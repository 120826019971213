/* eslint-disable no-unused-vars */
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Slide,
  DialogActions,
} from '@mui/material';
import { Fragment } from 'react';
import React from 'react';
import { useCourseQuery } from '../../../app/services/CoursesApi';
import { usePublicCourseQuery } from '../../../app/services/PublicCoursesApi';
import DisplayCourseCurriculum from './DisplayCourseCurriculum';
import DataLoader from '../../../components/DataLoader';
import HlsJs from '../HlsJs';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
function DisplaySingleCourseVideoQuality(props) {
  const { coursesID, open, onClosePopUp } = props;
  const { data, isLoading, isSuccess } = usePublicCourseQuery({
    id: parseInt(coursesID, 10),
  });
  console.log('this is props ', props);
  if (isLoading) {
    return;
  }
  return (
    <Fragment>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={onClosePopUp}
        aria-describedby="alert-dialog-slide-description"
        fullWidth={true}
        maxWidth="md"
        scroll="paper"
      >
        <DialogContent sx={{ width: 'auto' }}>
          {isLoading ? (
            <DataLoader thickness={5} size={50} />
          ) : (
            <>
              {data.course.promo_url ? (
                <HlsJs vidSrc={data.course.promo_url} />
              ) : data.course.video && data.course.video?.vid_url ? (
                <HlsJs vidSrc={data.course.video.vid_url} />
              ) : data.course?.published_video ? (
                <HlsJs vidSrc={data.course.published_video.vid_url} />
              ) : (
                <p>No Video Found</p>
              )}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClosePopUp}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}
export default DisplaySingleCourseVideoQuality;
