/* eslint-disable no-unused-vars */
import React, { Fragment, useState } from 'react';
import {
  Box,
  Grid,
  Paper,
  Slider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
} from '@mui/material';
import { BarChart } from '@mui/x-charts';
import { PieChart } from '@mui/x-charts/PieChart';
import GaugeIndicator from './GaugeIndicator';
import { Gauge } from '@mui/x-charts/Gauge';
import { CustomDateRange } from './CustomDateRange';
import { useEducatorCoursesAndReviewsQuery } from '../../../app/services/EducatorApi';
import moment from 'moment';
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:not(:last-of-type)': {
    marginBottom: theme.spacing(2), // Adjust spacing as needed
  },
  '& > *': {
    borderBottom: 'unset', // Remove default border-bottom
  },
  boxShadow: '1px 1px 2px 1px grey',
}));
export const ReviewandRating = () => {
  const [selectedPlan, setSelectedPlan] = useState('free');
  const [selectedCourseID, setSelectedCourseID] = useState(null);
  const { data, isLoading, isSuccess } = useEducatorCoursesAndReviewsQuery();

  const chartSetting = {
    yAxis: [
      {
        // label: 'Income (mm)',
      },
    ],
    // width: 500,
    height: 350,
  };
  const RatingchartSetting = {
    xAxis: [
      {
        // label: 'rainfall (mm)',
      },
    ],
    width: 250,
    height: 150,
  };
  const castchartSetting = {
    yAxis: [
      {
        // label: 'rainfall (mm)',
      },
    ],
    width: 400,
    height: 150,
  };
  const agechartSetting = {
    yAxis: [
      {
        label: 'Spending',
      },
    ],
    width: 400,
    height: 250,
  };
  const dataset = [
    {
      london: 59,
      paris: 57,
      newYork: 86,
      seoul: 21,
      month: 'Jan',
    },
    {
      london: 50,
      paris: 52,
      newYork: 78,
      seoul: 28,
      month: 'Fev',
    },
    {
      london: 47,
      paris: 53,
      newYork: 106,
      seoul: 41,
      month: 'Mar',
    },
    {
      london: 54,
      paris: 56,
      newYork: 92,
      seoul: 73,
      month: 'Apr',
    },
    {
      london: 57,
      paris: 69,
      newYork: 92,
      seoul: 99,
      month: 'May',
    },
    {
      london: 60,
      paris: 63,
      newYork: 103,
      seoul: 144,
      month: 'June',
    },
    {
      london: 59,
      paris: 60,
      newYork: 105,
      seoul: 319,
      month: 'July',
    },
    {
      london: 65,
      paris: 60,
      newYork: 106,
      seoul: 249,
      month: 'Aug',
    },
    {
      london: 51,
      paris: 51,
      newYork: 95,
      seoul: 131,
      month: 'Sept',
    },
    {
      london: 60,
      paris: 65,
      newYork: 97,
      seoul: 55,
      month: 'Oct',
    },
    {
      london: 67,
      paris: 64,
      newYork: 76,
      seoul: 48,
      month: 'Nov',
    },
    {
      london: 61,
      paris: 70,
      newYork: 103,
      seoul: 25,
      month: 'Dec',
    },
  ];
  const valueFormatter = (value) => `${value}`;
  function createData(srNo, courseId, rating, type) {
    return { srNo, courseId, type, rating, enroll: type };
  }
  function newcreateData(srNo, date, location, rating, review, action) {
    return { srNo, date, location, rating, review, action };
  }
  const ratingData = [
    {
      data: 35,
      number: '1',
    },
    {
      data: 20,
      number: '2',
    },
    {
      data: 28,
      number: '3',
    },
    {
      data: 35,
      number: '4',
    },
    {
      data: 98,
      number: '5',
    },
  ];
  const ageData = [
    {
      data: 2177,
      label: '24 and under',
    },
    {
      data: 37738,
      number: '25-34',
    },
    {
      data: 50045,
      number: '35-44',
    },
    {
      data: 52627,
      number: '45-54',
    },
    {
      data: 45758,
      number: '55-64',
    },
    {
      data: 32557,
      number: '65+',
    },
  ];
  const pieParams = { height: 150, width: 150, margin: { right: 5 } };
  return (
    <Fragment>
      <div style={{ position: 'relative' }}>
        <div
          style={{
            position: 'absolute',
            bottom: '0.7rem',
            right: '0rem',
            display: 'inline-block',
          }}
        >
          <CustomDateRange />
        </div>
      </div>
      <Grid container spacing={2} sx={{ background: '', marginTop: '0rem' }}>
        <Grid item xl={12} lg={12} md={12} sm={12} justifyContent={'center'}>
          <Box sx={{ background: '#fff', boxShadow: '1px 1px 10px 1px #dcdcdc' }}>
            <Grid container>
              <Grid item xl={9} lg={9} md={12} sm={12}>
                <Box sx={{ margin: '1rem', background: '#eef7fa' }}>
                  <div
                    style={{
                      display: 'flex',
                      gap: '1rem',
                      flexWrap: 'wrap',
                      // padding: '1rem',
                      background: '#fff',
                    }}
                  >
                    <div
                      style={{
                        height: '100px',
                        width: '200px',
                        background: selectedPlan == 'free' ? '#eef7fa' : '#10496a',
                        display: 'grid',
                        alignItems: 'center',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        setSelectedPlan('free');
                      }}
                    >
                      <div>
                        <Typography
                          sx={{
                            color: selectedPlan == 'free' ? '#124b6b' : '#fff',
                            textAlign: 'center',
                            fontWeight: 600,
                          }}
                        >
                          Free Plan
                        </Typography>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-around',
                            alignItems: 'center',
                          }}
                        >
                          <div style={{ alignSelf: 'center' }}>
                            <Typography
                              sx={{
                                color: selectedPlan == 'free' ? '#124b6b' : '#fff',
                                textAlign: 'center',
                                fontWeight: 400,
                                fontSize: '12px',
                              }}
                            >
                              Avg Rating
                            </Typography>
                            <Typography
                              sx={{
                                color: selectedPlan == 'free' ? '#124b6b' : '#fff',
                                textAlign: 'center',
                                fontWeight: 600,
                                fontSize: '12px',
                              }}
                            >
                              400
                            </Typography>
                          </div>
                          <div
                            style={{
                              width: '2px',
                              backgroundColor:
                                selectedPlan == 'free' ? '#000' : '#fff',
                              height: '40px' /* Adjust height as needed */,
                            }}
                          ></div>
                          <div style={{ alignSelf: 'center' }}>
                            <Typography
                              sx={{
                                color: selectedPlan == 'free' ? '#124b6b' : '#fff',
                                textAlign: 'center',
                                fontWeight: 400,
                                fontSize: '12px',
                              }}
                            >
                              Reviews
                            </Typography>
                            <Typography
                              sx={{
                                color: selectedPlan == 'free' ? '#124b6b' : '#fff',
                                textAlign: 'center',
                                fontWeight: 600,
                                fontSize: '12px',
                              }}
                            >
                              50
                            </Typography>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        height: '100px',
                        width: '200px',
                        background:
                          selectedPlan == 'subscription' ? '#eef7fa' : '#10496a',
                        display: 'grid',
                        alignItems: 'center',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        setSelectedPlan('subscription');
                      }}
                    >
                      <div>
                        <Typography
                          sx={{
                            color:
                              selectedPlan == 'subscription' ? '#124b6b' : '#fff',
                            textAlign: 'center',
                            fontWeight: 600,
                          }}
                        >
                          Subscription Plan
                        </Typography>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-around',
                            alignItems: 'center',
                          }}
                        >
                          <div style={{ alignSelf: 'center' }}>
                            <Typography
                              sx={{
                                color:
                                  selectedPlan == 'subscription'
                                    ? '#124b6b'
                                    : '#fff',
                                textAlign: 'center',
                                fontWeight: 400,
                                fontSize: '12px',
                              }}
                            >
                              Avg Rating
                            </Typography>
                            <Typography
                              sx={{
                                color:
                                  selectedPlan == 'subscription'
                                    ? '#124b6b'
                                    : '#fff',
                                textAlign: 'center',
                                fontWeight: 600,
                                fontSize: '12px',
                              }}
                            >
                              400
                            </Typography>
                          </div>
                          <div
                            style={{
                              width: '2px',
                              backgroundColor:
                                selectedPlan == 'subscription' ? '#000' : '#fff',
                              height: '40px' /* Adjust height as needed */,
                            }}
                          ></div>
                          <div style={{ alignSelf: 'center' }}>
                            <Typography
                              sx={{
                                color:
                                  selectedPlan == 'subscription'
                                    ? '#124b6b'
                                    : '#fff',
                                textAlign: 'center',
                                fontWeight: 400,
                                fontSize: '12px',
                              }}
                            >
                              Reviews
                            </Typography>
                            <Typography
                              sx={{
                                color:
                                  selectedPlan == 'subscription'
                                    ? '#124b6b'
                                    : '#fff',
                                textAlign: 'center',
                                fontWeight: 600,
                                fontSize: '12px',
                              }}
                            >
                              50
                            </Typography>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        height: '100px',
                        width: '200px',
                        background:
                          selectedPlan == 'premium' ? '#eef7fa' : '#10496a',
                        display: 'grid',
                        alignItems: 'center',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        setSelectedPlan('premium');
                      }}
                    >
                      <div>
                        <Typography
                          sx={{
                            color: selectedPlan == 'premium' ? '#124b6b' : '#fff',
                            textAlign: 'center',
                            fontWeight: 600,
                          }}
                        >
                          Premium Plan
                        </Typography>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-around',
                            alignItems: 'center',
                          }}
                        >
                          <div style={{ alignSelf: 'center' }}>
                            <Typography
                              sx={{
                                color:
                                  selectedPlan == 'premium' ? '#124b6b' : '#fff',
                                textAlign: 'center',
                                fontWeight: 400,
                                fontSize: '12px',
                              }}
                            >
                              Avg Rating
                            </Typography>
                            <Typography
                              sx={{
                                color:
                                  selectedPlan == 'premium' ? '#124b6b' : '#fff',
                                textAlign: 'center',
                                fontWeight: 600,
                                fontSize: '12px',
                              }}
                            >
                              400
                            </Typography>
                          </div>
                          <div
                            style={{
                              width: '2px',
                              backgroundColor:
                                selectedPlan == 'premium' ? '#000' : '#fff',
                              height: '40px' /* Adjust height as needed */,
                            }}
                          ></div>
                          <div style={{ alignSelf: 'center' }}>
                            <Typography
                              sx={{
                                color:
                                  selectedPlan == 'premium' ? '#124b6b' : '#fff',
                                textAlign: 'center',
                                fontWeight: 400,
                                fontSize: '12px',
                              }}
                            >
                              Reviews
                            </Typography>
                            <Typography
                              sx={{
                                color:
                                  selectedPlan == 'premium' ? '#124b6b' : '#fff',
                                textAlign: 'center',
                                fontWeight: 600,
                                fontSize: '12px',
                              }}
                            >
                              50
                            </Typography>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <BarChart
                    dataset={dataset}
                    xAxis={[{ scaleType: 'band', dataKey: 'month' }]}
                    series={[
                      { dataKey: 'london', valueFormatter },
                      { dataKey: 'seoul', valueFormatter },
                    ]}
                    {...chartSetting}
                  />
                </Box>
              </Grid>
              <Grid
                item
                xl={3}
                lg={3}
                md={12}
                sm={12}
                sx={{ borderLeft: '3px solid #cbcbcb', padding: '1rem' }}
              >
                <Typography
                  sx={{ color: '#000', fontWeight: 700, fontSize: '18px' }}
                >
                  Performance Meter
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '2rem',
                    alignSelf: 'center',
                  }}
                >
                  <GaugeIndicator />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xl={4} lg={4} md={12} sm={12}>
          <Box
            sx={{
              background: '#fff',
              padding: '1rem',
              boxShadow: '1px 1px 10px 1px #dcdcdc',
            }}
          >
            <TableContainer component={Paper} sx={{ maxHeight: '700px' }}>
              <Table sx={{ minWidth: '100%' }} aria-label="simple table">
                <TableHead sx={{ background: '#10496a' }}>
                  <TableRow>
                    <TableCell
                      sx={{
                        color: '#fff',
                        fontSize: '16px',
                        fontWeight: 700,
                      }}
                    >
                      #
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        color: '#fff',
                        fontSize: '16px',
                        fontWeight: 700,
                        width: '120px',
                        padding: '0.3rem',
                      }}
                    >
                      Course ID
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        color: '#fff',
                        fontSize: '16px',
                        fontWeight: 700,
                        width: '120px',
                        padding: '0.3rem',
                      }}
                    >
                      Ava Ratings
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        color: '#fff',
                        fontSize: '16px',
                        fontWeight: 700,
                        width: '120px',
                        padding: '0.3rem',
                      }}
                    >
                      Reviews
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className="analytics-custom-mui-table-body">
                  {data &&
                    data.courses?.map((row, i) => (
                      <TableRow
                        key={row?.id}
                        className="analytics-custom-mui-table-row"
                      >
                        <TableCell>
                          <div>{i + 1}</div>
                        </TableCell>
                        <TableCell
                          align="center"
                          onClick={() => {
                            setSelectedCourseID(row);
                          }}
                          sx={{ cursor: 'pointer' }}
                        >
                          <div>{row?.id}</div>
                        </TableCell>
                        <TableCell align="center">
                          <div>
                            {row?.course_reviews_avg_rating
                              ? parseFloat(row?.course_reviews_avg_rating).toFixed(1)
                              : '-'}
                          </div>
                        </TableCell>
                        <TableCell align="center">
                          <div>{row?.course_reviews_count}</div>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Grid>
        <Grid item xl={8} lg={8} md={12} sm={12}>
          {selectedCourseID && (
            <Grid container spacing={2}>
              <Grid item xl={6} lg={6} md={12} sm={12}>
                <Box
                  sx={{
                    background: '#fff',
                    padding: '1rem',
                    minHeight: '175px',
                    boxShadow: '1px 1px 10px 1px #dcdcdc',
                  }}
                >
                  <Typography>Rating Distributions</Typography>
                  <div style={{ display: 'flex' }}>
                    <Box sx={{ width: '150px', position: 'relative' }}>
                      <div
                        style={{
                          position: 'absolute',
                          top: '3rem',
                          right: '2.5rem',
                        }}
                      >
                        <Typography
                          sx={{
                            textAlign: 'center',
                            fontWeight: 700,
                            fontSize: '20px',
                          }}
                        >
                          {selectedCourseID?.course_reviews_avg_rating
                            ? parseFloat(
                                selectedCourseID.course_reviews_avg_rating
                              ).toFixed(1)
                            : '-'}{' '}
                        </Typography>
                        <Typography
                          sx={{
                            textAlign: 'center',
                            fontWeight: 400,
                            fontSize: '12px',
                          }}
                        >
                          {selectedCourseID.course_reviews_count} Reviews
                        </Typography>
                      </div>
                      <Gauge
                        width={150}
                        height={150}
                        value={
                          selectedCourseID?.course_reviews_avg_rating
                            ? (parseFloat(
                                selectedCourseID.course_reviews_avg_rating
                              ).toFixed(1) *
                                100) /
                              5
                            : '-'
                        }
                        innerRadius="70%"
                        outerRadius="100%"
                        text={({ value, valueMax }) => ``}
                      />
                    </Box>
                    <Box>
                      <BarChart
                        dataset={selectedCourseID.course_reviews}
                        yAxis={[{ scaleType: 'band', dataKey: 'rating' }]}
                        series={[
                          {
                            dataKey: 'rating',
                          },
                        ]}
                        layout="horizontal"
                        {...RatingchartSetting}
                      />
                    </Box>
                  </div>
                </Box>
              </Grid>
              <Grid item xl={6} lg={6} md={12} sm={12}>
                <Box
                  sx={{
                    background: '#fff',
                    padding: '1rem',
                    minHeight: '175px',
                    boxShadow: '1px 1px 10px 1px #dcdcdc',
                  }}
                >
                  <Typography>See How Loyal Are Your Customers</Typography>
                  <div style={{ display: 'flex' }}>
                    <Box>
                      <BarChart
                        dataset={ratingData}
                        yAxis={[{ scaleType: 'band', dataKey: 'number' }]}
                        series={[
                          {
                            dataKey: 'data',
                          },
                        ]}
                        layout="vertical"
                        {...castchartSetting}
                      />
                    </Box>
                  </div>
                </Box>
              </Grid>
              <Grid item xl={12} lg={12} md={12} sm={12}>
                <Box
                  sx={{
                    background: '#fff',
                    padding: '1rem',
                    minHeight: '175px',
                    boxShadow: '1px 1px 10px 1px #dcdcdc',
                  }}
                >
                  <TableContainer component={Paper} sx={{ maxHeight: '470px' }}>
                    <Table sx={{ minWidth: '100%' }} aria-label="simple table">
                      <TableHead sx={{ background: '#10496a' }}>
                        <TableRow>
                          <TableCell
                            sx={{
                              color: '#fff',
                              fontSize: '16px',
                              fontWeight: 700,
                            }}
                          >
                            #
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              color: '#fff',
                              fontSize: '16px',
                              fontWeight: 700,
                            }}
                          >
                            Date
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              color: '#fff',
                              fontSize: '16px',
                              fontWeight: 700,
                            }}
                          >
                            Location
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              color: '#fff',
                              fontSize: '16px',
                              fontWeight: 700,
                            }}
                          >
                            Rating
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              color: '#fff',
                              fontSize: '16px',
                              fontWeight: 700,
                              width: '300px',
                            }}
                          >
                            Review
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              color: '#fff',
                              fontSize: '16px',
                              fontWeight: 700,
                            }}
                          >
                            Action
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody className="review-and-rating-table">
                        {selectedCourseID.course_reviews?.map((row, index) => (
                          <StyledTableRow
                            key={row?.id}

                            //   className="analytics-custom-mui-table-row"
                          >
                            <TableCell>
                              <div>{index + 1}</div>
                            </TableCell>
                            <TableCell>
                              <div>
                                {moment(row?.created_at).format('Do MMM YY hh:mm A')}
                              </div>
                            </TableCell>
                            <TableCell align="center">
                              <div>{row?.location}</div>
                            </TableCell>
                            <TableCell align="center">
                              <div>{row?.rating}</div>
                            </TableCell>
                            <TableCell align="center">
                              <div>{row?.review_text}</div>
                            </TableCell>
                            <TableCell align="center">
                              <div>{row?.action}</div>
                            </TableCell>
                          </StyledTableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Fragment>
  );
};
