/* eslint-disable no-unused-vars */
import { Button, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { usePublishedSubscriptionPlansQuery } from '../../../app/services/SunscriptionPlansApi';

function DisplaySubscriptionPlans() {
  const { data, isLoading, isSuccess } = usePublishedSubscriptionPlansQuery();

  return (
    <Grid container>
      <Grid item md={2}></Grid>
      <Grid item md={8} sx={{ background: 'lightcyan' }}>
        {isSuccess && data.subscriptionPlans ? (
          <Box
            sx={{ ml: '20px', mt: '20px', height: '100vh', alignItems: 'center' }}
          >
            <Grid container>
              {data.subscriptionPlans.map((singlePlan) => (
                <Grid item md={4} key={singlePlan.id}>
                  <Typography variant="h4">{singlePlan.title}</Typography>
                  <Typography>{singlePlan.description}</Typography>

                  <Button variant="contained" color="secondary">
                    Start Subscription
                  </Button>
                </Grid>
              ))}
            </Grid>
          </Box>
        ) : (
          <Typography>No subscription Plan Found</Typography>
        )}
      </Grid>
      <Grid item md={8}></Grid>

      {/* <Grid
        item
        xs={false}
        sm={4}
        md={6}
        sx={{
          backgroundImage:
            'url(https://images.unsplash.com/photo-1521898284481-a5ec348cb555?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80)',
        }}
      ></Grid> */}
    </Grid>
  );
}
export default DisplaySubscriptionPlans;
