/* eslint-disable no-unused-vars */
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Divider,
  Grid,
  Paper,
  Rating,
  Typography,
} from '@mui/material';
import moment from 'moment';
import { Link, useNavigate } from 'react-router-dom';
import { useUserCourseReviewsQuery } from '../../app/services/UserApi';
import DataLoader from '../../components/DataLoader';

function UserCourseReviews() {
  const { data, isLoading, isSuccess } = useUserCourseReviewsQuery();
  const navigate = useNavigate();

  const onCourseClick = (course) => {
    navigate(
      `/${course.sub_category.category.title}/${course.sub_category.title}/course/${course.id}`
    );
  };
  if (isLoading) {
    return <DataLoader thickness={5} size={50} />;
  }
  return (
    <>
      {isSuccess && data && data.courseReviews.length >= 1
        ? data.courseReviews.map((singleCourseReview) => (
            <>
              <Card sx={{ display: 'flex', mt: 1 }}>
                <CardMedia
                  component="img"
                  sx={{ width: 340, display: { xs: 'none', sm: 'block' } }}
                  image={
                    singleCourseReview.course.url ||
                    `https://images.unsplash.com/photo-1516397281156-ca07cf9746fc?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80`
                  }
                />
                <CardContent
                  sx={{
                    flex: 1,
                    overflowWrap: 'anywhere',
                  }}
                >
                  <Button
                    onClick={() => onCourseClick(singleCourseReview.course)}
                    sx={{ textAlign: 'start' }}
                  >
                    {singleCourseReview.course.title}
                  </Button>
                  <Box sx={{ display: 'flex' }}>
                    <Rating
                      name="read-only"
                      value={singleCourseReview.rating}
                      readOnly
                      precision={0.5}
                    />
                  </Box>{' '}
                  <Box>
                    <Typography variant="caption" display="block" gutterBottom>
                      Reviewed on:{' '}
                      {moment(singleCourseReview.created_at).format(
                        'Do MMM YY hh:mm A'
                      )}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography>{singleCourseReview.review_text}</Typography>
                  </Box>
                </CardContent>
              </Card>
              <Divider />
            </>
          ))
        : 'No reviews found'}
    </>
  );
}

export default UserCourseReviews;
