/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BaseApi } from './BaseApi';

const API_URL = `${process.env.REACT_APP_BACKEND_API_PATH}/api`;
const LOCALSTORAGE_APP_KEY = `${process.env.REACT_APP_LOCALSTORAGE_KEY}`;

export const subscriptionPlansApi = BaseApi.injectEndpoints({
  reducerPath: 'subscriptionPlansApi',
  endpoints: (builder) => ({
    subscriptionPlans: builder.query({
      query: () => ({
        url: '/subscription/plans',
        method: 'GET',
      }),
      providesTags: ['SubscriptionPlan'],
    }),
    createSubscriptionPlan: builder.mutation({
      query: (details) => ({
        url: '/subscription/plan/create',
        method: 'POST',

        body: {
          title: details.title,
          description: details.description,
          cost: details.cost,
        },
      }),
      invalidatesTags: ['SubscriptionPlan'],
    }),
    subscriptionPlan: builder.query({
      query: (details) => ({
        url: `/subscription/plan/show/${details.id}`,
        method: 'GET',
      }),
      providesTags: ['SubscriptionPlan'],
    }),
    updateSubscriptionPlan: builder.mutation({
      query: (details) => ({
        url: `/subscription/plan/update/${details.id}`,
        method: 'POST',

        body: {
          title: details.title,
          description: details.description,
          cost: details.cost,
        },
      }),
      invalidatesTags: ['SubscriptionPlan'],
    }),
    updateSubscriptionPlanSatus: builder.mutation({
      query: (details) => ({
        url: '/subscription/plan/status/update',
        method: 'POST',

        body: {
          status: details.status,
          subscription_plan_id: details.id,
        },
      }),
      invalidatesTags: ['SubscriptionPlan'],
    }),
    publishedSubscriptionPlans: builder.query({
      query: (details) => ({
        url: `/public/subscription/plans`,
        method: 'GET',
      }),
      providesTags: ['SubscriptionPlan'],
    }),
  }),
});

export const {
  useSubscriptionPlansQuery,
  useCreateSubscriptionPlanMutation,
  useSubscriptionPlanQuery,
  useUpdateSubscriptionPlanMutation,
  useUpdateSubscriptionPlanSatusMutation,
  usePublishedSubscriptionPlansQuery,
} = subscriptionPlansApi;
