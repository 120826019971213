/* eslint-disable no-unused-vars */
import { Button, Box, TextField, MenuItem, InputAdornment } from '@mui/material';

import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useCreateStudentGradeSubjectSubscriptionPlanMutation } from '../../../../../app/services/StudentSubscriptionPlansApi';

function CreateGradeSubjectPlan(props) {
  const { subscriptionPlan } = props;

  const { enqueueSnackbar } = useSnackbar();

  const [createSubscriptionPlan, result] =
    useCreateStudentGradeSubjectSubscriptionPlanMutation();

  const [subCategoryTitle, setSubCategoryTitle] = useState('');

  const [selectedSubCategory, setSelectedSubCategory] = useState({
    title: '',
    id: null,
  });

  const [gradeTitle, setGradeTitle] = useState('');
  const [gradeSubjectTitle, setGradeSubjectTitle] = useState('');

  const [selectedGrade, setSelectedGrade] = useState({
    title: '',
    id: null,
  });

  const [selectedGradeSubject, setSelectedGradeSubject] = useState({
    title: '',
    id: null,
  });

  const validateData = (subscriptionPlanData) => {
    let err = true;

    if (
      !subscriptionPlanData.title ||
      !subscriptionPlanData.category_id ||
      !subscriptionPlanData.sub_category_id ||
      !subscriptionPlanData.grade_id ||
      !subscriptionPlanData.grade_subject_id
    ) {
      enqueueSnackbar('Please provide all the required fields', {
        variant: 'error',
      });
    } else {
      err = false;
    }

    return err;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const subscriptionPlanData = {
      title: selectedGradeSubject.title,
      cost: data.get('cost'),
      category_id: subscriptionPlan.category.id,
      sub_category_id: selectedSubCategory.id,
      grade_id: selectedGrade.id,
      grade_subject_id: selectedGradeSubject.id,
      student_subscription_plan_id: subscriptionPlan.id,
    };

    const res = validateData(subscriptionPlanData);
    if (!res) {
      createSubscriptionPlan(subscriptionPlanData);
    }
  };

  const handleSubCategoryChange = (event) => {
    const tempSelectedSubCategory =
      subscriptionPlan.category.published_sub_categories.find(
        (singleSubCategory) => singleSubCategory.id === event.target.value
      );

    setSelectedSubCategory(tempSelectedSubCategory);
    setSubCategoryTitle(event.target.value);
  };

  const handleGradeChange = (event) => {
    const tempSelectedGrade = selectedSubCategory.grades.find(
      (singleGrade) => singleGrade.id === event.target.value
    );

    setSelectedGrade(tempSelectedGrade);
    setGradeTitle(event.target.value);
  };

  const handleGradeSubjectChange = (event) => {
    const tempSelectedGradeSubject = selectedGrade.grade_subjects.find(
      (singleGradeSubject) => singleGradeSubject.id === event.target.value
    );

    setSelectedGradeSubject(tempSelectedGradeSubject);
    setGradeSubjectTitle(event.target.value);
  };

  return result.data ? (
    result.data.message
  ) : (
    <>
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <TextField
          margin="normal"
          select
          required
          fullWidth
          id="sub_category"
          label="Sub Category"
          onChange={handleSubCategoryChange}
          name="sub_category"
          autoComplete="sub_category"
          autoFocus
          value={subCategoryTitle}
        >
          {subscriptionPlan &&
          subscriptionPlan.category &&
          subscriptionPlan.category.published_sub_categories.length
            ? subscriptionPlan.category.published_sub_categories.map(
                (singleSubCategory, index) => (
                  <MenuItem value={singleSubCategory.id} key={singleSubCategory.id}>
                    {singleSubCategory.title}
                  </MenuItem>
                )
              )
            : null}
        </TextField>
        {selectedSubCategory.id && selectedSubCategory.grades.length ? (
          <TextField
            margin="normal"
            select
            required
            fullWidth
            id="grade"
            label="Grade"
            onChange={handleGradeChange}
            name="grade"
            autoComplete="grade"
            autoFocus
            value={gradeTitle}
          >
            {selectedSubCategory && selectedSubCategory.grades.length
              ? selectedSubCategory.grades.map((singleGrade, index) => (
                  <MenuItem value={singleGrade.id} key={singleGrade.id}>
                    {singleGrade.title}
                  </MenuItem>
                ))
              : null}
          </TextField>
        ) : null}

        {selectedSubCategory.id &&
        selectedGrade.id &&
        selectedGrade.grade_subjects.length ? (
          <TextField
            margin="normal"
            select
            required
            fullWidth
            id="grade_subject"
            label="Grade Subject"
            onChange={handleGradeSubjectChange}
            name="grade subject"
            autoComplete="grade subject"
            autoFocus
            value={gradeSubjectTitle}
          >
            {selectedGrade.grade_subjects.length
              ? selectedGrade.grade_subjects.map((singleGradeSubject, index) => (
                  <MenuItem
                    value={singleGradeSubject.id}
                    key={singleGradeSubject.id}
                  >
                    {singleGradeSubject.title}
                  </MenuItem>
                ))
              : null}
          </TextField>
        ) : null}

        <TextField
          margin="normal"
          // required
          fullWidth
          id="cost"
          label="Cost"
          name="cost"
          autoComplete="cost"
          type="number"
          autoFocus
          InputProps={{
            startAdornment: <InputAdornment position="start">₹</InputAdornment>,
          }}
        />

        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          disabled={result.isLoading || result.isSuccess}
        >
          Add Student Grade Subject Subscription Plan
        </Button>
      </Box>
    </>
  );
}

export default CreateGradeSubjectPlan;
