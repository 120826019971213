import { Typography, Grid, Card, CardContent, Box } from '@mui/material';
import { useParams } from 'react-router';

import { useGetTopicVideoUrlQuery } from '../../../../../app/services/CoursesApi';
import DataLoader from '../../../../../components/DataLoader';
import HlsJs from '../../../HlsJs';
import DisplayTopicVideos from './DisplayTopicVideos';

function DisplaySingleTopicVideo() {
  const params = useParams();

  const { data, isLoading, isSuccess } = useGetTopicVideoUrlQuery({
    id: parseInt(params.Id, 10),
  });
  if (isLoading) {
    return <DataLoader thickness={5} size={50} />;
  }

  return (
    <>
      {isSuccess && data.video ? (
        <Grid container spacing={1}>
          <Grid item xs="12" sm="12" md="1" lg="1" xl="1" align="left"></Grid>

          <Grid item xs="12" sm="12" md="6" lg="6" xl="6" align="left">
            {data.video ? <HlsJs vidSrc={data.video.vid_url} /> : null}

            <Card>
              <CardContent sx={{ overflowWrap: 'anywhere' }}>
                <Typography component="h2" variant="h5">
                  <strong>{data.video.title}</strong>
                </Typography>
                <Typography variant="subtitle1" paragraph>
                  Description: {data.video.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs="12" sm="12" md="5" lg="5" xl="5" align="left">
            {data.video.topic.video ? (
              <Card>
                <Box mt={2} textAlign="center">
                  <Typography variant="subtitle1" color="text.secondary">
                    Topic Videos
                  </Typography>
                  <DisplayTopicVideos video={data.video.topic.video} />
                </Box>
              </Card>
            ) : (
              <Typography variant="subtitle1" color="text.secondary">
                No Topic Videos Found
              </Typography>
            )}
          </Grid>
        </Grid>
      ) : (
        <p>No Video Found</p>
      )}
    </>
  );
}

export default DisplaySingleTopicVideo;
