import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography,
} from '@mui/material';
import moment from 'moment';
import React from 'react';
import { useParams } from 'react-router';
import { useStudentSubscriptionQuery } from '../../../app/services/StudentApi';
import DataLoader from '../../../components/DataLoader';

function SingleStudentSubscription() {
  const params = useParams();

  const { data, isLoading, isSuccess } = useStudentSubscriptionQuery({
    id: parseInt(params.Id, 10),
  });

  if (isLoading) {
    return <DataLoader thickness={5} size={50} />;
  }

  return (
    <>
      {isSuccess && data.subscription ? (
        <>
          <Grid container>
            <Grid item xs="12" sm="12" md="6" lg="6" xl="6">
              <Card>
                <CardContent>
                  <Typography
                    sx={{ fontSize: 14 }}
                    color="text.secondary"
                    gutterBottom
                  >
                    Your subscribed plan{' '}
                  </Typography>
                  <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    {data.subscription.subscription_plan.title}
                  </Typography>
                  <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    Status: {data.subscription.status}
                  </Typography>
                  <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    Subscribed On &nbsp;
                    {moment(data.subscription.created_at).format(
                      'Do MMM YY hh:mm A'
                    )}
                  </Typography>{' '}
                  <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    Vatlid from &nbsp;
                    {moment(data.subscription.start_date).format(
                      'Do MMM YY hh:mm A'
                    )}
                    &nbsp; till &nbsp;
                    {moment(data.subscription.end_date).format('Do MMM YY hh:mm A')}
                  </Typography>{' '}
                  <Typography variant="body2">
                    {data.subscription.subscription_plan.description}
                  </Typography>
                  <Typography variant="body2">
                    Click below on your subscriptions to start learning
                  </Typography>
                </CardContent>
                <CardActions>
                  {data.subscription.sub_categories.length >= 1
                    ? data.subscription.sub_categories.map((singleSub, index) => (
                        <Button key={index}>{singleSub.title}</Button>
                      ))
                    : null}
                  <br />
                  {data.subscription.grades.length >= 1
                    ? data.subscription.grades.map((singleGrade, index) => (
                        <Button key={index}>{singleGrade.title}</Button>
                      ))
                    : null}
                  <br />
                  {data.subscription.grade_subjects.length >= 1
                    ? data.subscription.grade_subjects.map(
                        (singleGradeSubject, index) => (
                          <Button key={index}>{singleGradeSubject.title}</Button>
                        )
                      )
                    : null}
                </CardActions>
              </Card>
            </Grid>
            <Grid item xs="12" sm="12" md="6" lg="6" xl="6">
              <Card>
                <CardContent>
                  <Typography
                    sx={{ fontSize: 14 }}
                    color="text.secondary"
                    gutterBottom
                  >
                    Payment Info{' '}
                  </Typography>
                  <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    Paid on :{' '}
                    {moment(data.subscription.payment_info.created_at).format(
                      'Do MMM YY hh:mm A'
                    )}
                  </Typography>
                  <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    Amount paid: ₹{data.subscription.payment_info.paid_amount}
                  </Typography>
                  <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    Status: {data.subscription.payment_info.status}
                  </Typography>
                  <Typography variant="body2">
                    Transaction Id:{data.subscription.payment_info.transaction_id}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </>
      ) : (
        <p>No subscription found</p>
      )}
    </>
  );
}

export default SingleStudentSubscription;
