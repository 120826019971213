/* eslint-disable no-unused-vars */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { useParams } from 'react-router';
import { useStudentGradeSubjectSubscriptionPlanQuery } from '../../../../../app/services/StudentSubscriptionPlansApi';
import DataLoader from '../../../../../components/DataLoader';

function SingleGradeSubjectPlan() {
  const params = useParams();

  const { data, isLoading, isSuccess } = useStudentGradeSubjectSubscriptionPlanQuery(
    {
      id: parseInt(params.Id, 10),
    }
  );

  if (isLoading) {
    return <DataLoader thickness={5} size={50} />;
  }

  return (
    <>
      {isSuccess && data.studentSubscriptionPlan ? (
        <>
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            sx={{ padding: 4, color: '#233c8d' }}
          >
            Title: {data.studentSubscriptionPlan.title}
            <br />
            Cost: ₹{data.studentSubscriptionPlan.cost}
            <br />
            Status: {data.studentSubscriptionPlan.status}
          </Typography>
        </>
      ) : (
        <p>No studentSubscriptionPlan found</p>
      )}
    </>
  );
}

export default SingleGradeSubjectPlan;
