/* eslint-disable no-unused-vars */
import { Button, Grid, Paper, Typography } from '@mui/material';
import React, { Fragment, useState } from 'react';
import HlsJs from '../../../features/courses/HlsJs';
import { useNavigate } from 'react-router-dom';

export const TeachEducator = () => {
  const [selectedStep, setSelectedStep] = useState('Register');
  const navigate = useNavigate();

  const allCards = [
    {
      img: '/teach-here/teacher/partnership.png',
      title: `Collaborate with us as partner and embark on a journey of knowledge and growth.`,
    },
    {
      img: '/teach-here/teacher/youtubestudio.png',
      title: `Collaborate with us as partner and embark on a journey of knowledge and growth.`,
    },
    {
      img: '/teach-here/teacher/Group 1660.png',
      title: `Collaborate with us as partner and embark on a journey of knowledge and growth.`,
    },
    {
      img: '/teach-here/teacher/Group 1661.png',
      title: `Collaborate with us as partner and embark on a journey of knowledge and growth.`,
    },
    {
      img: '/teach-here/teacher/Path 1752.png',
      title: `Collaborate with us as partner and embark on a journey of knowledge and growth.`,
    },
    {
      img: '/teach-here/teacher/Group 1662.png',
      title: `Collaborate with us as partner and embark on a journey of knowledge and growth.`,
    },
    {
      img: '/teach-here/teacher/Path 1755.png',
      title: `Collaborate with us as partner and embark on a journey of knowledge and growth.`,
    },
    {
      img: '/teach-here/teacher/Path 1756.png',
      title: `Collaborate with us as partner and embark on a journey of knowledge and growth.`,
    },
  ];

  return (
    <Fragment>
      <Grid
        container
        spacing={2}
        sx={{ background: '#ddf0f5' }}
        justifyContent={'center'}
      >
        <Grid item xl={9} lg={9} md={10} sm={11} xs={11}>
          <Grid container spacing={2}>
            <Grid
              item
              xl={4}
              lg={4}
              md={4}
              sm={12}
              xs={12}
              sx={{ background: '#ddf0f5' }}
            >
              <img
                src="/teach-here/teacher/GMavin.png"
                style={{ height: '100%', width: '100%' }}
              />
            </Grid>
            <Grid
              item
              xl={8}
              lg={8}
              md={8}
              sm={12}
              xs={12}
              sx={{ background: '#ddf0f5' }}
              alignSelf={'center'}
            >
              <Typography
                sx={{ color: '#274265', fontSize: '28px', fontWeight: 700 }}
              >
                Join our community of educators and become a part of our mission to
                revolutionize online learning. Empower yourself with our unique
                paltform
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            {Array.isArray(allCards) &&
              allCards.length > 0 &&
              allCards.map((singleCard, index) => (
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12} key={'ind' + index}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      background: '#fff',
                      padding: '1.5rem',
                      gap: '1rem',
                      borderRadius: '20px',
                      boxShadow: '2px 2px 5px 2px #9c9c9c',
                    }}
                  >
                    <img
                      src={singleCard.img}
                      style={{ width: '100px', height: '100px' }}
                    />
                    <p>{singleCard?.title}</p>
                  </div>
                </Grid>
              ))}
          </Grid>
          <Typography
            sx={{
              color: '#274265',
              fontSize: '28px',
              fontWeight: 700,
              textAlign: 'center',
              marginTop: '4rem',
            }}
          >
            This technology offering you an opportunity to play a crucial role in
            shaping millions of lives globally.
          </Typography>
          <Typography
            sx={{
              color: '#274265',
              fontSize: '18px',
              fontWeight: 700,
              textAlign: 'center',
              marginTop: '1rem',
            }}
          >
            Unlock your potential with our innovative online education platform
          </Typography>
          <div
            style={{
              width: '100%',
              display: 'grid',
              justifyContent: 'center',
              paddingTop: '1rem',
              paddingBottom: '1rem',
            }}
          >
            <Button
              variant="contained"
              sx={{ background: '#ee3163', fontSize: '24px', fontWeight: 700 }}
              onClick={() => {
                navigate('/teacher-plan');
              }}
            >
              Join Us Now
            </Button>
          </div>
        </Grid>
      </Grid>
    </Fragment>
  );
};
