/* eslint-disable no-unused-vars */
import {
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Card,
  Grid,
  Box,
  CardMedia,
  CardContent,
  Tabs,
  Tab,
} from '@mui/material';
import moment from 'moment';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useCourseQuery } from '../../../app/services/CoursesApi';
import CreateNewSubject from './subjects/CreateNewSubject';
import SubjectList from './subjects/SubjectList';
import AssignInstitutionToCourse from './admin/AssignInstitutionToCourse';
import AssignEducatorToCourse from './institution/AssignEducatorToCourse';
import UpdateCourse from './UpdateCourse';
import UploadCourseImage from './UploadCourseImage';
import LanguageIcon from '@mui/icons-material/Language';
import UploadCourseVideo from './UploadCourseVideo';
import Slide from '@mui/material/Slide';

import DisplayCourseVideos from './DisplayCourseVideos';
import DataLoader from '../../../components/DataLoader';
import { EditCourse } from './EditCourse';
import EditCourseSubjectAndTopics from './EditCourseSubjectAndTopics';
import TabPanel from '../../../components/authentication/TabPanel';

function EditSingleCourse() {
  const [value, setValue] = useState(0);
  const params = useParams();
  const { user } = useSelector((state) => state.auth);
  const { data, isLoading, isSuccess } = useCourseQuery({
    id: parseInt(params.Id, 10),
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      {isSuccess && data.course ? (
        <>
          <Grid container spacing={1} justifyContent={'center'}>
            <Grid item xs="11" sm="11" md="10" lg="10" xl="10">
              <Tabs
                value={value}
                onChange={(e, newval) => handleChange(e, newval)}
                textColor="primary"
                indicatorColor="primary"
                aria-label="Account forms"
              >
                <Tab
                  label="Edit Details"
                  id="simple-tab-0"
                  aria-controls="simple-tabpanel-0"
                />
                <Tab
                  label="Edit Curriculum"
                  id="simple-tab-0"
                  aria-controls="simple-tabpanel-0"
                />
              </Tabs>
              <TabPanel value={value} index={0}>
                <Grid container spacing={1} sx={{ marginTop: '1rem' }}>
                  <EditCourse course={data.course} />
                </Grid>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Grid container spacing={1} sx={{ marginTop: '1rem' }}>
                  <EditCourseSubjectAndTopics
                    courseId={params.Id}
                    course={data.course}
                  />
                </Grid>
              </TabPanel>
            </Grid>
          </Grid>
        </>
      ) : (
        <p>No course found</p>
      )}
    </>
  );
}

export default EditSingleCourse;
