/* eslint-disable no-unused-vars */

import { CheckCircleOutline } from '@mui/icons-material';
import {
  Alert,
  Backdrop,
  Button,
  CircularProgress,
  Grid,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useCreateEducatorPaymentOrderIdMutation,
  useCreateEducatorSubscriptionWithPaymentMutation,
  usePublicEducatorSubscriptionPlanQuery,
} from '../../../app/services/EducatorSubscriptionPlansApi';

const RAZORPAKEY = process.env.REACT_APP_RAZORPAY_KEY;

function DisplaySingleEducatorSubscriptionPlan() {
  const params = useParams();
  const { user, isSignedIn } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const { data, isLoading, isSuccess } = usePublicEducatorSubscriptionPlanQuery({
    id: parseInt(params.Id, 10),
  });
  const [createSubscriptionPlan, res] = useCreateEducatorPaymentOrderIdMutation();
  const [createSubscriptionPlanPayment, result1] =
    useCreateEducatorSubscriptionWithPaymentMutation();

  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleCancel = () => {
    setVisible(false);
  };

  const showModal = () => {
    setVisible(true);
  };

  const createOrderId = async () => {
    // createSubscriptionPlan(subscriptionPlanData);

    const createOrderIdRequest = await createSubscriptionPlan({
      amount: isSuccess && data ? data.educatorSubscriptionPlan.cost : null,
      currency: 'INR',
    });

    return createOrderIdRequest.data.orderId;
  };

  const razorPayPoUp = (orderId) => {
    const options = {
      key: RAZORPAKEY, // Enter the Key ID generated from the Dashboard
      amount: isSuccess && data ? data.educatorSubscriptionPlan.cost * 100 : null, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      currency: 'INR',
      name: 'Techno Bug',
      description: 'Transaction',
      image: '{ logo }',
      order_id: orderId,
      handler: async (response) => {
        if (!response.razorpay_signature) {
          showModal();
        } else {
          setLoading(true);

          const subscriptionRes = await createSubscriptionPlanPayment({
            razorpay_payment_id: response.razorpay_payment_id,
            razorpay_signature: response.razorpay_signature,
            order_id: orderId,
            amount: isSuccess && data ? data.educatorSubscriptionPlan.cost : null,
            educator_subscription_plan_id:
              isSuccess && data ? data.educatorSubscriptionPlan.id : null,
            email: user.email,
          });
          setSuccess(true);
        }
      },
      prefill: {
        name: user.name,
        email: user.email,
        contact: user.phone_number,
      },
      notes: {
        address: 'Razorpay Corporate Office',
      },
      theme: {
        color: '#a544a5',
      },
    };
    if (typeof Razorpay !== 'undefined') {
      // eslint-disable-next-line no-undef
      const rzp1 = new Razorpay(options);
      // eslint-disable-next-line func-names
      rzp1.on('payment.failed', function (response) {
        // eslint-disable-next-line no-console
        console.log(response, 'failed');
      });

      rzp1.open();
    }
  };

  const handleRazorPayPayment = async () => {
    const orderId = await createOrderId();
    razorPayPoUp(orderId);
  };

  if (visible) {
    return (
      <>
        <Alert severity="error">Error Subscribing.</Alert>

        <Grid container align="center">
          <Grid item md={12}>
            <Typography variant="h4">
              {data.educatorSubscriptionPlan.title}
            </Typography>
            <Typography>₹{data.educatorSubscriptionPlan.cost}</Typography>
            <Typography>
              <pre>{data.educatorSubscriptionPlan.description}</pre>
            </Typography>
          </Grid>
        </Grid>
      </>
    );
  }

  if (success && result1?.data?.response?.id) {
    return (
      <>
        <Alert icon={<CheckCircleOutline fontSize="inherit" />} severity="success">
          Successfully Subscribed.
        </Alert>
        <Alert icon={<CheckCircleOutline fontSize="inherit" />} severity="success">
          status: {result1?.data?.response?.status}
        </Alert>
        <Alert icon={<CheckCircleOutline fontSize="inherit" />} severity="success">
          Transaction Id: {result1?.data?.response?.payment?.transaction_id}
        </Alert>

        <Grid container align="center">
          <Grid item md={12}>
            <Typography variant="h4">
              {data.educatorSubscriptionPlan.title}
            </Typography>
            <Typography>₹{data.educatorSubscriptionPlan.cost}</Typography>
            <Typography>
              <pre>{data.educatorSubscriptionPlan.description}</pre>
            </Typography>
          </Grid>
        </Grid>
      </>
    );
  }

  return (
    <>
      {isSuccess && data.educatorSubscriptionPlan ? (
        <>
          <Helmet>
            <script src="https://checkout.razorpay.com/v1/checkout.js" />
          </Helmet>
          <Grid container align="center">
            <Grid item md={12}>
              <Typography variant="h4">
                {data.educatorSubscriptionPlan.title}
              </Typography>
              <Typography>₹{data.educatorSubscriptionPlan.cost}</Typography>
              <Typography>
                <pre>{data.educatorSubscriptionPlan.description}</pre>
              </Typography>

              {isSignedIn && user.role !== 'educator' ? (
                <Alert severity="info">
                  Please register as an educator to subscribe
                </Alert>
              ) : (
                <Button
                  variant="contained"
                  color="secondary"
                  // onClick={handleRazorPayPayment}
                  onClick={() => {
                    isSignedIn
                      ? handleRazorPayPayment()
                      : navigate('/student-account', {
                          state: location.pathname,
                        });
                  }}
                  disabled={data.educatorSubscriptionPlan.alreadySubscribed}
                >
                  {data.educatorSubscriptionPlan.alreadySubscribed
                    ? 'Subscribed'
                    : 'Pay'}
                </Button>
              )}
            </Grid>
          </Grid>
        </>
      ) : (
        <p>No educator subscriptionPlan found</p>
      )}
    </>
  );
}

export default DisplaySingleEducatorSubscriptionPlan;
