/* eslint-disable no-unused-vars */
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Grid,
  LinearProgress,
  Typography,
} from '@mui/material';
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import {
  TextField,
  IconButton,
  Tabs,
  Tab,
  InputAdornment,
  Icon,
  Dialog,
  DialogActions,
  DialogContent,
} from '@mui/material';

import {
  Add,
  DeleteOutline,
  EditNote,
  SaveOutlined,
  SignalCellularNull,
} from '@mui/icons-material';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import TopicVideoProgressRequest from './subjects/topics/TopicVideoProgressRequest';
import {
  useDeleteTopicDocumentMutation,
  useDeleteTopicVideoMutation,
  useUpdateTopicExternalLinkMutation,
} from '../../../app/services/CoursesApi';
import DisplayCourseVideos from './DisplayCourseVideos';
import DisplayCourseDocuments from './DisplayCourseDocuments';
import TabPanel from '../../../components/authentication/TabPanel';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import DisplayDocument from './DisplayDocument';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { useNavigate } from 'react-router-dom';

// eslint-disable-next-line no-undef
const API_URL = `${process.env.REACT_APP_BACKEND_API_PATH}/api`;

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

function DisplayAndEditTopicData(props) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [duration, setSetDuration] = useState(0);
  const [progress, setProgress] = useState(0);
  const {
    sind,
    tind,
    topic,
    subject,
    handleSelectVideo,
    handleSelectDocument,
    handleExternalData,
  } = props;
  const videoRef = useRef();
  const { token } = useSelector((state) => state.auth);
  const [videoStatus, setVideoStatus] = useState(null);
  const [processProgress, setProcessProgress] = useState(0);
  const [video, setVideo] = useState(null);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [vidUrl, setVideoUrl] = useState(null);
  const [value, setValue] = useState(null);

  const docRef = useRef();
  const [documentStatus, setDocumentStatus] = useState(null);
  //   const [processProgress, setProcessProgress] = useState(0);
  const [document, setDocument] = useState(null);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [documentUrl, setDocumentUrl] = useState(null);
  const [updateTopicExternalLink, updateRes] = useUpdateTopicExternalLinkMutation();
  const [open, setOpen] = useState(false);
  const [deleteTopicVideo, deletionResult] = useDeleteTopicVideoMutation();
  const [deleteTopicDocument, deletionResult1] = useDeleteTopicDocumentMutation();

  const loadedMetaData = (event) => {
    const video = videoRef.current;
    if (!video) return;

    setSetDuration(Math.floor(video.duration));
  };

  useEffect(() => {
    if (selectedVideo) {
      setVideoUrl(URL.createObjectURL(selectedVideo));
    }
    videoRef.current?.load();
  }, [selectedVideo]);

  useEffect(() => {
    if (selectedDocument) {
      setDocumentUrl(URL.createObjectURL(selectedDocument));
    }
    docRef.current?.load();
  }, [selectedDocument]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const fileUploadRequest = (uploadData) => {
    return axios.post(
      `${API_URL}/course/subject/topic/video/upload/${uploadData.topicId}`,
      {
        file: uploadData.file,
        file_type: 'topic',
        title: uploadData.title,
        description: uploadData.description,
        duration: uploadData.duration,
      },
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${uploadData.token}`,
        },

        onUploadProgress: (progressEvent) => {
          var percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );

          setProgress(percentCompleted);
        },
      }
    );
  };

  const UploadVideo = (sind, tind, subjectId, topicId) => {
    if (subject && topic) {
      const videoUploadData = {
        file: topic.video,
        token: token,
        title: topic.title,
        description: topic.title,
        duration: duration,
        topicId: topicId,
      };

      setVideoStatus('PROCESSING');

      fileUploadRequest(videoUploadData)
        .then((res) => {
          if (res.data.video.id) {
            setVideo(res.data.video);
            setVideoStatus('SUCCESS');
            setProcessProgress(100);
            handleSelectVideo(sind, tind, res.data.video);

            enqueueSnackbar('Video Uploaded Successfully.', {
              variant: 'success',
            });
          } else {
            setVideoStatus('ERROR');
            enqueueSnackbar('Error uploading Video. ', {
              variant: 'error',
            });
          }
        })
        .catch((err) => {
          console.log(err);
          enqueueSnackbar(`Error uploading Video. ${topic.title}`, {
            variant: 'error',
          });
        });
    }
  };

  const fileUploadRequest1 = (uploadData) => {
    return axios.post(
      `${API_URL}/course/subject/topic/document/upload/${uploadData.topicId}`,
      {
        file: uploadData.file,
        file_type: 'document',
        title: uploadData.title,
        description: uploadData.description,
      },
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${uploadData.token}`,
        },

        onUploadProgress: (progressEvent) => {
          var percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );

          setProgress(percentCompleted);
        },
      }
    );
  };

  const uploadDocument = (sind, tind, subjectId, topicId) => {
    if (subject && topic) {
      const documentUploadData = {
        file: topic.document,
        token: token,
        title: topic.title,
        description: topic.title,
        topicId: topicId,
      };

      setDocumentStatus('PROCESSING');

      fileUploadRequest1(documentUploadData)
        .then((res) => {
          if (res.data.document.id) {
            setDocument(res.data.document);
            setDocumentStatus('SUCCESS');
            setProcessProgress(100);
            handleSelectDocument(sind, tind, res.data.document);

            enqueueSnackbar('Document Uploaded Successfully.', {
              variant: 'success',
            });
          } else {
            setDocumentStatus('ERROR');
            enqueueSnackbar('Error uploading Document. ', {
              variant: 'error',
            });
          }
        })
        .catch((err) => {
          console.log(err);
          enqueueSnackbar(`Error uploading Document. ${topic.title}`, {
            variant: 'error',
          });
        });
    }
  };

  const onExternalLinkSubmit = (e, topicToUpdate) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    if (
      data.get('externalLinkTitle').trim().length === 0 ||
      data.get('externalVideoLink').trim().length === 0
    ) {
      enqueueSnackbar('Link Title or Link cannot be empty or contain only spaces', {
        variant: 'error',
      });
      return err;
    }
    const updateData = {
      external_link_title: data.get('externalLinkTitle'),
      external_video_link: data.get('externalVideoLink'),
      id: topicToUpdate.id,
    };

    updateTopicExternalLink(updateData);
    // dispatch(api.util.invalidateTags(['Topic']));

    // if (updateData.external_link_title && updateData.external_video_link) {
    // } else {
    //   enqueueSnackbar('Please provide both title and link', {
    //     variant: 'error',
    //   });
    // }
  };

  const onDataChange = (e) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);

    const updateData = {
      external_link_title: data.get('externalLinkTitle'),
      external_video_link: data.get('externalVideoLink'),
    };
    handleExternalData(
      sind,
      tind,
      updateData.external_link_title,
      updateData.external_video_link
    );
  };

  const onDeleteVideo = (id) => {
    deleteTopicVideo({ id })
      .unwrap()
      .then((response) => {
        if (response.message === 'DELETED') {
          setSelectedVideo(null);
          handleSelectVideo(sind, tind, null);
        }
      });
  };
  const onDeleteDocument = (id) => {
    deleteTopicDocument({ id })
      .unwrap()
      .then((response) => {
        if (response.message === 'DELETED') {
          setSelectedDocument(null);
          handleSelectDocument(sind, tind, null);
        }
      });
  };
  const viewVideo = (id) => {
    navigate(`/course/video/${id}`);
  };
  return (
    <>
      <div style={{ marginTop: '0.5rem' }}>
        <Tabs
          value={value}
          onChange={(e, newval) => handleChange(e, newval)}
          textColor="primary"
          indicatorColor="primary"
          aria-label="Account forms"
        >
          <Tab
            label="Upload Video"
            id="simple-tab-0"
            aria-controls="simple-tabpanel-0"
            disabled={topic.external_video_link !== null || topic.document !== null}
          />
          <Tab
            label="Uploaded Video"
            id="simple-tab-1"
            aria-controls="simple-tabpanel-0"
            disabled={topic.external_video_link !== null || topic.document !== null}
          />
          <Tab
            label="External Link"
            id="simple-tab-2"
            aria-controls="simple-tabpanel-1"
            disabled={topic.video !== null || topic.document !== null}
          />
          <Tab
            label="Upload Document"
            id="simple-tab-3"
            aria-controls="simple-tabpanel-1"
            disabled={topic.video !== null || topic.external_video_link !== null}
          />
          <Tab
            label="Uploaded Document"
            id="simple-tab-4"
            aria-controls="simple-tabpanel-1"
            disabled={topic.video !== null || topic.external_video_link !== null}
          />
        </Tabs>
        <TabPanel value={value} index={0}>
          <Grid container spacing={1}>
            {/* <DisplayAndUploadVideo
              sind={subjectIndex}
              tind={topicIndex}
              topic={topic}
              subject={singleSubject}
              handleSelectVideo={handleSelectVideo}
            /> */}
            <Grid item xl="10" lg="10" md="12" sm="12" xs="12">
              <form
                className="form"
                style={{
                  marginTop: '1rem',
                }}
              >
                <div
                  className="file-upload-wrapper"
                  data-text={
                    topic.video
                      ? topic.video.name || topic.video.title
                      : 'No File Selected'
                  }
                  data-button-text={topic.video ? 'Change video' : 'Select video'}
                >
                  <input
                    name="file-upload-field"
                    type="file"
                    accept="video/*"
                    className="file-upload-field"
                    value=""
                    onChange={(e) => {
                      const selectedFile = e.target.files[0];
                      if (selectedFile && selectedFile.type.includes('video')) {
                        setSelectedVideo(e.target.files[0]);
                        handleSelectVideo(sind, tind, e.target.files[0]);
                      } else {
                        enqueueSnackbar('Please select a Video file.', {
                          variant: 'error',
                        });
                      }
                    }}
                    disabled={progress > 0}
                  />
                </div>
              </form>
              {progress > 0 ? (
                <Box sx={{ width: '100%', mt: '4px' }}>
                  <LinearProgressWithLabel value={progress} color="secondary" />
                </Box>
              ) : null}
              {/* {processProgress > 39 ? (
            <TopicVideoProgressRequest
              uploadProcessProgress={processProgress}
              setUploadProcessProgress={setProcessProgress}
              id={video.media_convert_job_id}
            />
          ) : null} */}

              {videoStatus === 'PROCESSING' ? (
                <Alert severity="info">
                  Your video is being uploaded. Please Wait
                </Alert>
              ) : null}
              {videoStatus === 'ERROR' ? (
                <Alert severity="error">Error Uploading Video</Alert>
              ) : null}
              {processProgress === 100 ? (
                <Alert severity="success">
                  Your video Uploaded Successfully. May take some time to process.
                </Alert>
              ) : null}
              {selectedVideo ? (
                <Box sx={{ mt: '12px' }}>
                  <Button
                    variant="contained"
                    onClick={() => {
                      setSelectedVideo(null);
                      handleSelectVideo(sind, tind, null);
                    }}
                    disabled={progress > 0}
                  >
                    Cancel
                  </Button>
                </Box>
              ) : null}
            </Grid>
            <Grid item xl="2" lg="2" md="12" sm="12" xs="12">
              {selectedVideo ? (
                <>
                  <video
                    controls
                    ref={videoRef}
                    controlsList="nodownload"
                    onLoadedMetadata={loadedMetaData}
                  >
                    <source
                      src={selectedVideo ? URL.createObjectURL(selectedVideo) : null}
                      type="video/mp4"
                    />
                    Your browser does not support HTML video.
                  </video>

                  <Button
                    variant="contained"
                    onClick={() => UploadVideo(sind, tind, subject.id, topic.id)}
                    disabled={progress > 0}
                  >
                    Upload Video
                  </Button>
                </>
              ) : // <img
              //   src={null}
              //   style={{
              //     border: '1px solid #c7c7c7',
              //     width: '100%',
              //     height: '100%',
              //   }}
              // />
              null}
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={1}>
          {topic?.video?.id && (
            <Grid container spacing={1}>
              <Grid item xl="10" lg="10" md="12" sm="12" xs="12">
                <Box sx={{ display: 'flex', marginLeft: '20px' }}>
                  <Button
                    variant="contained"
                    onClick={() => viewVideo(topic.video.id)}
                  >
                    {topic.video.title}&nbsp;
                    {<PlayCircleOutlineIcon />}
                  </Button>
                  {deletionResult?.data?.message === 'DELETED' ? (
                    <Alert severity="success">Your Video Deleted Successfully</Alert>
                  ) : topic?.video?.video_url ? (
                    <Button onClick={() => onDeleteVideo(topic.video.id)}>
                      Delete
                    </Button>
                  ) : (
                    <p>
                      ( Still processing video. Ff it takes longer than usual. Please
                      contact support.)
                    </p>
                  )}
                </Box>
              </Grid>
            </Grid>
          )}
        </TabPanel>

        <TabPanel value={value} index={2}>
          <Grid container>
            <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
              <Box
                marginTop={'1rem'}
                component="form"
                onSubmit={(e) => onExternalLinkSubmit(e, topic)}
                noValidate
                onChange={(e) => {
                  onDataChange(e);
                }}
              >
                <TextField
                  required
                  fullWidth
                  size="small"
                  label={`Add Title`}
                  id="externalLinkTitle"
                  name="externalLinkTitle"
                  autoComplete="externalLinkTitle"
                  autoFocus
                  defaultValue={topic.external_link_title}
                />
                <TextField
                  required
                  fullWidth
                  size="small"
                  sx={{ mt: '0.5rem' }}
                  label={`Add Video URL`}
                  id="externalVideoLink"
                  name="externalVideoLink"
                  autoComplete="externalVideoLink"
                  autoFocus
                  defaultValue={topic.external_video_link}
                />
                {updateRes?.originalArgs?.id === topic.id && updateRes?.isError ? (
                  <Alert severity="error">Error updating external data</Alert>
                ) : null}
                {updateRes?.originalArgs?.id === topic.id && updateRes?.isSuccess ? (
                  <Alert severity="success">
                    External data updated Successfully
                  </Alert>
                ) : null}
                <Button
                  variant="contained"
                  type="submit"
                  sx={{ mt: '0.5rem', float: 'right' }}
                  disabled={updateRes?.isLoading}
                >
                  Submit
                </Button>
              </Box>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={3}>
          <Grid container>
            <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
              {/* <DisplayAndUploadDocument
                sind={subjectIndex}
                tind={topicIndex}
                topic={topic}
                subject={singleSubject}
                handleSelectDocument={handleSelectDocument}
              /> */}

              <Grid item xl="10" lg="10" md="12" sm="12" xs="12">
                <form
                  className="form"
                  style={{
                    marginTop: '1rem',
                  }}
                >
                  <div
                    className="file-upload-wrapper"
                    data-text={
                      topic.document
                        ? topic.document.name || topic.document.title
                        : 'No File Selected'
                    }
                    data-button-text={
                      topic.document ? 'Change document' : 'Select document'
                    }
                  >
                    <input
                      name="file-upload-field"
                      type="file"
                      accept=".pdf"
                      className="file-upload-field"
                      value=""
                      onChange={(e) => {
                        const selectedFile = e.target.files[0];
                        if (selectedFile && selectedFile.type.includes('pdf')) {
                          setSelectedDocument(e.target.files[0]);
                          handleSelectDocument(sind, tind, e.target.files[0]);
                        } else {
                          enqueueSnackbar('Please select a PDF file.', {
                            variant: 'error',
                          });
                        }
                      }}
                      disabled={progress > 0 && progress < 100}
                    />
                  </div>
                </form>
                {progress > 0 ? (
                  <Box sx={{ width: '100%', mt: '4px' }}>
                    <LinearProgressWithLabel value={progress} color="secondary" />
                  </Box>
                ) : null}

                {documentStatus === 'PROCESSING' ? (
                  <Alert severity="info">
                    Your document is being uploaded. Please Wait
                  </Alert>
                ) : null}
                {documentStatus === 'ERROR' ? (
                  <Alert severity="error">Error Uploading Document</Alert>
                ) : null}
                {processProgress === 100 ? (
                  <Alert severity="success">
                    Your document Uploaded Successfully
                  </Alert>
                ) : null}
                {selectedDocument ? (
                  <Box sx={{ mt: '12px' }}>
                    <Button
                      variant="contained"
                      onClick={() => {
                        setSelectedDocument(null);
                        handleSelectDocument(sind, tind, null);
                      }}
                      disabled={progress > 0}
                    >
                      Cancel
                    </Button>
                  </Box>
                ) : null}
              </Grid>
              <Grid item xl="2" lg="2" md="12" sm="12" xs="12">
                {topic.document ? (
                  <>
                    <IconButton
                      aria-label="fingerprint"
                      color="secondary"
                      onClick={handleClickOpen}
                    >
                      <AttachFileIcon /> View File
                    </IconButton>
                    <Dialog open={open} onClose={handleClose} maxWidth="md">
                      <DialogContent>
                        <DisplayDocument doc={topic.document} />
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleClose}>Close</Button>
                      </DialogActions>
                    </Dialog>

                    <Button
                      variant="contained"
                      onClick={() =>
                        uploadDocument(sind, tind, subject.id, topic.id)
                      }
                      disabled={progress > 0}
                    >
                      Upload File
                    </Button>
                  </>
                ) : null}
              </Grid>
            </Grid>
          </Grid>
        </TabPanel>

        <TabPanel value={value} index={4}>
          {topic?.document?.id && (
            <Grid container spacing={1}>
              <Grid item xl="10" lg="10" md="12" sm="12" xs="12">
                <Box sx={{ display: 'flex', marginLeft: '20px' }}>
                  <Typography>{topic.document.title}</Typography>
                  <IconButton
                    aria-label="fingerprint"
                    color="secondary"
                    onClick={handleClickOpen}
                  >
                    <AttachFileIcon /> View File
                  </IconButton>
                  <Dialog open={open} onClose={handleClose} maxWidth="md">
                    <DialogContent>
                      <DisplayDocument doc={topic.document} />
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleClose}>Close</Button>
                    </DialogActions>
                  </Dialog>
                  {deletionResult1?.data?.message === 'DELETED' ? (
                    <Alert severity="success">
                      Your Document Deleted Successfully
                    </Alert>
                  ) : topic?.document?.uploaded_path ? (
                    <Button onClick={() => onDeleteDocument(topic.document.id)}>
                      Delete
                    </Button>
                  ) : (
                    <p>(Error fetching document. Contact Support)</p>
                  )}
                </Box>
              </Grid>
            </Grid>
          )}
        </TabPanel>
      </div>
    </>
  );
}

export default DisplayAndEditTopicData;
