import { Box, Grid, Typography } from '@mui/material';
import React, { Fragment } from 'react';

export const PrivacyPloicy = () => {
  const pageData = [
    {
      title: 'Information We Collect',
      list: [
        {
          title: 'Personal Information',
          data: `We collect personal information such as your name, email
                      address, and payment details when you register or make a purchase`,
        },
        {
          title: 'Usage Data',
          data: `We collect data on how you use our Service, including your IP address,
            browser type, and usage patterns`,
        },
      ],
    },
    {
      title: 'How We Use Your Information',
      list: [
        {
          title: '',
          data: `To provide and maintain our Service`,
        },
        {
          title: '',
          data: `To process your transactions`,
        },
        {
          title: '',
          data: `To communicate with you about your account and provide customer support`,
        },
        {
          title: '',
          data: `To improve our Service`,
        },
      ],
    },
    {
      title: 'Sharing Your Information',
      list: [
        {
          title: '',
          data: `We do not share your personal information with third parties except as necessary to
            provide our Service or as required by law`,
        },
      ],
    },
    {
      title: 'Security of Your Information',
      list: [
        {
          title: '',
          data: `We use industry-standard security measures to protect your information. However, no
            method of transmission over the internet is 100% secure`,
        },
      ],
    },
    {
      title: 'Your Rights',
      list: [
        {
          title: '',
          data: `You have the right to access, update, or delete your personal information. Contact us to
            exercise these rights`,
        },
      ],
    },
    {
      title: 'Changes to Privacy Policy',
      list: [
        {
          title: '',
          data: `We may update this Privacy Policy from time to time. Users will be notified of significant
            changes`,
        },
      ],
    },
    {
      title: 'Contact Information',
      list: [
        {
          title: '',
          data: `For any questions about this Privacy Policy, please contact us at
            connect@dcentmass.com`,
        },
      ],
    },
  ];
  return (
    <Fragment>
      <Grid container justifyContent={'center'}>
        <Grid item xl={10} lg={10} md={11} sm={11}>
          <Typography
            sx={{
              color: '#000',
              fontSize: '24px',
              fontWeight: 700,
              textAlign: 'center',
            }}
          >
            Privacy Policy
          </Typography>
          <Box sx={{ marginTop: '1rem' }}>
            <Typography
              sx={{
                color: '#000',
                fontSize: '18px',
                fontWeight: 600,
              }}
            >
              Introduction
            </Typography>
            <Typography
              sx={{
                color: '#000',
                fontSize: '16px',
                fontWeight: 500,
                marginTop: '0.5rem',
              }}
            >
              This Privacy Policy explains how DCENTMASS collects, uses, discloses,
              and protects your information when you use our Service.
            </Typography>
          </Box>
          {pageData.map((singleData, ind) => (
            <Box sx={{ marginTop: '1.5rem' }} key={ind + singleData?.title}>
              <Typography
                sx={{
                  color: '#000',
                  fontSize: '18px',
                  fontWeight: 600,
                }}
              >
                {singleData?.title}
              </Typography>
              {Array.isArray(singleData?.list) &&
                singleData?.list.map((singleList, singleIndex) => (
                  <li
                    style={{
                      color: '#000',
                      fontSize: '16px',
                      fontWeight: 500,
                      marginTop: '0.5rem',
                      marginLeft: '1rem',
                    }}
                    key={singleIndex + ' list'}
                  >
                    {singleList?.title ? (
                      <span
                        style={{ fontWeight: 700 }}
                      >{`${singleList?.title} : `}</span>
                    ) : null}
                    {`${singleList?.data} .`}
                  </li>
                ))}
            </Box>
          ))}
        </Grid>
      </Grid>
    </Fragment>
  );
};
