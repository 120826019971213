/* eslint-disable no-unused-vars */
import { Button, Box, TextField, MenuItem, InputAdornment } from '@mui/material';

import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { usePublishedCategoriesQuery } from '../../../../app/services/CategoriesApi';
import { useCreateStudentSubscriptionPlanMutation } from '../../../../app/services/StudentSubscriptionPlansApi';

function CreateNewStudentSubscriptionPlan() {
  const { enqueueSnackbar } = useSnackbar();
  const { data, isLoading, isSuccess } = usePublishedCategoriesQuery();

  const [createSubscriptionPlan, result] =
    useCreateStudentSubscriptionPlanMutation();

  const [categoryTitle, setCategoryTitle] = useState('');

  const [selectedCategory, setSelectedCategory] = useState({
    title: '',
    id: null,
    published_sub_categories: [],
  });

  const validateData = (subscriptionPlanData) => {
    let err = true;

    if (
      !subscriptionPlanData.title ||
      !subscriptionPlanData.no_of_days ||
      !subscriptionPlanData.category_id
    ) {
      enqueueSnackbar('Please provide all the required fields', {
        variant: 'error',
      });
    } else {
      err = false;
    }

    return err;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const subscriptionPlanData = {
      title: data.get('title'),
      description: data.get('description'),
      no_of_days: data.get('noOfDays'),
      category_id: selectedCategory.id,
    };

    const res = validateData(subscriptionPlanData);
    if (!res) {
      createSubscriptionPlan(subscriptionPlanData);
    }
  };

  const handleCategoryChange = (event) => {
    const tempSelectedCategory = data.categories.find(
      (singleCategory) => singleCategory.id === event.target.value
    );
    setSelectedCategory(tempSelectedCategory);
    setCategoryTitle(event.target.value);
  };

  return result.data ? (
    result.data.message
  ) : (
    <>
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="title"
          label=" Title"
          name="title"
          autoComplete="title"
          autoFocus
        />
        <TextField
          margin="normal"
          fullWidth
          id="description"
          label="Description"
          name="description"
          autoComplete="description"
          autoFocus
          multiline
          rows="2"
        />

        <TextField
          margin="normal"
          select
          required
          fullWidth
          id="category"
          label="Category"
          onChange={handleCategoryChange}
          name="category"
          autoComplete="category"
          autoFocus
          value={categoryTitle}
        >
          {data.categories && data.categories.length
            ? data.categories.map((singleCategory, index) => (
                <MenuItem value={singleCategory.id} key={singleCategory.id}>
                  {singleCategory.title}
                </MenuItem>
              ))
            : null}
        </TextField>

        <TextField
          id="noOfDays"
          fullWidth
          name="noOfDays"
          required
          label="Add Number Of Days Plan is Valid"
          sx={{ mt: 3, mb: 2 }}
          type="number"
          InputLabelProps={{
            shrink: true,
          }}
        />

        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          disabled={result.isLoading || result.isSuccess}
        >
          Add Student Subscription Plan
        </Button>
      </Box>
    </>
  );
}

export default CreateNewStudentSubscriptionPlan;
