import { Grid, Typography, Button } from '@mui/material';
import React, { useState } from 'react';
// import { useStudentSubscriptionsQuery } from '../../../app/services/StudentApi';
// import DataLoader from '../../../components/DataLoader';
// import StudentSubscriptionsList from './StudentSubscriptionsList';
import { StudentSubscriptionPlans } from './StudentSubscriptionPlans';

function StudentSubscriptionManager() {
  const [selectedPaymentDuration, setSelectedPaymentDuration] = useState('Month');
  // const { data, isLoading, isSuccess } = useStudentSubscriptionsQuery();

  // if (isLoading) {
  //   return <DataLoader thickness={5} size={50} />;
  // }

  return (
    <>
      <Grid container spacing={1}>
        {/* <Grid item xs="12" sm="12" md="12" lg="12" xl="12">
          {isSuccess && data.subscriptions.length ? (
            <StudentSubscriptionsList subscriptions={data.subscriptions} />
          ) : (
            <p>No subscriptions found</p>
          )}
        </Grid> */}
        <Grid
          item
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          display={'flex'}
          alignSelf={'center'}
          justifyContent={'center'}
        >
          <Typography
            sx={{
              color: '#1b75bb',
              fontSize: '24px',
              fontWeight: 500,
              maxWidth: '940px',
              textAlign: 'center',
              alignSelf: 'center',
              marginTop: '3rem',
            }}
          >
            <span
              style={{
                fontWeight: 700,
                fontSize: '40px',
                lineHeight: 0,
              }}
            >
              Unlock premium features
            </span>{' '}
            and elevate your teaching experience with GM&apos;s subscription plans.
            Choose the plan
            <span
              style={{
                fontWeight: 700,
                fontSize: '40px',
                lineHeight: 0,
              }}
            >
              {' '}
              that suits your needs
            </span>{' '}
            and start creating impactful learning experiences today
          </Typography>
        </Grid>
        <Grid
          item
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          display={'flex'}
          alignSelf={'center'}
          justifyContent={'center'}
          paddingBottom={'1rem'}
          paddingTop={'2rem !important'}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '1rem',
              position: 'relative',
            }}
          >
            <div>
              <Button
                variant={
                  selectedPaymentDuration === 'Month' ? 'contained' : 'outlined'
                }
                sx={{
                  borderRadius: '0px',
                  background: selectedPaymentDuration === 'Month' ? '#20acc1' : '',
                  ':hover': {
                    background: selectedPaymentDuration === 'Month' ? '#20acc1' : '',
                  },
                }}
                onClick={() => {
                  setSelectedPaymentDuration('Month');
                }}
              >
                Monthly Billing
              </Button>
              <Button
                variant={
                  selectedPaymentDuration === 'Year' ? 'contained' : 'outlined'
                }
                sx={{
                  borderRadius: '0px',
                  background: selectedPaymentDuration === 'Year' ? '#20acc1' : '',
                  ':hover': {
                    background: selectedPaymentDuration === 'Year' ? '#20acc1' : '',
                  },
                }}
                onClick={() => {
                  setSelectedPaymentDuration('Year');
                }}
              >
                Yearly Billing
              </Button>
            </div>
            {selectedPaymentDuration == 'Year' && (
              <Typography sx={{ position: 'absolute', right: '-90px' }}>
                Save 10%
              </Typography>
            )}
          </div>
        </Grid>
      </Grid>
      <Grid container justifyContent={'center'}>
        <Grid
          item
          xl={11}
          lg={11}
          md={12}
          sm={12}
          xs={12}
          sx={{ marginTop: '1rem' }}
        >
          <StudentSubscriptionPlans
            selectedPaymentDuration={selectedPaymentDuration}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default StudentSubscriptionManager;
