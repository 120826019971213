/* eslint-disable no-unused-vars */
import React, { Fragment } from 'react';
import {
  Box,
  Button,
  Grid,
  Paper,
  Slider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { BarChart } from '@mui/x-charts';
import { PieChart } from '@mui/x-charts/PieChart';
import GaugeIndicator from './GaugeIndicator';
import { Gauge } from '@mui/x-charts/Gauge';
import { CustomDateRange } from './CustomDateRange';

export const EducatorEarning = () => {
  const chartSetting = {
    yAxis: [
      {
        // label: 'My Earning',
      },
    ],
    width: 500,
    height: 400,
  };
  const dataset = [
    {
      data: 150000,
      month: 'Till Date',
    },
    {
      data: 20000,
      month: 'Current Month',
    },
    {
      data: 15000,
      month: 'Last Month',
    },
  ];

  const valueFormatter = (value) => `${value}`;

  function createData(one, two, three, four, five, six, seven, eight, nine) {
    return { one, two, three, four, five, six, seven, eight, nine };
  }
  return (
    <Fragment>
      <div style={{ position: 'relative' }}>
        <div
          style={{
            position: 'absolute',
            bottom: '0.7rem',
            right: '0rem',
            display: 'inline-block',
          }}
        >
          <CustomDateRange />
        </div>
      </div>
      <Grid container spacing={2} sx={{ marginTop: '0rem' }}>
        <Grid item xl={12} lg={12} md={12} sm={12} justifyContent={'center'}>
          <Box sx={{ background: '#fff', boxShadow: '1px 1px 10px 1px #dcdcdc' }}>
            <Grid container>
              <Grid item xl={8} lg={8} md={12} sm={12}>
                <Grid container>
                  <Grid item xl={4} lg={5} md={12} sm={12}>
                    <div
                      style={{
                        display: 'flex',
                        gap: '1rem',
                        flexWrap: 'wrap',
                        padding: '1rem',
                      }}
                    >
                      <div
                        style={{
                          height: '120px',
                          minWidth: '200px',
                          background: '#ef9bb1',
                          borderRadius: '10px',
                          display: 'grid',
                          alignItems: 'center',
                        }}
                      >
                        <div>
                          <Typography
                            sx={{
                              color: '#124b6b',
                              textAlign: 'center',
                              fontWeight: 600,
                            }}
                          >
                            Earnings Till Date
                          </Typography>
                          <Typography
                            sx={{
                              color: '#124b6b',
                              textAlign: 'center',
                              fontWeight: 600,
                            }}
                          >
                            Rs. 150,000.00
                          </Typography>
                        </div>
                      </div>
                      <div
                        style={{
                          height: '120px',
                          width: '200px',
                          background: '#e2b5ff',
                          borderRadius: '10px',
                          display: 'grid',
                          alignItems: 'center',
                        }}
                      >
                        <div>
                          <Typography
                            sx={{
                              color: '#124b6b',
                              textAlign: 'center',
                              fontWeight: 600,
                            }}
                          >
                            Last Month
                          </Typography>
                          <Typography
                            sx={{
                              color: '#124b6b',
                              textAlign: 'center',
                              fontWeight: 600,
                            }}
                          >
                            Rs. 150,000.00
                          </Typography>
                        </div>
                      </div>
                      <div
                        style={{
                          height: '120px',
                          width: '200px',
                          background: '#a0e894',
                          borderRadius: '10px',
                          display: 'grid',
                          alignItems: 'center',
                        }}
                      >
                        <div>
                          <Typography
                            sx={{
                              color: '#124b6b',
                              textAlign: 'center',
                              fontWeight: 600,
                            }}
                          >
                            Current Month
                          </Typography>
                          <Typography
                            sx={{
                              color: '#124b6b',
                              textAlign: 'center',
                              fontWeight: 600,
                            }}
                          >
                            Rs. 150,000.00
                          </Typography>
                        </div>
                      </div>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xl={8}
                    lg={7}
                    md={12}
                    sm={12}
                    alignSelf={'center'}
                    justifyContent={'center'}
                    sx={{ display: 'flex' }}
                  >
                    <BarChart
                      dataset={dataset}
                      xAxis={[{ scaleType: 'band', dataKey: 'month' }]}
                      series={[
                        {
                          dataKey: 'data',
                          label: 'My Earning',
                          valueFormatter,
                        },
                      ]}
                      layout="vertical"
                      {...chartSetting}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                xl={4}
                lg={4}
                md={12}
                sm={12}
                sx={{ borderLeft: '3px solid #cbcbcb', padding: '1rem' }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    marginTop: '1rem',
                    padding: '2rem',
                    background: '#f5f5b5',
                    justifyItems: 'center',
                    gap: '1rem',
                    borderRadius: '30px',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    sx={{
                      color: '#124b6b',
                      fontSize: '16px',
                      fontWeight: 600,
                      textAlign: 'center',
                    }}
                  >
                    Unpaid Earnings
                  </Typography>
                  <Typography
                    sx={{
                      color: '#124b6b',
                      fontSize: '18px',
                      fontWeight: 500,
                      textAlign: 'center',
                    }}
                  >
                    Rs. 150,000.00
                  </Typography>
                  <Button
                    sx={{
                      background: '#f5f5b5',
                      color: '#09768d',
                      border: '2px solid #09768d',
                      borderRadius: '50px',
                      fontSize: '18px',
                      fontWeight: 700,
                      maxWidth: '300px',
                      '&:hover': {
                        background: '#f5f5b5',
                      },
                    }}
                  >
                    Withdraw Payment
                  </Button>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    marginTop: '1rem',
                    padding: '2rem',
                    background: '#9cf4b7',
                    justifyItems: 'center',
                    gap: '1rem',
                    borderRadius: '30px',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    sx={{
                      color: '#124b6b',
                      fontSize: '16px',
                      fontWeight: 600,
                      textAlign: 'center',
                    }}
                  >
                    Last Payment Withdrawal
                  </Typography>
                  <Typography
                    sx={{
                      color: '#124b6b',
                      fontSize: '18px',
                      fontWeight: 500,
                      //   textAlign: 'center',
                      display: 'flex',
                      justifyContent: 'space-between',
                      width: '100%',
                    }}
                  >
                    <span>24-May-2024</span> <span>Rs. 150,000.00</span>
                  </Typography>
                  <Button
                    sx={{
                      background: '#9cf4b7',
                      color: '#09768d',
                      border: '2px solid #09768d',
                      borderRadius: '50px',
                      fontSize: '18px',
                      fontWeight: 700,
                      maxWidth: '300px',
                      '&:hover': {
                        background: '#9cf4b7',
                      },
                    }}
                  >
                    View Payment History
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12}>
          <Box
            sx={{
              background: '#fff',
              padding: '1rem',
              boxShadow: '1px 1px 10px 1px #dcdcdc',
            }}
          >
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: '100%' }} aria-label="simple table">
                <TableHead sx={{ background: '#10496a' }}>
                  <TableRow>
                    <TableCell
                      sx={{
                        color: '#fff',
                        fontSize: '16px',
                        fontWeight: 700,
                        width: '200px',
                      }}
                    >
                      My Work
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        color: '#fff',
                        fontSize: '16px',
                        fontWeight: 700,
                      }}
                    >
                      Enrolls
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        color: '#fff',
                        fontSize: '16px',
                        fontWeight: 700,
                      }}
                    >
                      Average Watch Hrs
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        color: '#fff',
                        fontSize: '16px',
                        fontWeight: 700,
                      }}
                    >
                      Gross Earned
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        color: '#fff',
                        fontSize: '16px',
                        fontWeight: 700,
                      }}
                    >
                      TDS Deducted
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        color: '#fff',
                        fontSize: '16px',
                        fontWeight: 700,
                      }}
                    >
                      Net Earned
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        color: '#fff',
                        fontSize: '16px',
                        fontWeight: 700,
                      }}
                    >
                      Avg Rating
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        color: '#fff',
                        fontSize: '16px',
                        fontWeight: 700,
                      }}
                    >
                      My Rank
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        color: '#fff',
                        fontSize: '16px',
                        fontWeight: 700,
                      }}
                    >
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {[
                    createData(
                      'Self Paced Courses',
                      '400',
                      '-',
                      'Rs, 20,000',
                      'Rs 2,000',
                      'Rs 18,000',
                      '4.2%',
                      '-',
                      '-'
                    ),
                    createData(
                      'Live Course',
                      '400',
                      '-',
                      'Rs, 20,000',
                      'Rs 2,000',
                      'Rs 18,000',
                      '4.2%',
                      '-',
                      '-'
                    ),
                  ].map((row) => (
                    <TableRow
                      key={row?.name}
                      //   className="analytics-custom-mui-table-row"
                    >
                      <TableCell sx={{ padding: '0rem' }}>
                        <div className="table-div-one">{row?.one}</div>
                        <div className="table-div-two">
                          <Typography sx={{ margin: '0rem' }}>Free</Typography>
                          <Typography sx={{ margin: '0rem' }}>
                            Subscription
                          </Typography>
                          <Typography sx={{ margin: '0rem' }}>Premium</Typography>
                        </div>
                      </TableCell>
                      <TableCell sx={{ padding: '0rem' }} align="center">
                        <div className="table-div-one">{row?.two}</div>
                        <div className="table-div-two">
                          <Typography sx={{ margin: '0rem' }}>400</Typography>
                          <Typography sx={{ margin: '0rem' }}>400</Typography>
                          <Typography sx={{ margin: '0rem' }}>400</Typography>
                        </div>
                      </TableCell>
                      <TableCell sx={{ padding: '0rem' }} align="center">
                        <div className="table-div-one">{row?.three}</div>
                        <div className="table-div-two">
                          <Typography sx={{ margin: '0rem' }}>42%</Typography>
                          <Typography sx={{ margin: '0rem' }}>42%</Typography>
                          <Typography sx={{ margin: '0rem' }}>42%</Typography>
                        </div>
                      </TableCell>
                      <TableCell sx={{ padding: '0rem' }} align="center">
                        <div className="table-div-one">{row?.four}</div>
                        <div className="table-div-two">
                          <Typography sx={{ margin: '0rem' }}>Rs 20,000</Typography>
                          <Typography sx={{ margin: '0rem' }}>Rs 20,000</Typography>
                          <Typography sx={{ margin: '0rem' }}>Rs 20,000</Typography>
                        </div>
                      </TableCell>
                      <TableCell sx={{ padding: '0rem' }} align="center">
                        <div className="table-div-one">{row?.five}</div>
                        <div className="table-div-two">
                          <Typography sx={{ margin: '0rem' }}>Rs 2,000</Typography>
                          <Typography sx={{ margin: '0rem' }}>Rs 2,000</Typography>
                          <Typography sx={{ margin: '0rem' }}>Rs 2,000</Typography>
                        </div>
                      </TableCell>
                      <TableCell sx={{ padding: '0rem' }} align="center">
                        <div className="table-div-one">{row?.six}</div>
                        <div className="table-div-two">
                          <Typography sx={{ margin: '0rem' }}>Rs 18,000</Typography>
                          <Typography sx={{ margin: '0rem' }}>Rs 18,000</Typography>
                          <Typography sx={{ margin: '0rem' }}>Rs 18,000</Typography>
                        </div>
                      </TableCell>
                      <TableCell sx={{ padding: '0rem' }} align="center">
                        <div className="table-div-one">{row?.seven}</div>
                        <div className="table-div-two">
                          <Typography sx={{ margin: '0rem' }}>4.2%</Typography>
                          <Typography sx={{ margin: '0rem' }}>4.2%</Typography>
                          <Typography sx={{ margin: '0rem' }}>4.2%</Typography>
                        </div>
                      </TableCell>
                      <TableCell sx={{ padding: '0rem' }} align="center">
                        <div className="table-div-one">{row?.eight}</div>
                        <div className="table-div-two">
                          <Typography sx={{ margin: '0rem' }}>100</Typography>
                          <Typography sx={{ margin: '0rem' }}>100</Typography>
                          <Typography sx={{ margin: '0rem' }}>100</Typography>
                        </div>
                      </TableCell>
                      <TableCell sx={{ padding: '0rem' }} align="center">
                        <div className="table-div-one">{row?.nine}</div>
                        <div className="table-div-two">
                          <Typography sx={{ margin: '0rem' }}>Action</Typography>
                          <Typography sx={{ margin: '0rem' }}>Action</Typography>
                          <Typography sx={{ margin: '0rem' }}>Action</Typography>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell sx={{ padding: '0rem' }}>
                      <div className="table-div-one">Total</div>
                    </TableCell>
                    <TableCell sx={{ padding: '0rem' }} align="center">
                      <div className="table-div-one">400</div>
                    </TableCell>
                    <TableCell sx={{ padding: '0rem' }} align="center">
                      <div className="table-div-one">-</div>
                    </TableCell>
                    <TableCell sx={{ padding: '0rem' }} align="center">
                      <div className="table-div-one">Rs 20,000</div>
                    </TableCell>
                    <TableCell sx={{ padding: '0rem' }} align="center">
                      <div className="table-div-one">Rs 2,000</div>
                    </TableCell>
                    <TableCell sx={{ padding: '0rem' }} align="center">
                      <div className="table-div-one">Rs 18,000</div>
                    </TableCell>
                    <TableCell sx={{ padding: '0rem' }} align="center">
                      <div className="table-div-one">4.2 %</div>
                    </TableCell>
                    <TableCell sx={{ padding: '0rem' }} align="center">
                      <div className="table-div-one">-</div>
                    </TableCell>
                    <TableCell sx={{ padding: '0rem' }} align="center">
                      <div className="table-div-one">-</div>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Grid>
      </Grid>
    </Fragment>
  );
};
