/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BaseApi } from './BaseApi';

const API_URL = `${process.env.REACT_APP_BACKEND_API_PATH}/api`;
const LOCALSTORAGE_APP_KEY = `${process.env.REACT_APP_LOCALSTORAGE_KEY}`;

export const educatorSubscriptionPlansApi = BaseApi.injectEndpoints({
  reducerPath: 'educatorSubscriptionPlansApi',
  endpoints: (builder) => ({
    educatorSubscriptionPlans: builder.query({
      query: () => ({
        url: '/educator/subscription/plans',
        method: 'GET',
      }),
      providesTags: ['EducatorSubscriptionPlan'],
    }),
    createEducatorSubscriptionPlan: builder.mutation({
      query: (details) => ({
        url: '/educator/subscription/plan/create',
        method: 'POST',

        body: {
          title: details.title,
          description: details.description,
          cost: details.cost,
        },
      }),
      invalidatesTags: ['EducatorSubscriptionPlan'],
    }),
    educatorSubscriptionPlan: builder.query({
      query: (details) => ({
        url: `/educator/subscription/plan/show/${details.id}`,
        method: 'GET',
      }),
      providesTags: ['EducatorSubscriptionPlan'],
    }),
    updateEducatorSubscriptionPlan: builder.mutation({
      query: (details) => ({
        url: `/educator/subscription/plan/update/${details.id}`,
        method: 'POST',

        body: {
          title: details.title,
          description: details.description,
          cost: details.cost,
        },
      }),
      invalidatesTags: ['EducatorSubscriptionPlan'],
    }),
    updateEducatorSubscriptionPlanSatus: builder.mutation({
      query: (details) => ({
        url: '/educator/subscription/plan/status/update',
        method: 'POST',

        body: {
          status: details.status,
          educator_subscription_plan_id: details.id,
        },
      }),
      invalidatesTags: ['EducatorSubscriptionPlan'],
    }),
    publishedEducatorSubscriptionPlans: builder.query({
      query: (details) => ({
        url: `/public/educator/subscription/plans`,
        method: 'GET',
      }),
      providesTags: ['EducatorSubscriptionPlan'],
    }),
    createEducatorPaymentOrderId: builder.mutation({
      query: (details) => ({
        url: '/educator/subscription/payment/create/orderId',
        method: 'POST',

        body: {
          amount: details.amount,
          currency: details.currency,
        },
      }),
      // invalidatesTags: ['EducatorSubscriptionPlan'],
    }),
    publicEducatorSubscriptionPlan: builder.query({
      query: (details) => ({
        url: `/public/educator/subscription/plan/show/${details.id}`,
        method: 'GET',
      }),
      // providesTags: ['EducatorSubscriptionPlan'],
    }),

    createEducatorSubscriptionWithPayment: builder.mutation({
      query: (details) => ({
        url: '/educator/subscription/with/payment',
        method: 'POST',

        body: {
          razorpay_payment_id: details.razorpay_payment_id,
          order_id: details.order_id,
          razorpay_signature: details.razorpay_signature,
          amount: details.amount,
          educator_subscription_plan_id: details.educator_subscription_plan_id,
          email: details.email,
        },
      }),
      // invalidatesTags: ['EducatorSubscriptionPlan'],
    }),
    createEducatorOnboardPaymentOrderId: builder.mutation({
      query: (details) => ({
        url: '/educator/onboard/payment/create/orderId',
        method: 'POST',

        body: {
          amount: details.amount,
          currency: details.currency,
        },
      }),
      // invalidatesTags: ['EducatorSubscriptionPlan'],
    }),
    createEducatorOnboardWithPayment: builder.mutation({
      query: (details) => ({
        url: '/educator/onboard/with/payment',
        method: 'POST',

        body: {
          razorpay_payment_id: details.razorpay_payment_id,
          order_id: details.order_id,
          razorpay_signature: details.razorpay_signature,
          amount: details.amount,
          email: details.email,
        },
      }),
      // invalidatesTags: ['EducatorSubscriptionPlan'],
    }),
    educatorOnboard: builder.query({
      query: (token) => ({
        url: '/educator/onboard/show',
        method: 'GET',
        headers: { Authorization: `Bearer ${token}` },
      }),
    }),
  }),
});

export const {
  useEducatorSubscriptionPlansQuery,
  useCreateEducatorSubscriptionPlanMutation,
  useEducatorSubscriptionPlanQuery,
  useUpdateEducatorSubscriptionPlanMutation,
  useUpdateEducatorSubscriptionPlanSatusMutation,
  usePublishedEducatorSubscriptionPlansQuery,
  useCreateEducatorPaymentOrderIdMutation,
  usePublicEducatorSubscriptionPlanQuery,
  useCreateEducatorSubscriptionWithPaymentMutation,
  useCreateEducatorOnboardPaymentOrderIdMutation,
  useCreateEducatorOnboardWithPaymentMutation,
  useEducatorOnboardQuery,
} = educatorSubscriptionPlansApi;
