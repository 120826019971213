/* eslint-disable no-unused-vars */
import React, { Fragment, useEffect, useState } from 'react';
import { useEducatorProfileQuery } from '../../../app/services/EducatorApi';
import { Box, Grid, Tab, Tabs, Typography } from '@mui/material';
import TabPanel from '../../../components/authentication/TabPanel';
import { useLocation } from 'react-router-dom';
import { Analytics } from './Analytics';
import { EducatorEnrollment } from './EducatorEnrollment';
import { ReviewandRating } from './ReviewandRating';
import { CustomDateRange } from './CustomDateRange';
import { EducatorEarning } from './EducatorEarning';

export const EducatorPerformance = () => {
  const location = useLocation();
  const educator = useEducatorProfileQuery();

  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    const newActiveIndex = location?.state?.activeIndex;
    if (
      typeof newActiveIndex === 'number' &&
      newActiveIndex !== null &&
      newActiveIndex !== undefined
    ) {
      // setValue(newValue);
      handleChange({}, newActiveIndex);
    }
  }, [location, location?.state]);
  return (
    <Fragment>
      <Grid container spacing={2}>
        {/* <Grid
          item
          xl="6"
          lg="6"
          md="12"
          sm="12"
          xs="12"
          sx={{
            minHeight: '200px',
            background: '#0a6d84',
            alignSelf: 'center',
            display: 'flex',
          }}
        >
          <Typography
            sx={{
              color: '#fff',
              textAlign: 'center',
              alignSelf: 'center',
              width: '100%',
              fontSize: '24px',
              fontWeight: 700,
            }}
          >
            {`Welcome ${
              educator?.data
                ? educator?.data?.profile?.first_name
                  ? educator?.data?.profile?.first_name
                  : educator?.data?.user?.name
                : '#'
            }`}
            <br />
            Check your Performance
          </Typography>
        </Grid>
        <Grid
          item
          xl="6"
          lg="6"
          md="12"
          sm="12"
          xs="12"
          sx={{
            minHeight: '200px',
            backgroundImage: 'url(/teacher-dashbooard.webp)',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          }}
        ></Grid> */}
        <Grid
          item
          xl="12"
          lg="12"
          md="12"
          sm="12"
          xs="12"
          sx={{
            minHeight: '60px',
            background: '#10496a',
            paddingTop: 0,
          }}
          padding={'0rem'}
          className="padding-0"
        >
          <Grid
            container
            justifyContent={'center'}
            className="custom-tab-parent-container"
            sx={{ maxHeight: '60px' }}
          >
            <Grid item xl="10" lg="10" md="11" sm="12" xs="12">
              <Tabs
                value={value}
                onChange={(e, newval) => handleChange(e, newval)}
                textColor="primary"
                indicatorColor="primary"
                aria-label="Account forms"
                className="custom-dashboard-tab"
                sx={{ height: '100%' }}
                scrollButtons={true}
                allowScrollButtonsMobile
              >
                <Tab
                  label="My Earnings"
                  id="simple-tab-0"
                  aria-controls="simple-tabpanel-0"
                  sx={{ textTransform: 'capitalize' }}
                />
                <Tab
                  label="Analytics"
                  id="simple-tab-0"
                  aria-controls="simple-tabpanel-0"
                  sx={{ textTransform: 'capitalize' }}
                />
                <Tab
                  label="Enrollment Overview"
                  id="simple-tab-0"
                  aria-controls="simple-tabpanel-0"
                  sx={{ textTransform: 'capitalize' }}
                />
                {/* <Tab
                  label="Ratings & Reviews"
                  id="simple-tab-1"
                  aria-controls="simple-tabpanel-1"
                /> */}
              </Tabs>
            </Grid>
          </Grid>

          <Grid container justifyContent={'center'} sx={{ background: '#fff' }}>
            <Grid item xl="10" lg="10" md="11" sm="12" xs="12">
              <TabPanel value={value} index={0}>
                <EducatorEarning />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Analytics />
              </TabPanel>
              <TabPanel value={value} index={2}>
                <EducatorEnrollment />
              </TabPanel>
              {/* <TabPanel value={value} index={3}>
                <ReviewandRating />
              </TabPanel> */}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
};
