import { Button, Box, TextField } from '@mui/material';
import { useUpdateCategoryMutation } from '../../../app/services/CategoriesApi';

function UpdateCategory(props) {
  const { category } = props;
  const [updateCategory, result] = useUpdateCategoryMutation();

  const handleSubmit = async (event) => {
    event.preventDefault();

    const data = new FormData(event.currentTarget);
    const categoryData = {
      id: category.id,
      title: data.get('title'),
    };

    await updateCategory(categoryData);
  };
  return result.data ? (
    result.data.message
  ) : (
    <>
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="title"
          label="Category Title"
          name="title"
          autoComplete="title"
          autoFocus
          defaultValue={category.title}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          disabled={result.isLoading || result.isSuccess}
        >
          Update Category
        </Button>
      </Box>
    </>
  );
}

export default UpdateCategory;
