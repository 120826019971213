/* eslint-disable no-unused-vars */
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useUpdateDocumentStatusMutation } from '../../../../app/services/CoursesApi';

function ChangeDocumentStatus(props) {
  const { document } = props;
  const [status, setStatus] = React.useState('');
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = React.useState(false);
  const [localDocument, setLocalDocument] = React.useState(document);
  const [updateDocumentStatus, result] = useUpdateDocumentStatusMutation();

  const handleChangeStatus = (e) => {
    setStatus(e.target.value);
  };

  const onChangeStatus = (event) => {
    event.preventDefault();
    setLoading(true);
    const updateData = {
      documentId: localDocument.id,
      status: status,
    };

    const res = updateDocumentStatus(updateData);
  };

  return result.data ? (
    result.data.message
  ) : (
    <>
      <Box
        noValidate
        component="form"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          m: 'auto',
          width: 'fit-content',
        }}
        onSubmit={onChangeStatus}
      >
        <Typography>Current Status: {localDocument.status}</Typography>
        <FormControl sx={{ mt: 2, minWidth: 120 }}>
          {localDocument.status === 'COMPLETED' ? (
            <>
              <InputLabel htmlFor="max-width">status</InputLabel>

              <Select
                autoFocus
                onChange={handleChangeStatus}
                label="maxWidth"
                inputProps={{
                  name: 'max-width',
                  id: 'max-width',
                }}
              >
                <MenuItem value="BLOCKED">BLOCK</MenuItem>
                <MenuItem value="PUBLISHED">PUBLISH</MenuItem>
              </Select>
            </>
          ) : null}
          {localDocument.status === 'PUBLISHED' ? (
            <>
              <InputLabel htmlFor="max-width">status</InputLabel>
              <Select
                autoFocus
                onChange={handleChangeStatus}
                label="maxWidth"
                inputProps={{
                  name: 'max-width',
                  id: 'max-width',
                }}
              >
                <MenuItem value="BLOCKED">BLOCK</MenuItem>
              </Select>
            </>
          ) : null}

          {localDocument.status === 'BLOCKED' ? (
            <>
              <InputLabel htmlFor="max-width">status</InputLabel>
              <Select
                autoFocus
                onChange={handleChangeStatus}
                label="maxWidth"
                inputProps={{
                  name: 'max-width',
                  id: 'max-width',
                }}
              >
                <MenuItem value="PUBLISHED">PUBLISH</MenuItem>
              </Select>
            </>
          ) : null}
        </FormControl>
        <Button
          type="submit"
          variant="contained"
          sx={{ mt: 1 }}
          disabled={status === localDocument.status || loading || !status}
        >
          Change Status
        </Button>
      </Box>
    </>
  );
}

export default ChangeDocumentStatus;
