import { configureStore } from '@reduxjs/toolkit';
import { authSlice } from './slices/authSlice';
import { categoriesApi } from './services/CategoriesApi';
import { authApi } from './services/AuthApi';

import { publicCoursesApi } from './services/PublicCoursesApi';
import { coursesApi } from './services/CoursesApi';
import { studentCoursesApi } from './services/StudentCoursesApi';
import { examsApi } from './services/ExamsApi';
import { publicExamsApi } from './services/PublicExamsApi';
import { studentExamsApi } from './services/StudentExamsApi';
import { adminUsersApi } from './services/AdminUsersApi';
import { testsApi } from './services/TestsApi';
import { publicTestsApi } from './services/PublicTestsApi';
import { studentTestsApi } from './services/StudentTestsApi';
import { BaseApi } from './services/BaseApi';
import { subscriptionPlansApi } from './services/SunscriptionPlansApi';
import { homePageApi } from './services/HomePageApi';
import { keywordsApi } from './services/KeywordsApi';
import { SearchApi } from './services/SearchApi';
import { studentApi } from './services/StudentApi';
import { learningObjectivesApi } from './services/LearningObjectivesApi';
import { userApi } from './services/UserApi';
import { EducatorApi } from './services/EducatorApi';
import { educatorSubscriptionPlansApi } from './services/EducatorSubscriptionPlansApi';
import { studentSubscriptionPlansApi } from './services/StudentSubscriptionPlansApi';
import { supportTicketsApi } from './services/SupportTicketsApi';

const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [categoriesApi.reducerPath]: categoriesApi.reducer,
    [coursesApi.reducerPath]: coursesApi.reducer,
    [publicCoursesApi.reducerPath]: publicCoursesApi.reducer,
    [studentCoursesApi.reducerPath]: studentCoursesApi.reducer,
    [examsApi.reducerPath]: examsApi.reducer,
    [publicExamsApi.reducerPath]: publicExamsApi.reducer,
    [studentExamsApi.reducerPath]: studentExamsApi.reducer,
    [adminUsersApi.reducerPath]: adminUsersApi.reducer,
    [testsApi.reducerPath]: testsApi.reducer,
    [publicTestsApi.reducerPath]: publicTestsApi.reducer,
    [studentTestsApi.reducerPath]: studentTestsApi.reducer,
    [BaseApi.reducerPath]: BaseApi.reducer,
    [subscriptionPlansApi.reducerPath]: subscriptionPlansApi.reducer,
    [homePageApi.reducerPath]: homePageApi.reducer,
    [keywordsApi.reducerPath]: keywordsApi.reducer,
    [SearchApi.reducerPath]: SearchApi.reducer,
    [studentApi.reducerPath]: studentApi.reducer,
    [learningObjectivesApi.reducerPath]: learningObjectivesApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [EducatorApi.reducerPath]: EducatorApi.reducer,
    [educatorSubscriptionPlansApi.reducerPath]: educatorSubscriptionPlansApi.reducer,
    [studentSubscriptionPlansApi.reducerPath]: studentSubscriptionPlansApi.reducer,
    [supportTicketsApi.reducerPath]: supportTicketsApi.reducer,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      categoriesApi.middleware,
      authApi.middleware,
      coursesApi.middleware,
      publicCoursesApi.middleware,
      studentCoursesApi.middleware,
      examsApi.middleware,
      publicExamsApi.middleware,
      studentExamsApi.middleware,
      adminUsersApi.middleware,
      testsApi.middleware,
      publicTestsApi.middleware,
      studentTestsApi.middleware,
      BaseApi.middleware,
      subscriptionPlansApi.middleware,
      homePageApi.middleware,
      keywordsApi.middleware,
      SearchApi.middleware,
      studentApi.middleware,
      learningObjectivesApi.middleware,
      userApi.middleware,
      EducatorApi.middleware,
      educatorSubscriptionPlansApi.middleware,
      studentSubscriptionPlansApi.middleware,
      supportTicketsApi.middleware
    ),
});

export default store;
