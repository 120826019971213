/* eslint-disable no-unused-vars */
import {
  Button,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';
import { BaseApi } from '../../app/services/BaseApi';
import { signOut } from '../../app/slices/authSlice';
import { Logout } from '@mui/icons-material';

// Sign Out Component
function SignOut(props) {
  const { token } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const handleSignOut = () => {
    // dispatch(BaseApi.util.resetApiState());
    dispatch(signOut(token))
      .unwrap()
      .then(() => dispatch(BaseApi.util.resetApiState()));
    navigate('/');
  };
  const navigate = useNavigate();
  return (
    <>
      {props.showListUi ? (
        <ListItem disablePadding>
          <ListItemButton
            onClick={() => {
              handleSignOut();
              props.handleClosePopper && props.handleClosePopper();
            }}
          >
            <ListItemIcon>
              <Logout />
            </ListItemIcon>
            <ListItemText>Log Out</ListItemText>
          </ListItemButton>
        </ListItem>
      ) : (
        <Button onClick={handleSignOut} variant="" sx={{ my: 1, mx: 1.5 }}>
          Sign Out
        </Button>
      )}
    </>
  );
}

export default SignOut;
