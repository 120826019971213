/* eslint-disable no-unused-vars */
import { Link, Toolbar, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import Box from '@mui/material/Box';
import { usePublishedCategoriesQuery } from '../app/services/CategoriesApi';
import { useSelector } from 'react-redux';

function MenuToolBar() {
  const { data, isLoading, isSuccess } = usePublishedCategoriesQuery();
  const [selectedPublishedCategory, setSelectedPublishedCategoryPublished] =
    React.useState(false);
  const [selectedPublishedSubCategory, setSelectedPublishedSubCategory] =
    useState(false);
  const { isSignedIn, user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const [selectedPublishedSubCategoryGrade, setSelectedPublishedSubCategoryGrade] =
    useState(false);
  const onSubMenuClick = (id) => {
    navigate(`/${selectedPublishedCategory.title}/${id}`);
  };
  const onGradeClick = (id) => {
    navigate(
      `/allgrades/${selectedPublishedCategory.title}/${selectedPublishedSubCategory?.id}/${id}`
    );
  };

  return (
    <>
      {isSuccess && data.categories.length && user?.role !== 'educator' ? (
        <>
          <Box>
            <Toolbar
              component="nav"
              variant="dense"
              sx={{
                // justifyContent: 'flex-start',
                overflowX: 'auto',
                backgroundColor: 'teal',
              }}
              onMouseLeave={() => {
                setSelectedPublishedSubCategory(false);
              }}
            >
              {data.categories.map((singlePublishedCategory) => (
                <>
                  <Link
                    component="button"
                    variant="body2"
                    onClick={() => {
                      setSelectedPublishedCategoryPublished(singlePublishedCategory);
                    }}
                    color="inherit"
                    noWrap
                    key={singlePublishedCategory.id}
                    underline="none"
                    sx={{ p: 1, flexShrink: 0 }}
                  >
                    {singlePublishedCategory.title.charAt(0).toUpperCase() +
                      singlePublishedCategory.title.slice(1)}
                  </Link>
                </>
              ))}
            </Toolbar>
            {selectedPublishedCategory &&
            selectedPublishedCategory.published_sub_categories.length ? (
              <Toolbar
                component="nav"
                variant="dense"
                sx={{
                  justifyContent: 'flex-start',
                  overflowX: 'auto',
                  backgroundColor: '#ffffff',
                  color: '#000000',
                }}
              >
                {selectedPublishedCategory.published_sub_categories.map(
                  (singlePublishedCategorySubCategory) => (
                    <Link
                      component="button"
                      variant="body2"
                      color="inherit"
                      noWrap
                      key={singlePublishedCategorySubCategory.id.toString()}
                      underline="none"
                      sx={{ p: 1, flexShrink: 0 }}
                      onClick={() =>
                        onSubMenuClick(singlePublishedCategorySubCategory.id)
                      }
                      onMouseEnter={() => {
                        setSelectedPublishedSubCategory(
                          singlePublishedCategorySubCategory
                        );
                      }}
                    >
                      {singlePublishedCategorySubCategory.title
                        .charAt(0)
                        .toUpperCase() +
                        singlePublishedCategorySubCategory.title.slice(1)}
                    </Link>
                  )
                )}
              </Toolbar>
            ) : null}
            {selectedPublishedSubCategory &&
            Array.isArray(selectedPublishedSubCategory.grades) &&
            selectedPublishedSubCategory.grades.length ? (
              <Toolbar
                component="nav"
                variant="dense"
                sx={{
                  justifyContent: 'flex-start',
                  overflowX: 'auto',
                  backgroundColor: '#59d2eb',
                  color: '#000000',
                }}
                onMouseLeave={() => {
                  setSelectedPublishedSubCategory(false);
                }}
              >
                {Array.isArray(selectedPublishedSubCategory.grades) &&
                  selectedPublishedSubCategory.grades.length > 0 &&
                  selectedPublishedSubCategory.grades.map((singlePublishedgrade) => (
                    <Link
                      component="button"
                      variant="body2"
                      color="inherit"
                      noWrap
                      key={singlePublishedgrade.id.toString()}
                      underline="none"
                      sx={{
                        p: 1,
                        flexShrink: 0,
                        color:
                          selectedPublishedSubCategoryGrade ===
                          singlePublishedgrade?.title
                            ? 'red'
                            : '',
                        fontWeight:
                          selectedPublishedSubCategoryGrade ===
                          singlePublishedgrade?.title
                            ? 700
                            : 500,
                      }}
                      onMouseEnter={() => {
                        setSelectedPublishedSubCategoryGrade(
                          singlePublishedgrade.title
                        );
                      }}
                      onMouseLeave={() => {
                        setSelectedPublishedSubCategoryGrade(false);
                      }}
                      onClick={() => onGradeClick(singlePublishedgrade.id)}
                    >
                      {singlePublishedgrade.title.charAt(0).toUpperCase() +
                        singlePublishedgrade.title.slice(1)}
                    </Link>
                  ))}
              </Toolbar>
            ) : null}
          </Box>
        </>
      ) : null}
    </>
  );
}

export default MenuToolBar;
