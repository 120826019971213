/* eslint-disable no-unused-vars */
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
} from '@mui/material';
import React from 'react';
import { useCoursesQuery } from '../../../app/services/CoursesApi';
import DataLoader from '../../../components/DataLoader';
import CoursesList from './CoursesList';
import CreateNewCourse from './CreateNewCourse';
import { useNavigate } from 'react-router-dom';

function CoursesManager() {
  const [open, setOpen] = React.useState(false);

  const { data, isLoading, isSuccess } = useCoursesQuery();
  const navigate = useNavigate();
  if (isLoading) {
    return <DataLoader thickness={5} size={50} />;
  }

  const handleClickOpen = () => {
    // setOpen(true);
    navigate('/add-course');
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Grid container spacing={1} justifyContent={'center'}>
        <Grid item xs="12" sm="12" md="12" lg="12" xl="12">
          {/* <div style={{ textAlign: 'end' }}>
            <Dialog open={open} onClose={handleClose} maxWidth="md">
              <DialogTitle> Create New Course</DialogTitle>
              <DialogContent>
                <CreateNewCourse />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Close</Button>
              </DialogActions>
            </Dialog>
          </div> */}
        </Grid>
        <Grid item xs="12" sm="12" md="12" lg="12" xl="12">
          {isSuccess ? (
            <CoursesList courses={data?.courses} />
          ) : (
            <p>No courses found</p>
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default CoursesManager;
