/* eslint-disable no-unused-vars */
import React, { Fragment, useState } from 'react';
import {
  Box,
  Button,
  Grid,
  Paper,
  Radio,
  Slider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { BarChart } from '@mui/x-charts';
import { PieChart } from '@mui/x-charts/PieChart';
import GaugeIndicator from './GaugeIndicator';
import { Gauge } from '@mui/x-charts/Gauge';

export const MyPlan = () => {
  function createData(srNo, date, type, orderNumber, plan, amount, status) {
    return { srNo, date, type, orderNumber, plan, amount, status };
  }
  return (
    <Fragment>
      <Grid container spacing={2} sx={{ marginTop: '0rem' }}>
        <Grid item xl={12} lg={12} md={12} sm={12} justifyContent={'center'}>
          <Box
            sx={{
              background: '#fff',
              padding: '1rem',
              boxShadow: '1px 1px 10px 1px #dcdcdc',
            }}
          >
            <Grid container spacing={2}>
              <Grid item xl={3} lg={3} md={6} sm={6}>
                <Typography
                  sx={{ color: '#10496a', fontSize: '20px', fontWeight: 700 }}
                >
                  My Current Plan
                </Typography>
                <Box
                  sx={{
                    margin: '1rem',
                    padding: '1rem',
                    boxShadow: '1px 1px 10px 1px #dadada',
                  }}
                >
                  <Typography
                    sx={{ color: '#2bb18c', fontWeight: 400, textAlign: 'center' }}
                  >
                    Basic Plan
                  </Typography>
                  <Typography
                    sx={{
                      color: '#2bb18c',
                      fontWeight: 700,
                      textAlign: 'center',
                      fontSize: '28px',
                    }}
                  >
                    RS. 14,999
                  </Typography>
                  <Typography
                    sx={{
                      color: '#2bb18c',
                      fontWeight: 400,
                      textAlign: 'center',
                      fontSize: '16px',
                    }}
                  >
                    / Teacher / Month
                  </Typography>
                </Box>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: 'center',
                    paddingBottom: '2rem',
                  }}
                >
                  <Typography
                    sx={{
                      color: '#20b0c5',
                      fontWeight: 400,
                      textAlign: 'center',
                      width: '260px',
                    }}
                  >
                    Explore GM&lsquo;s platform&lsquo;s capabilities Test key
                    features before upgrading for revenue generation.
                  </Typography>

                  <Button
                    sx={{
                      background: '#2bb18c',
                      color: '#fff',
                      borderRadius: '20px',
                      fontSize: '18px',
                      fontWeight: 700,
                      mt: '2rem',
                      '&:hover': {
                        backgroundColor: '#2bb18c',
                      },
                    }}
                  >
                    Upgrade Plan
                  </Button>
                </div>
              </Grid>
              <Grid item xl={6} lg={6} md={12} sm={12}>
                <Typography
                  sx={{
                    color: '#10496a',
                    fontSize: '20px',
                    fontWeight: 700,
                    textAlign: 'center',
                  }}
                >
                  Included in Your Plan
                </Typography>
                <Box
                  sx={{
                    margin: '1rem',
                    padding: '1rem',
                    boxShadow: '1px 1px 10px 1px #dadada',
                    borderRadius: '2px',
                    display: 'flex',
                    gap: '1rem',
                    alignItems: 'center',
                    minHeight: 'calc(100% - 30%)',
                  }}
                >
                  <Box sx={{ width: '49%' }}>
                    {[
                      '45 Days Payout',
                      'Basic income Scheme',
                      'Space Bandwidth ?',
                      'Email Support',
                      'Promotion',
                      'unlimited enrolments',
                      'Basic Insight’s',
                    ].map((item, ind) => (
                      <Typography sx={{ color: '#20b0c5' }} key={'ind' + ind}>
                        {item}
                      </Typography>
                    ))}
                  </Box>
                  <div
                    style={{
                      borderLeft: '4px solid #eaeaea',
                      height: '200px',
                      width: '2px',
                    }}
                  ></div>
                  <Box sx={{ width: '49%' }}>
                    {[
                      '45 Days Payout',
                      'Basic income Scheme',
                      'Space Bandwidth ?',
                      'Email Support',
                      'Promotion',
                      'unlimited enrolments',
                      'Basic Insight’s',
                    ].map((item, ind) => (
                      <Typography sx={{ color: '#20b0c5' }} key={'ind' + ind}>
                        {item}
                      </Typography>
                    ))}
                  </Box>
                </Box>
              </Grid>
              <Grid item xl={3} lg={3} md={12} sm={12}>
                <Typography
                  sx={{
                    color: '#10496a',
                    fontSize: '20px',
                    fontWeight: 700,
                    // textAlign: 'center',
                  }}
                >
                  Billing Method
                </Typography>
                <Box
                  sx={{
                    margin: '1rem',
                    padding: '1rem',
                    boxShadow: '1px 1px 10px 1px #dadada',
                    borderRadius: '2px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '1rem',
                    alignItems: 'center',
                    minHeight: 'calc(100% - 30%)',
                    justifyContent: 'space-between',
                  }}
                >
                  <Box sx={{ display: 'flex', gap: '10px' }}>
                    <img
                      style={{ height: '30px', width: '30px' }}
                      src="https://w7.pngwing.com/pngs/667/120/png-transparent-google-pay-2020-hd-logo-thumbnail.png"
                    />
                    <Box>
                      <Typography sx={{ fontWeight: 600, color: '#10496a' }}>
                        Google Pay
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: 600,
                          color: '#10496a',
                          lineHeight: '18px',
                          marginTop: '0.5rem',
                        }}
                      >
                        Pay ₹ 2,029.00/mo Next payment on May 29, 2024 Annual plan,
                        paid monthly
                      </Typography>
                    </Box>
                  </Box>
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Button
                      sx={{
                        background: '#2bb18c',
                        color: '#fff',
                        borderRadius: '20px',
                        fontSize: '12px',
                        fontWeight: 600,
                        mt: '2rem',
                        '&:hover': {
                          backgroundColor: '#2bb18c',
                        },
                      }}
                    >
                      Edit Billing and Payment
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xl={8} lg={8} md={12} sm={12}>
          <Box
            sx={{
              background: '#fff',
              padding: '1rem',
              boxShadow: '1px 1px 10px 1px #dcdcdc',
            }}
          >
            <Typography sx={{ fontWeight: 600 }}>
              Invoice and billing history
            </Typography>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: '100%' }} aria-label="simple table">
                <TableHead sx={{ background: '#eef7fa' }}>
                  <TableRow>
                    <TableCell
                      sx={{
                        color: '#083faf',
                        fontSize: '16px',
                        fontWeight: 700,
                      }}
                    >
                      #
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        color: '#083faf',
                        fontSize: '16px',
                        fontWeight: 700,
                      }}
                    >
                      Date
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        color: '#083faf',
                        fontSize: '16px',
                        fontWeight: 700,
                      }}
                    >
                      Type
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        color: '#083faf',
                        fontSize: '16px',
                        fontWeight: 700,
                      }}
                    >
                      Order Number
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        color: '#083faf',
                        fontSize: '16px',
                        fontWeight: 700,
                      }}
                    >
                      Plan
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        color: '#083faf',
                        fontSize: '16px',
                        fontWeight: 700,
                      }}
                    >
                      Amount
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        color: '#083faf',
                        fontSize: '16px',
                        fontWeight: 700,
                      }}
                    >
                      Status
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className="analytics-custom-mui-table-body">
                  {[
                    createData(
                      1,
                      '29-May-2024',
                      'Invoice',
                      'BS1000234',
                      'Basic Plan',
                      '14999',
                      'Pending'
                    ),
                    createData(
                      2,
                      '29-May-2024',
                      'Invoice',
                      'BS1000234',
                      'Basic Plan',
                      '14999',
                      'Pending'
                    ),
                    createData(
                      3,
                      '29-May-2024',
                      'Invoice',
                      'BS1000234',
                      'Basic Plan',
                      '14999',
                      'Pending'
                    ),
                    createData(
                      4,
                      '29-May-2024',
                      'Invoice',
                      'BS1000234',
                      'Basic Plan',
                      '14999',
                      'Pending'
                    ),
                    createData(
                      5,
                      '29-May-2024',
                      'Invoice',
                      'BS1000234',
                      'Basic Plan',
                      '14999',
                      'Pending'
                    ),
                    createData(
                      6,
                      '29-May-2024',
                      'Invoice',
                      'BS1000234',
                      'Basic Plan',
                      '14999',
                      'Pending'
                    ),
                  ].map((row) => (
                    <TableRow
                      key={row?.name}
                      className="analytics-custom-mui-table-row"
                    >
                      <TableCell>
                        <div>
                          {row?.srNo}{' '}
                          <Radio
                            value="a"
                            name="radio-buttons"
                            inputProps={{ 'aria-label': 'A' }}
                            sx={{ padding: '0rem' }}
                          />
                        </div>
                      </TableCell>
                      <TableCell align="center">
                        <div>{row?.date}</div>
                      </TableCell>
                      <TableCell align="center">
                        <div>{row?.type}</div>
                      </TableCell>
                      <TableCell align="center">
                        <div>{row?.orderNumber}</div>
                      </TableCell>
                      <TableCell align="center">
                        <div>{row?.plan}</div>
                      </TableCell>
                      <TableCell align="center">
                        <div>{row?.amount}</div>
                      </TableCell>
                      <TableCell align="center">
                        <div>{row?.status}</div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Grid>
        <Grid item xl={4} lg={4} md={12} sm={12}>
          <Box
            sx={{
              background: '#fff',
              padding: '1rem',
              height: '150px',
              alignSelf: 'center',
              display: 'flex',
              alignContent: 'center',
              justifyContent: 'center',
              alignItems: 'center',
              boxShadow: '1px 1px 10px 1px #dcdcdc',
            }}
          >
            <Typography
              sx={{ fontWeight: 600, textAlign: 'center', fontSize: '22px' }}
            >
              Add Area
            </Typography>
          </Box>
          <Box
            sx={{
              background: '#fff',
              padding: '1rem',
              height: '150px',
              alignSelf: 'center',
              display: 'flex',
              alignContent: 'center',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '1rem',
              boxShadow: '1px 1px 10px 1px #dcdcdc',
            }}
          >
            <Typography
              sx={{ fontWeight: 600, textAlign: 'center', fontSize: '22px' }}
            >
              Add Area
            </Typography>
          </Box>
          <Box
            sx={{
              background: '#fff',
              padding: '1rem',
              height: '150px',
              alignSelf: 'center',
              display: 'flex',
              alignContent: 'center',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '1rem',
              boxShadow: '1px 1px 10px 1px #dcdcdc',
            }}
          >
            <Typography
              sx={{ fontWeight: 600, textAlign: 'center', fontSize: '22px' }}
            >
              Add Area
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Fragment>
  );
};
