/* eslint-disable no-unused-vars */
import React, { Fragment, useState } from 'react';
import {
  Box,
  Grid,
  Paper,
  Slider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { BarChart } from '@mui/x-charts';
import { PieChart } from '@mui/x-charts/PieChart';
import GaugeIndicator from './GaugeIndicator';
import { Gauge } from '@mui/x-charts/Gauge';
import { CustomDateRange } from './CustomDateRange';
import { useEducatorCoursesAndEnrolsQuery } from '../../../app/services/EducatorApi';
import moment from 'moment';

export const EducatorEnrollment = () => {
  const [selectedPlan, setSelectedPlan] = useState('free');
  const [selectedCourseID, setSelectedCourseID] = useState(null);
  const { data, isLoading, isSuccess } = useEducatorCoursesAndEnrolsQuery();

  const valueFormatter = (value) => `${value}`;
  function createSmallData(srNo, courseId, type) {
    return { srNo, courseId, type, enroll: type };
  }
  function createData(
    CourseID,
    Type,
    Duration,
    StudentID,
    Enroll,
    Date,
    Status,
    Price,
    PayMethod,
    TransactID,
    CompletionRate,
    Ratings
  ) {
    return {
      CourseID,
      Type,
      Duration,
      StudentID,
      Enroll,
      Date,
      Status,
      Price,
      PayMethod,
      TransactID,
      CompletionRate,
      Ratings,
    };
  }
  const dataset = [
    {
      london: 59,
      paris: 57,
      newYork: 86,
      seoul: 21,
      month: 'Jan',
    },
    {
      london: 50,
      paris: 52,
      newYork: 78,
      seoul: 28,
      month: 'Fev',
    },
    {
      london: 47,
      paris: 53,
      newYork: 106,
      seoul: 41,
      month: 'Mar',
    },
    {
      london: 54,
      paris: 56,
      newYork: 92,
      seoul: 73,
      month: 'Apr',
    },
    {
      london: 57,
      paris: 69,
      newYork: 92,
      seoul: 99,
      month: 'May',
    },
    {
      london: 60,
      paris: 63,
      newYork: 103,
      seoul: 144,
      month: 'June',
    },
    {
      london: 59,
      paris: 60,
      newYork: 105,
      seoul: 319,
      month: 'July',
    },
    {
      london: 65,
      paris: 60,
      newYork: 106,
      seoul: 249,
      month: 'Aug',
    },
    {
      london: 51,
      paris: 51,
      newYork: 95,
      seoul: 131,
      month: 'Sept',
    },
    {
      london: 60,
      paris: 65,
      newYork: 97,
      seoul: 55,
      month: 'Oct',
    },
    {
      london: 67,
      paris: 64,
      newYork: 76,
      seoul: 48,
      month: 'Nov',
    },
    {
      london: 61,
      paris: 70,
      newYork: 103,
      seoul: 25,
      month: 'Dec',
    },
  ];
  const chartSetting = {
    yAxis: [
      {
        label: '',
      },
    ],
    // width: 500,
    height: 350,
  };

  const findVideoProgress = (course, videoProgress) => {
    const totalCourseDuration =
      Number(course.published_video_sum_duration) +
      Number(course.published_subject_video_sum_duration) +
      Number(course.published_topic_video_sum_duration) +
      Number(course.published_sub_topic_video_sum_duration);

    const totalVideoProgressDuration =
      videoProgress && videoProgress.reduce((n, { timeStamp }) => n + timeStamp, 0);

    return Math.round((totalVideoProgressDuration * 100) / totalCourseDuration);
  };

  return (
    <Fragment>
      <div style={{ position: 'relative' }}>
        <div
          style={{
            position: 'absolute',
            bottom: '0.7rem',
            right: '0rem',
            display: 'inline-block',
          }}
        >
          <CustomDateRange />
        </div>
      </div>
      <Grid container spacing={2} sx={{ background: '', marginTop: '0rem' }}>
        <Grid item xl={12} lg={12} md={12} sm={12} justifyContent={'center'}>
          <Box
            sx={{
              background: '#fff',
              padding: '1rem',
              boxShadow: '1px 1px 10px 1px #dcdcdc',
            }}
          >
            <Grid container sx={{ background: '#eef7fa' }}>
              <Grid item xl={12} lg={12} md={12} sm={12}>
                <div
                  style={{
                    display: 'flex',
                    gap: '1rem',
                    flexWrap: 'wrap',
                    // padding: '1rem',
                    background: '#fff',
                  }}
                >
                  <div
                    style={{
                      height: '100px',
                      width: '200px',
                      background: selectedPlan == 'free' ? '#eef7fa' : '#10496a',
                      display: 'grid',
                      alignItems: 'center',
                    }}
                    onClick={() => {
                      setSelectedPlan('free');
                    }}
                  >
                    <div>
                      <Typography
                        sx={{
                          color: selectedPlan == 'free' ? '#124b6b' : '#fff',
                          textAlign: 'center',
                          fontWeight: 600,
                        }}
                      >
                        Free Plan
                      </Typography>
                      <Typography
                        sx={{
                          color: selectedPlan == 'free' ? '#124b6b' : '#fff',
                          textAlign: 'center',
                          fontWeight: 600,
                        }}
                      >
                        Enrolls 12000
                      </Typography>
                    </div>
                  </div>
                  <div
                    style={{
                      height: '100px',
                      width: '200px',
                      background:
                        selectedPlan == 'subscription' ? '#eef7fa' : '#10496a',
                      display: 'grid',
                      alignItems: 'center',
                    }}
                    onClick={() => {
                      setSelectedPlan('subscription');
                    }}
                  >
                    <div>
                      <Typography
                        sx={{
                          color: selectedPlan == 'subscription' ? '#124b6b' : '#fff',
                          textAlign: 'center',
                          fontWeight: 600,
                        }}
                      >
                        Subscription Plan
                      </Typography>
                      <Typography
                        sx={{
                          color: selectedPlan == 'subscription' ? '#124b6b' : '#fff',
                          textAlign: 'center',
                          fontWeight: 600,
                        }}
                      >
                        Enrolls 12000
                      </Typography>
                    </div>
                  </div>
                  <div
                    style={{
                      height: '100px',
                      width: '200px',
                      background: selectedPlan == 'premium' ? '#eef7fa' : '#10496a',
                      display: 'grid',
                      alignItems: 'center',
                    }}
                    onClick={() => {
                      setSelectedPlan('premium');
                    }}
                  >
                    <div>
                      <Typography
                        sx={{
                          color: selectedPlan == 'premium' ? '#124b6b' : '#fff',
                          textAlign: 'center',
                          fontWeight: 600,
                        }}
                      >
                        Premium Plan
                      </Typography>
                      <Typography
                        sx={{
                          color: selectedPlan == 'premium' ? '#124b6b' : '#fff',
                          textAlign: 'center',
                          fontWeight: 600,
                        }}
                      >
                        Enrolls 12000
                      </Typography>
                    </div>
                  </div>
                </div>
                <BarChart
                  dataset={dataset}
                  xAxis={[{ scaleType: 'band', dataKey: 'month' }]}
                  series={[{ dataKey: 'london', valueFormatter }]}
                  {...chartSetting}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xl={4} lg={4} md={12} sm={12}>
          <Box
            sx={{
              background: '#fff',
              padding: '1rem',
              boxShadow: '1px 1px 10px 1px #dcdcdc',
            }}
          >
            <TableContainer component={Paper} sx={{ maxHeight: '470px' }}>
              <Table
                sx={{ minWidth: '100%' }}
                aria-label="simple table"
                stickyHeader={true}
              >
                <TableHead sx={{ background: '#10496a' }}>
                  <TableRow>
                    <TableCell
                      sx={{
                        color: '#fff',
                        fontSize: '16px',
                        fontWeight: 700,
                        background: '#10496a',
                        width: '50px',
                      }}
                    >
                      #
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        color: '#fff',
                        fontSize: '16px',
                        fontWeight: 700,
                        width: '100px',
                        background: '#10496a',
                      }}
                    >
                      Course ID
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        color: '#fff',
                        fontSize: '16px',
                        fontWeight: 700,
                        width: '150px',
                        background: '#10496a',
                      }}
                    >
                      No OF Enrollments
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className="analytics-custom-mui-table-body">
                  {data &&
                    data.courses?.map((row, index) => (
                      <TableRow
                        key={row?.id}
                        className="analytics-custom-mui-table-row"
                      >
                        <TableCell>
                          <div>{index + 1}</div>
                        </TableCell>
                        <TableCell
                          align="center"
                          onClick={() => {
                            setSelectedCourseID(row);
                          }}
                          sx={{ cursor: 'pointer' }}
                        >
                          <div>{row?.id}</div>
                        </TableCell>
                        <TableCell align="center">
                          <div>{row?.course_enrols_count}</div>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Grid>
        <Grid item xl={8} lg={8} md={12} sm={12}>
          {selectedCourseID && (
            <Box
              sx={{
                background: '#fff',
                padding: '1rem',
                boxShadow: '1px 1px 10px 1px #dcdcdc',
              }}
            >
              <TableContainer component={Paper} sx={{ maxHeight: '470px' }}>
                <Table
                  sx={{ minWidth: '100%' }}
                  aria-label="simple table"
                  stickyHeader={true}
                >
                  <TableHead sx={{ background: '#10496a' }}>
                    <TableRow>
                      {[
                        'Course ID',
                        'Type',
                        'Total Duration',
                        'Student Name',
                        'Enroll Date',
                        'Status',
                        'Price',
                        'Pay Method',
                        'Transact ID',
                        'Completion Rate',
                        'Ratings',
                      ].map((item, ind) => (
                        <TableCell
                          align="center"
                          sx={{
                            color: '#fff',
                            fontSize: '16px',
                            fontWeight: 700,
                            minWidth: `${item.length}0px`,
                            background: '#10496a',
                            //   padding: '0.3rem',
                          }}
                          key={'ind' + ind}
                        >
                          {item}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody className="analytics-custom-mui-table-body">
                    {selectedCourseID.course_enrols?.map((row) => (
                      <TableRow
                        key={row?.id}
                        className="analytics-custom-mui-table-row"
                      >
                        <TableCell sx={{ padding: '0.2rem' }}>
                          <div>{row?.course_id}</div>
                        </TableCell>
                        <TableCell align="center" sx={{ padding: '0.2rem' }}>
                          <div>{selectedCourseID?.cost ? 'Paid' : 'Free'}</div>
                        </TableCell>
                        <TableCell align="center" sx={{ padding: '0.2rem' }}>
                          {/* <div>{row?.Duration}</div> */}
                          <div>
                            {moment
                              .utc(
                                (Number(
                                  selectedCourseID.published_video_sum_duration
                                ) +
                                  Number(
                                    selectedCourseID.published_subject_video_sum_duration
                                  ) +
                                  Number(
                                    selectedCourseID.published_topic_video_sum_duration
                                  ) +
                                  Number(
                                    selectedCourseID.published_sub_topic_video_sum_duration
                                  )) *
                                  1000
                              )
                              .format('HH:mm:ss')}
                          </div>
                        </TableCell>
                        <TableCell align="center" sx={{ padding: '0.2rem' }}>
                          <div>
                            {row?.user?.student_info?.first_name &&
                            row?.user?.student_info?.last_name
                              ? `${row?.user?.student_info.first_name} ${row?.user?.student_info.last_name}`
                              : row?.user.name}
                          </div>
                        </TableCell>

                        <TableCell align="center" sx={{ padding: '0.2rem' }}>
                          <div>{moment(row?.created_at).format('Do MMM YY ')}</div>
                        </TableCell>
                        <TableCell align="center" sx={{ padding: '0.2rem' }}>
                          <div>{row?.payment?.status}</div>
                        </TableCell>
                        <TableCell align="center" sx={{ padding: '0.2rem' }}>
                          <div>{row?.payment?.paid_amount}</div>
                        </TableCell>
                        <TableCell align="center" sx={{ padding: '0.2rem' }}>
                          <div>{row?.PayMethod}</div>
                        </TableCell>
                        <TableCell align="center" sx={{ padding: '0.2rem' }}>
                          <div>{row?.payment?.transaction_id}</div>
                        </TableCell>
                        <TableCell align="center" sx={{ padding: '0.2rem' }}>
                          <div>
                            {' '}
                            {findVideoProgress(
                              selectedCourseID,
                              row.video_progress
                            ) || 0}
                            %
                          </div>
                        </TableCell>
                        <TableCell align="center" sx={{ padding: '0.2rem' }}>
                          <div>
                            {row?.user_review?.rating
                              ? parseFloat(row?.user_review?.rating).toFixed(1)
                              : '-'}
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          )}
        </Grid>
      </Grid>
    </Fragment>
  );
};
