import { Button, Box, TextField } from '@mui/material';
import { useUpdateSubCategoryMutation } from '../../../app/services/CategoriesApi';

function UpdateSubCategory(props) {
  const { subCategory } = props;
  const [updateSubCategory, result] = useUpdateSubCategoryMutation();

  const handleSubmit = async (event) => {
    event.preventDefault();

    const data = new FormData(event.currentTarget);
    const subCategoryData = {
      id: subCategory.id,
      title: data.get('title'),
    };

    await updateSubCategory(subCategoryData);
  };
  return result.data ? (
    result.data.message
  ) : (
    <>
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="title"
          label="Sub Category Title"
          name="title"
          autoComplete="title"
          autoFocus
          defaultValue={subCategory.title}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          disabled={result.isLoading || result.isSuccess}
        >
          Update Sub Category
        </Button>
      </Box>
    </>
  );
}

export default UpdateSubCategory;
