/* eslint-disable no-unused-vars */
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  LinearProgress,
  Typography,
} from '@mui/material';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import DisplayDocument from './DisplayDocument';
import AttachFileIcon from '@mui/icons-material/AttachFile';

// eslint-disable-next-line no-undef
const API_URL = `${process.env.REACT_APP_BACKEND_API_PATH}/api`;

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

function DisplayAndUploadDocument(props) {
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);

  const [progress, setProgress] = useState(0);
  const { sind, tind, topic, subject, handleSelectDocument } = props;
  const docRef = useRef();
  const { token } = useSelector((state) => state.auth);
  const [documentStatus, setDocumentStatus] = useState(null);
  const [processProgress, setProcessProgress] = useState(0);
  const [document, setDocument] = useState(null);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [documentUrl, setDocumentUrl] = useState(null);

  useEffect(() => {
    if (selectedDocument) {
      setDocumentUrl(URL.createObjectURL(selectedDocument));
    }
    docRef.current?.load();
  }, [selectedDocument]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const fileUploadRequest = (uploadData) => {
    return axios.post(
      `${API_URL}/course/subject/topic/document/upload/${uploadData.topicId}`,
      {
        file: uploadData.file,
        file_type: 'document',
        title: uploadData.title,
        description: uploadData.description,
      },
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${uploadData.token}`,
        },

        onUploadProgress: (progressEvent) => {
          var percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );

          setProgress(percentCompleted);
        },
      }
    );
  };

  const uploadDocument = (sind, tind, subjectId, topicId) => {
    if (subject && topic) {
      const documentUploadData = {
        file: topic.documentFile,
        token: token,
        title: topic.title,
        description: topic.title,
        topicId: topicId,
      };

      setDocumentStatus('PROCESSING');

      fileUploadRequest(documentUploadData)
        .then((res) => {
          if (res.data.document.id) {
            setDocument(res.data.document);
            setDocumentStatus('SUCCESS');
            setProcessProgress(100);

            enqueueSnackbar('Document Uploaded Successfully.', {
              variant: 'success',
            });
          } else {
            setDocumentStatus('ERROR');
            enqueueSnackbar('Error uploading Document. ', {
              variant: 'error',
            });
          }
        })
        .catch((err) => {
          console.log(err);
          enqueueSnackbar(`Error uploading Document. ${topic.title}`, {
            variant: 'error',
          });
        });
    }
  };

  return (
    <>
      <Grid item xl="10" lg="10" md="12" sm="12" xs="12">
        <form
          className="form"
          style={{
            marginTop: '1rem',
          }}
        >
          <div
            className="file-upload-wrapper"
            data-text={
              topic.documentFile ? topic.documentFile.name : 'No File Selected'
            }
            data-button-text={
              topic.documentFile ? 'Change document' : 'Select document'
            }
          >
            <input
              name="file-upload-field"
              type="file"
              accept=".pdf"
              className="file-upload-field"
              value=""
              onChange={(e) => {
                setSelectedDocument(e.target.files[0]);
                handleSelectDocument(sind, tind, e.target.files[0]);
              }}
              disabled={progress > 0}
            />
          </div>
        </form>
        {progress > 0 ? (
          <Box sx={{ width: '100%', mt: '4px' }}>
            <LinearProgressWithLabel value={progress} color="secondary" />
          </Box>
        ) : null}

        {documentStatus === 'PROCESSING' ? (
          <Alert severity="info">Your document is being uploaded. Please Wait</Alert>
        ) : null}
        {documentStatus === 'ERROR' ? (
          <Alert severity="error">Error Uploading Document</Alert>
        ) : null}
        {processProgress === 100 ? (
          <Alert severity="success">Your document Uploaded Successfully</Alert>
        ) : null}
      </Grid>
      <Grid item xl="2" lg="2" md="12" sm="12" xs="12">
        {topic.documentFile ? (
          <>
            <IconButton
              aria-label="fingerprint"
              color="secondary"
              onClick={handleClickOpen}
            >
              <AttachFileIcon /> View File
            </IconButton>
            <Dialog open={open} onClose={handleClose} maxWidth="md">
              <DialogContent>
                <DisplayDocument doc={topic.documentFile} />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Close</Button>
              </DialogActions>
            </Dialog>

            <Button
              variant="contained"
              onClick={() => uploadDocument(sind, tind, subject.id, topic.id)}
              disabled={progress > 0}
            >
              Upload File
            </Button>
          </>
        ) : null}
      </Grid>
    </>
  );
}

export default DisplayAndUploadDocument;
