/* eslint-disable no-unused-vars */
import {
  Button,
  Box,
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Grid,
} from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { useUpdateStudentProfileMutation } from '../../../app/services/StudentApi';
import MomentUtils from '@date-io/moment';
import indianStatesDistricts from '../../../indian-states-districts.json';

function UpdateStudentProfile(props) {
  const { studentProfile } = props;

  const { enqueueSnackbar } = useSnackbar();
  const [updatestudentProfile, result] = useUpdateStudentProfileMutation();
  const [dob, setDob] = useState(
    studentProfile.date_of_birth ? moment(studentProfile.date_of_birth) : moment()
  );
  const [stateName, setStateName] = useState('');
  const [selectedState, setSelectedState] = useState({
    state: '',
    districts: [],
  });

  const [districtName, setDistrictName] = useState('');
  const [selectedDistrict, setSelectedDistrict] = useState('');
  const setStateAndDistrict = () => {
    const tempSelectedState = indianStatesDistricts.states.find(
      (singleState) => singleState.state === studentProfile.state
    );
    setSelectedState(tempSelectedState);
    setStateName(studentProfile.state);
    const tempSelectedDistrict = tempSelectedState.districts.find(
      (singleDistrict) => singleDistrict === studentProfile.district
    );

    setSelectedDistrict(tempSelectedDistrict);
    setDistrictName(studentProfile.district);
  };

  useEffect(() => {
    if (studentProfile.state) {
      setStateAndDistrict();
    }
  }, [studentProfile.state]);

  const [gender, setGender] = useState(
    studentProfile.gender ? studentProfile.gender : ''
  );
  const handleDobChange = (newValue) => {
    setDob(newValue);
  };
  const validateData = (studentProfileData) => {
    let err = true;

    if (!studentProfileData.firstName) {
      enqueueSnackbar('Please provide all the required fields', {
        variant: 'error',
      });
    } else if (!/^[a-zA-Z]+(\s[a-zA-Z]+)*$/.test(studentProfileData.firstName)) {
      enqueueSnackbar('Only alphabets are allowed in First Name', {
        variant: 'error',
      });
    } else if (
      studentProfileData.lastName &&
      !/^[a-zA-Z]+(\s[a-zA-Z]+)*$/.test(studentProfileData.lastName)
    ) {
      enqueueSnackbar('Only alphabets are allowed in Last Name', {
        variant: 'error',
      });
    } else if (
      studentProfileData.city &&
      !/^[a-zA-Z]+(\s[a-zA-Z]+)*$/.test(studentProfileData.city)
    ) {
      enqueueSnackbar('Only alphabets are allowed in City', {
        variant: 'error',
      });
    } else {
      err = false;
    }

    return err;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const studentProfileData = {
      id: studentProfile.id,
      firstName: data.get('firstName'),
      lastName: data.get('lastName'),
      gender: data.get('gender'),
      school: data.get('school'),
      state: stateName,
      district: districtName,
      city: data.get('city'),
      address: data.get('address'),
      dateOfBirth: dob.format(),
    };

    const res = validateData(studentProfileData);
    if (!res) {
      updatestudentProfile(studentProfileData);
    }
  };

  const handleStateChange = (event) => {
    const tempSelectedState = indianStatesDistricts.states.find(
      (singleState) => singleState.state === event.target.value
    );
    setSelectedState(tempSelectedState);
    setStateName(event.target.value);
  };

  const handleDistrictChange = (event) => {
    const tempSelectedDistrict = selectedState.districts.find(
      (singleDistrict) => singleDistrict === event.target.value
    );

    setSelectedDistrict(tempSelectedDistrict);
    setDistrictName(event.target.value);
  };

  return result.data ? (
    result.data.message
  ) : (
    <>
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="firstName"
          label=" First Name"
          name="firstName"
          autoComplete="firstName"
          defaultValue={studentProfile.first_name}
          autoFocus
        />
        <TextField
          margin="normal"
          fullWidth
          id="lastName"
          label=" Last Name"
          name="lastName"
          autoComplete="lastName"
          defaultValue={studentProfile.last_name}
          autoFocus
        />
        <Grid container spacing={2}>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <Box sx={{ marginTop: 'inherit' }}>
              <LocalizationProvider dateAdapter={MomentUtils}>
                <DatePicker
                  label="Date Of Birth"
                  value={dob}
                  onChange={handleDobChange}
                  renderInput={(params) => <TextField fullWidth {...params} />}
                />
              </LocalizationProvider>
            </Box>
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <FormControl xs={6} fullWidth size="">
              <InputLabel>Gender</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={gender}
                onChange={(e) => {
                  setGender(e.target.value);
                }}
                name="gender"
                autoComplete="gender"
                label="Gender"
              >
                <MenuItem value={'male'}>Male</MenuItem>
                <MenuItem value={'female'}>Female</MenuItem>
                <MenuItem value={'other'}>Other</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        {/* <TextField
          margin="normal"
          fullWidth
          id="gender"
          label=" Gender"
          name="gender"
          autoComplete="gender"
          defaultValue={studentProfile.gender}
          autoFocus
        /> */}
        <TextField
          margin="normal"
          fullWidth
          id="school"
          label=" School"
          name="school"
          autoComplete="school"
          defaultValue={studentProfile.school}
          autoFocus
          multiline
          rows="2"
        />

        <TextField
          margin="normal"
          select
          fullWidth
          id="state"
          label="State"
          onChange={handleStateChange}
          name="state"
          autoComplete="state"
          autoFocus
          value={stateName}
        >
          {indianStatesDistricts && indianStatesDistricts.states.length
            ? indianStatesDistricts.states.map((singleState, index) => (
                <MenuItem value={singleState.state} key={singleState.index}>
                  {singleState.state}
                </MenuItem>
              ))
            : null}
        </TextField>
        <TextField
          margin="normal"
          select
          fullWidth
          id="district"
          label="District"
          onChange={handleDistrictChange}
          name="district"
          autoComplete="district"
          autoFocus
          value={districtName}
        >
          {selectedState && selectedState.districts.length
            ? selectedState.districts.map((singleDistrict, index) => (
                <MenuItem value={singleDistrict} key={index}>
                  {singleDistrict}
                </MenuItem>
              ))
            : null}
        </TextField>

        <TextField
          margin="normal"
          fullWidth
          id="city"
          label=" City"
          name="city"
          autoComplete="city"
          defaultValue={studentProfile.city}
          autoFocus
          disabled={!stateName && !districtName}
        />

        <TextField
          margin="normal"
          fullWidth
          id="address"
          label="Address"
          name="address"
          autoComplete="address"
          autoFocus
          multiline
          defaultValue={studentProfile.address}
          rows="2"
        />

        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          disabled={result.isLoading || result.isSuccess}
        >
          Update Profile
        </Button>
      </Box>
    </>
  );
}

export default UpdateStudentProfile;
