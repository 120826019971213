/* eslint-disable no-unused-vars */
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Grid,
  Typography,
} from '@mui/material';
import moment from 'moment';
import React from 'react';
import { useParams } from 'react-router';
import DataLoader from '../../components/DataLoader';
import { useSupportTicketQuery } from '../../app/services/SupportTicketsApi';
import DisplaySupportTicketComments from './DisplaySupportTicketComments';

function SingleSupportTicket() {
  const params = useParams();

  const { data, isLoading, isSuccess } = useSupportTicketQuery({
    id: parseInt(params.Id, 10),
  });

  if (isLoading) {
    return <DataLoader thickness={5} size={50} />;
  }

  return (
    <>
      {isSuccess && data.supportTicket ? (
        <>
          <Grid container>
            <Grid item xs="12" sm="12" md="2" lg="2" xl="2"></Grid>
            <Grid item xs="12" sm="12" md="8" lg="8" xl="8">
              <Card>
                <CardHeader
                  avatar={
                    <Avatar aria-label="ticket">
                      {data.supportTicket.creator.name.charAt(0).toUpperCase()}
                    </Avatar>
                  }
                  title={data.supportTicket.creator.name}
                  subheader={moment(data.supportTicket.created_at).format(
                    'Do MMM YY hh:mm A'
                  )}
                />
                <CardContent>
                  <Typography variant="h5" component="div" sx={{ color: 'blue' }}>
                    {data.supportTicket.title}
                  </Typography>
                  {/* <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    Status: {data.supportTicket.status}
                  </Typography> */}

                  <Typography variant="body2">
                    {data.supportTicket.description}
                  </Typography>
                  {data.supportTicket.image ? (
                    <Box mt={2} textAlign="center">
                      <div>Provided Image</div>
                      <img src={data.supportTicket.image_url} height="200px" />
                    </Box>
                  ) : null}
                </CardContent>
                <CardActions disableSpacing>
                  {data.supportTicket.status == 'open' ? (
                    <Chip label="open" color="success" />
                  ) : null}
                  {data.supportTicket.status == 'closed' ? (
                    <Chip label="closed" color="error" />
                  ) : null}
                </CardActions>
              </Card>
              <Card>
                <DisplaySupportTicketComments
                  comments={data.supportTicket.comments}
                  support_ticket_id={data.supportTicket.id}
                  ticketStatus={data.supportTicket.status}
                />
              </Card>
            </Grid>
            <Grid item xs="12" sm="12" md="2" lg="2" xl="2"></Grid>
          </Grid>
        </>
      ) : (
        <p>No Support Ticket found</p>
      )}
    </>
  );
}

export default SingleSupportTicket;
