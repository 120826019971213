/* eslint-disable no-unused-vars */
import {
  Button,
  Typography,
  Grid,
  Box,
  CardActions,
  Paper,
  Alert,
  AlertTitle,
  Rating,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { useNavigate, useParams } from 'react-router';
import { usePublicExamQuery } from '../../../app/services/PublicExamsApi';

import moment from 'moment';
import { useExamEnrolMutation } from '../../../app/services/StudentExamsApi';
import DisplayExamReviews from './DisplayExamReviews';
import DataLoader from '../../../components/DataLoader';

function DisplaySingleExam() {
  const params = useParams();
  const navigate = useNavigate();

  const { user, isSignedIn } = useSelector((state) => state.auth);
  const { data, isLoading, isSuccess } = usePublicExamQuery({
    id: parseInt(params.examId, 10),
  });
  const [examEnrol, result] = useExamEnrolMutation();

  if (result.isSuccess) {
    return <Navigate replace to={`/enrols/exam/${result.data.enrol.id}`} />;
  }

  const enrolexam = async () => {
    await examEnrol({ exam_id: data.exam.id });
  };

  if (isLoading) {
    return <DataLoader thickness={5} size={50} />;
  }

  return isSuccess && data.exam ? (
    <>
      <Paper
        sx={{
          position: 'relative',
          backgroundColor: 'grey.800',
          color: '#fff',
          mb: 4,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundImage: data.exam.url
            ? `url(${data.exam.url})`
            : `url("https://images.unsplash.com/photo-1516397281156-ca07cf9746fc?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80")`,
        }}
      >
        {/* Increase the priority of the hero background image */}
        {
          <img
            style={{ display: 'none' }}
            src={
              data.exam.url ||
              `https://images.unsplash.com/photo-1516397281156-ca07cf9746fc?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80`
            }
            // alt={data.exam.imageText}
          />
        }
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            backgroundColor: 'rgba(0,0,0,.3)',
          }}
        />
        <Grid container>
          <Grid item md={3}></Grid>
          <Grid item md={6}>
            <Box
              sx={{
                position: 'relative',
                p: { xs: 3, md: 6 },
                pr: { md: 0 },
                overflowWrap: 'anywhere',
              }}
            >
              <Typography component="h2" variant="h5">
                <strong>{data.exam.title}</strong>
              </Typography>
              {/* <Typography variant="subtitle1">
                Starts from&nbsp;
                {moment(data.exam.start_date).format('Do MMM YY hh:mm A')}
              </Typography>
              <Typography variant="subtitle1">
                Ends on&nbsp;
                {moment(data.exam.end_date).format('Do MMM YY hh:mm A')}
              </Typography> */}
              <Typography variant="subtitle1">
                Duration:&nbsp;
                {moment(data.exam.end_date).diff(
                  moment(data.exam.start_date),
                  'minutes'
                )}
                &nbsp; minutes
              </Typography>
              <Typography variant="subtitle1">
                In&nbsp;
                {data.exam.language}
              </Typography>
              <Box sx={{ display: 'flex' }}>
                <Box>
                  <Typography>
                    {Math.round(data.exam.exam_reviews_avg_rating * 10) / 10}&nbsp;
                  </Typography>
                </Box>
                <Rating
                  name="read-only"
                  value={Math.round(data.exam.exam_reviews_avg_rating * 10) / 10}
                  readOnly
                  precision={0.5}
                />

                {data.exam.exam_reviews_count ? (
                  <Typography>
                    &#40;{data.exam.exam_reviews_count}
                    &nbsp;reviews&#41;
                  </Typography>
                ) : (
                  <Typography>&#40;no reviews&#41;</Typography>
                )}
              </Box>
              {data.exam.cost ? (
                <Typography component="h2" color="text.primary">
                  <strong>₹{data.exam.cost}</strong>
                </Typography>
              ) : (
                <Typography>Free Exam</Typography>
              )}
              <CardActions sx={{ padding: 0 }}>
                {isSignedIn && user.role !== 'student' ? (
                  <Alert severity="info">
                    Please register as a student to enrol for the exam
                  </Alert>
                ) : result.isError &&
                  result.error.data.message === 'ALREADY_ENROLED' ? (
                  <>
                    <Alert severity="info">
                      <>
                        <AlertTitle>
                          You are already Enroled to this exam.
                        </AlertTitle>
                        <Button
                          variant="contained"
                          color="secondary"
                          size="small"
                          onClick={() =>
                            navigate(`/enrols/exam/${result.error.data.enrol.id}`)
                          }
                        >
                          Click here to start learning
                        </Button>
                      </>
                    </Alert>
                  </>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      isSignedIn
                        ? enrolexam()
                        : navigate('/student-account', {
                            state: location.pathname,
                          });
                    }}
                  >
                    Enrol
                  </Button>
                )}
              </CardActions>
            </Box>
          </Grid>
          <Grid item md={3}></Grid>
        </Grid>
      </Paper>

      <Grid container spacing={2}>
        <Grid item xs="12" sm="12" md="1" lg="1" xl="1"></Grid>
        <Grid item xs="12" sm="12" md="10" lg="10" xl="10">
          <Box
            sx={{
              position: 'relative',
              p: { xs: 3, md: 3 },
              pr: { md: 0 },
              overflowWrap: 'anywhere',
            }}
          >
            <Typography component="h2" variant="h5">
              <strong>Exam Description</strong>
            </Typography>
            <Typography variant="subtitle1" paragraph>
              {data.exam.description}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs="12" sm="12" md="1" lg="1" xl="1"></Grid>

        <Grid item xs="12" sm="12" md="1" lg="1" xl="1"></Grid>
        <Grid item xs="12" sm="12" md="10" lg="10" xl="10">
          <>
            <Box
              sx={{
                position: 'relative',
                p: { xs: 3, md: 3 },
                pr: { md: 0 },
                overflowWrap: 'anywhere',
              }}
            >
              <DisplayExamReviews examReviews={data.exam.exam_reviews} />
            </Box>
          </>
        </Grid>
        <Grid item xs="12" sm="12" md="1" lg="1" xl="1"></Grid>
      </Grid>
    </>
  ) : (
    <p>No exam found</p>
  );
}

export default DisplaySingleExam;
