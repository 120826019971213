import React, { Fragment } from 'react';
import './footer.css';
import './bootstrap.css';
import Copyright from '../components/Copyright';
export const Footer = () => {
  const woWeServe = [
    {
      title: 'Students',
      data: 'Providing access to a wide range of high-quality educational content',
    },
    {
      title: 'Trainers',
      data: 'Enabling educators to concentrate on course creation without administrative burdens',
    },
    {
      title: 'Institutes',
      data: 'Offering comprehensive solutions to enhance their educational offerings',
    },
  ];
  const ourServices = [
    {
      title: 'Technology Solution',
      data: 'Seamlessly managing all technical aspects of course delivery',
    },
    {
      title: 'Payment Collection',
      data: 'Ensuring secure and efficient payment processing',
    },
    {
      title: 'Marketing',
      data: 'Promoting courses to reach a broad and diverse audience',
    },
    {
      title: 'Student Onboarding',
      data: 'Facilitating smooth onboarding processes for students',
    },
  ];
  const aboutUs =
    'Technobug, an innovative EdTech marketplace by Dcentmass, is dedicated to connecting talented course creators with eager students. Our mission is to empower educators by allowing them to focus solely on creating high-quality courses while we handle all other aspects of the educational experience. At Technobug, we strive to create an ecosystem where education thrives through innovation and collaboration.';
  return (
    <Fragment>
      <footer className="site__footer" style={{ marginTop: '1rem' }}>
        <div className="site-footer">
          <div className="decor site-footer__decor decor--type--bottom">
            <div className="decor__body">
              <div className="decor__start"></div>
              <div className="decor__end"></div>
              <div className="decor__center"></div>
            </div>
          </div>
          <div className="site-footer__widgets">
            <div className="container">
              <div className="row">
                <div className="col-12 col-xl-5">
                  <div className="site-footer__widget footer-contacts">
                    <h5 className="footer-contacts__title">About US</h5>
                    <div
                      className="footer-contacts__text"
                      style={{
                        textOverflow: 'ellipsis',
                        WebkitLineClamp: '3',
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        overflow: 'hidden',
                      }}
                      title={aboutUs}
                    >
                      {aboutUs}
                    </div>
                    <address className="footer-contacts__contacts">
                      <dl>
                        <dt>Phone Number</dt>
                        <dd>81779 00557</dd>
                      </dl>
                      <dl>
                        <dt>Email Address</dt>
                        <dd>connect@dcentmass.com</dd>
                      </dl>
                      <dl style={{ width: '100%' }}>
                        <dt>Our Location</dt>
                        <dd>
                          330, Mayur Trade Center Phase 1, Chinchwad Station, old
                          Mumbai Pune Highway, Pune, Maharashtra, India
                        </dd>
                      </dl>
                      {/* <dl>
                        <dt>Working Hours</dt>
                        <dd>Mon-Sat 10:00pm - 7:00pm</dd>
                      </dl> */}
                    </address>
                  </div>
                </div>
                <div className="col-6 col-md-3 col-xl-2">
                  <div className="site-footer__widget footer-links">
                    <h5 className="footer-links__title">Information</h5>
                    <ul className="footer-links__list">
                      <li className="footer-links__item">
                        <a
                          href="/privacy-policy"
                          target="_blank"
                          className="footer-links__link"
                        >
                          Privacy Policy
                        </a>
                      </li>
                      <li className="footer-links__item">
                        <a
                          href="/refund-policy"
                          target="_blank"
                          className="footer-links__link"
                        >
                          Refund Policy
                        </a>
                      </li>
                      <li className="footer-links__item">
                        <a
                          href="/subscription-cancellation-policy"
                          target="_blank"
                          className="footer-links__link"
                        >
                          Subscription Cancellation Policy
                        </a>
                      </li>
                      <li className="footer-links__item">
                        <a
                          href="/terms-conditions"
                          target="_blank"
                          className="footer-links__link"
                        >
                          Terms & Conditions
                        </a>
                      </li>
                      <li className="footer-links__item">
                        <a
                          href="/acceptance-of-agreement"
                          target="_blank"
                          className="footer-links__link"
                        >
                          Acceptance of Agreement
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-xl-5">
                  <div className="site-footer__widget footer-newsletter">
                    <h5 className="footer-newsletter__title">Who We Serve:</h5>
                    <div className="footer-newsletter__text">
                      {woWeServe.map((singleServe, index) => (
                        <p
                          style={{
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            width: '100%',
                            margin: '0rem',
                          }}
                          title={singleServe?.data}
                          key={index + singleServe?.title}
                        >
                          {`${singleServe?.title} : ${singleServe?.data}`}
                        </p>
                      ))}
                    </div>
                    <h5 className="footer-newsletter__title">Our Services</h5>
                    <div className="footer-newsletter__text">
                      {ourServices.map((singleServe, index) => (
                        <p
                          style={{
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            width: '100%',
                            margin: '0rem',
                          }}
                          title={singleServe?.data}
                          key={index + singleServe?.title}
                        >
                          {`${singleServe?.title} : ${singleServe?.data} .`}
                        </p>
                      ))}
                    </div>
                    {/* <form action="" className="footer-newsletter__form">
                      <label className="sr-only" htmlFor="footer-newsletter-address">
                        Email Address
                      </label>
                      <input
                        type="text"
                        className="footer-newsletter__form-input"
                        id="footer-newsletter-address"
                        placeholder="Email Address..."
                      />
                      <button className="footer-newsletter__form-button">
                        Subscribe
                      </button>
                    </form> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="site-footer__bottom">
            <div className="container">
              <div className="site-footer__bottom-row">
                <div className="site-footer__copyright">
                  <Copyright />
                </div>
                <div className="site-footer__payments">
                  <img src="images/payments.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </Fragment>
  );
};
