function DisplayAdminDocument(props) {
  const { doc } = props;
  return (
    <>
      <embed src={doc ? doc.doc_url : null} width="800px" height="1500px" />
    </>
  );
}

export default DisplayAdminDocument;
