import { Button, Grid, Typography } from '@mui/material';
import React, { Fragment, useState } from 'react';
import { EducatorSubscriptionPlan } from './EducatorSubscriptionPlan';
import { Footer } from '../../../layout/Footer';
export const TeacherPlan = () => {
  const [selectedPaymentDuration, setSelectedPaymentDuration] = useState('Month');
  // const plans = [
  //   {
  //     title: 'Starter Plan',
  //     price: '499',
  //     discription: `Explore GM's platform's capabilities
  //           Test key features before upgrading
  //           for revenue generation.
  //           `,
  //     color: '#2bb08c',
  //     subtitle: 'Demo Plan',
  //     features: [
  //       'Explore Essential Features',
  //       'Upload Limited Demo Content',
  //       'Create and publish demo course',
  //       'Conduct Live Sessions',
  //       'Create Sample Assessments',
  //       'Enroll Sample students',
  //       'Monitor Basic Insights',
  //       'Access Limited Technical Support ',
  //       'Access Full Instructional Support',
  //     ],
  //   },
  //   {
  //     title: 'Basic Plan',
  //     price: '999',
  //     discription: `Explore GM's platform's capabilities
  //           Test key features before upgrading
  //           for revenue generation.
  //           `,
  //     color: '#20afc4',
  //     subtitle: 'Demo Plan',
  //     features: [
  //       'Explore Essential Features',
  //       'Upload Limited Demo Content',
  //       'Create and publish demo course',
  //       'Conduct Live Sessions',
  //       'Create Sample Assessments',
  //       'Enroll Sample students',
  //       'Monitor Basic Insights',
  //       'Access Limited Technical Support ',
  //       'Access Full Instructional Support',
  //     ],
  //   },
  //   {
  //     title: 'Standard Plan',
  //     price: '1999',
  //     discription: `Explore GM's platform's capabilities
  //           Test key features before upgrading
  //           for revenue generation.
  //           `,
  //     color: '#008093',
  //     subtitle: 'Demo Plan',
  //     features: [
  //       'Explore Essential Features',
  //       'Upload Limited Demo Content',
  //       'Create and publish demo course',
  //       'Conduct Live Sessions',
  //       'Create Sample Assessments',
  //       'Enroll Sample students',
  //       'Monitor Basic Insights',
  //       'Access Limited Technical Support ',
  //       'Access Full Instructional Support',
  //     ],
  //   },
  //   {
  //     title: 'Premium Plan',
  //     price: '2999',
  //     discription: `Explore GM's platform's capabilities
  //           Test key features before upgrading
  //           for revenue generation.
  //           `,
  //     color: '#b8aa91',
  //     subtitle: 'Demo Plan',
  //     features: [
  //       'Explore Essential Features',
  //       'Upload Limited Demo Content',
  //       'Create and publish demo course',
  //       'Conduct Live Sessions',
  //       'Create Sample Assessments',
  //       'Enroll Sample students',
  //       'Monitor Basic Insights',
  //       'Access Limited Technical Support ',
  //       'Access Full Instructional Support',
  //     ],
  //   },
  // ];
  return (
    <Fragment>
      {' '}
      <Grid container spacing={2} justifyContent={'center'}>
        <Grid
          item
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          sx={{ background: '#00687b' }}
        >
          <Typography
            component="h2"
            sx={{
              fontSize: '20px',
              fontWeight: 700,
              color: '#fff',
              paddingTop: '0.3rem',
              paddingBottom: '0.3rem',
              paddingLeft: '3rem',
            }}
          >
            Techno Bug Teacher Registration
          </Typography>
        </Grid>
        <Grid
          item
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          sx={{ background: '#10496a', height: '30px' }}
        ></Grid>
        <Grid
          item
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          display={'flex'}
          alignSelf={'center'}
          justifyContent={'center'}
        >
          <Typography
            sx={{
              color: '#1b75bb',
              fontSize: '24px',
              fontWeight: 500,
              maxWidth: '940px',
              textAlign: 'center',
              alignSelf: 'center',
              marginTop: '3rem',
            }}
          >
            <span
              style={{
                fontWeight: 700,
                fontSize: '40px',
                lineHeight: 0,
              }}
            >
              Unlock premium features
            </span>{' '}
            and elevate your teaching experience with GM&apos;s subscription plans.
            Choose the plan
            <span
              style={{
                fontWeight: 700,
                fontSize: '40px',
                lineHeight: 0,
              }}
            >
              {' '}
              that suits your needs
            </span>{' '}
            and start creating impactful learning experiences today
          </Typography>
        </Grid>
        <Grid
          item
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          display={'flex'}
          alignSelf={'center'}
          justifyContent={'center'}
          paddingBottom={'1rem'}
          paddingTop={'2rem !important'}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '1rem',
              position: 'relative',
            }}
          >
            <div>
              <Button
                variant={
                  selectedPaymentDuration === 'Month' ? 'contained' : 'outlined'
                }
                sx={{
                  borderRadius: '0px',
                  background: selectedPaymentDuration === 'Month' ? '#20acc1' : '',
                  ':hover': {
                    background: selectedPaymentDuration === 'Month' ? '#20acc1' : '',
                  },
                }}
                onClick={() => {
                  setSelectedPaymentDuration('Month');
                }}
              >
                Monthly Billing
              </Button>
              <Button
                variant={
                  selectedPaymentDuration === 'Year' ? 'contained' : 'outlined'
                }
                sx={{
                  borderRadius: '0px',
                  background: selectedPaymentDuration === 'Year' ? '#20acc1' : '',
                  ':hover': {
                    background: selectedPaymentDuration === 'Year' ? '#20acc1' : '',
                  },
                }}
                onClick={() => {
                  setSelectedPaymentDuration('Year');
                }}
              >
                Yearly Billing
              </Button>
            </div>
            {selectedPaymentDuration == 'Year' && (
              <Typography sx={{ position: 'absolute', right: '-90px' }}>
                Save 10%
              </Typography>
            )}
          </div>
        </Grid>
        {/* <Grid
          item
          xl={10}
          lg={10}
          md={11}
          sm={11}
          xs={11}
          sx={{ paddingTop: '1rem', paddingBottom: '1rem', mt: 3 }}
        >
          <Grid container justifyContent={'center'} spacing={2}>
            {plans.map((item, index) => (
              <Grid item xl={3} lg={3} md={3} sm={6} xs={12} key={index + 'plan'}>
                <Paper>
                  <Typography
                    sx={{
                      background: item.color,
                      fontSize: '20px',
                      fontWeight: 700,
                      textAlign: 'center',
                      color: '#fff',
                      paddingTop: '0.5rem',
                      paddingBottom: '0.5rem',
                    }}
                  >
                    {item.title}
                  </Typography>
                  <Box sx={{ paddingTop: '1rem', paddingBottom: '1rem' }}>
                    <Typography
                      sx={{
                        color: item.color,
                        fontSize: '30px',
                        fontWeight: 700,
                        textAlign: 'center',
                      }}
                    >
                      Rs.{` ${item.price}`}
                    </Typography>
                    <Typography
                      sx={{
                        color: item.color,
                        fontSize: '20px',
                        fontWeight: 500,
                        textAlign: 'center',
                      }}
                    >
                      {`Teacher / ${selectedPaymentDuration}`}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      padding: '0.5rem',
                      background: `${item.color}1f`,
                    }}
                  >
                    <Typography
                      sx={{
                        color: item.color,
                        fontSize: '16px',
                        fontWeight: 400,
                        textAlign: 'center',
                      }}
                    >
                      {item.discription}
                    </Typography>
                  </Box>
                  <Typography
                    sx={{
                      color: item.color,
                      fontSize: '18px',
                      fontWeight: 400,
                      textAlign: 'center',
                      paddingTop: '1rem',
                    }}
                  >
                    Demo Plan
                  </Typography>
                  <Box sx={{ padding: '1rem' }}>
                    {item.features.map((feature, ind) => (
                      <Typography
                        sx={{
                          color: item.color,
                          fontSize: '14px',
                          fontWeight: 400,
                          textAlign: 'start',
                        }}
                        key={ind + 'feature'}
                      >
                        {feature}
                      </Typography>
                    ))}
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      paddingBottom: '1rem',
                    }}
                  >
                    <Button
                      variant="contained"
                      sx={{
                        background: item.color,
                        borderRadius: '20px',
                        ':hover': { background: item.color },
                      }}
                    >
                      Get Started
                    </Button>
                  </Box>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Grid> */}
      </Grid>
      <Grid container justifyContent={'center'}>
        <Grid
          item
          xl={11}
          lg={11}
          md={12}
          sm={12}
          xs={12}
          sx={{ marginTop: '1rem' }}
        >
          <EducatorSubscriptionPlan
            selectedPaymentDuration={selectedPaymentDuration}
          />
        </Grid>
      </Grid>
      <Footer />
    </Fragment>
  );
};
