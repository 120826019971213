import { Card, CardContent, Grid, Typography } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { useParams } from 'react-router';
import { useEducatorOnboardQuery } from '../../../app/services/EducatorSubscriptionPlansApi';
import DataLoader from '../../../components/DataLoader';

function DisplayOnBoardSubscription() {
  const params = useParams();

  const { data, isLoading, isSuccess } = useEducatorOnboardQuery({
    id: parseInt(params.Id, 10),
  });

  if (isLoading) {
    return <DataLoader thickness={5} size={50} />;
  }

  return (
    <>
      {isSuccess && data.subscription ? (
        <>
          <Grid container>
            <Grid item xs="12" sm="12" md="6" lg="6" xl="6">
              <Card>
                <CardContent>
                  <Typography
                    sx={{ fontSize: 14 }}
                    color="text.secondary"
                    gutterBottom
                  >
                    Payment Info{' '}
                  </Typography>
                  <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    Paid on :{' '}
                    {moment(data.subscription.created_at).format(
                      'Do MMM YY hh:mm A'
                    )}
                  </Typography>
                  <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    Amount paid: ₹{data.subscription.paid_amount}
                  </Typography>
                  <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    Status: {data.subscription.status}
                  </Typography>
                  <Typography variant="body2">
                    Transaction Id:{data.subscription.transaction_id}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </>
      ) : (
        <p>No subscription found</p>
      )}
    </>
  );
}

export default DisplayOnBoardSubscription;
