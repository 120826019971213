function DisplayDocument(props) {
  const { doc } = props;
  return (
    <>
      <embed
        src={doc?.doc_url ? doc?.doc_url : URL.createObjectURL(doc)}
        width="800px"
        height="1500px"
      />
    </>
  );
}

export default DisplayDocument;
