import { Grid } from '@mui/material';
import { useDocumentsQuery } from '../../../../app/services/CoursesApi';
import DataLoader from '../../../../components/DataLoader';
import DocumentsList from './DocumentsList';

function DocumentManager() {
  const { data, isLoading, isSuccess } = useDocumentsQuery();

  if (isLoading) {
    return <DataLoader thickness={5} size={50} />;
  }

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs="12" sm="12" md="12" lg="12" xl="12">
          {isSuccess && data.documents ? (
            <DocumentsList documents={data.documents} />
          ) : (
            <p>No Documents found</p>
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default DocumentManager;
