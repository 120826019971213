/* eslint-disable no-unused-vars */

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@mui/material';
import React from 'react';
import { useSupportTicketsQuery } from '../../../app/services/SupportTicketsApi';
import DataLoader from '../../../components/DataLoader';
import CreateNewSupportTicket from './CreateNewSupportTicket';
import UserSupportTicketsList from './UserSupportTicketsList';

function UserSupportTicketManager() {
  const [open, setOpen] = React.useState(false);
  const { data, isLoading, isSuccess } = useSupportTicketsQuery();

  if (isLoading) {
    return <DataLoader thickness={5} size={50} />;
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs="12" sm="12" md="12" lg="12" xl="12">
          <div style={{ textAlign: 'center' }}>
            <Button variant="contained" onClick={handleClickOpen}>
              Create New Support Ticket
            </Button>
            <Dialog open={open} onClose={handleClose} maxWidth="md">
              <DialogTitle> Create New Support Ticket</DialogTitle>
              <DialogContent>
                <CreateNewSupportTicket />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Close</Button>
              </DialogActions>
            </Dialog>
          </div>
          {isSuccess && data.supportTickets.length ? (
            <UserSupportTicketsList supportTickets={data.supportTickets} />
          ) : (
            <p>No support tickets found</p>
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default UserSupportTicketManager;
