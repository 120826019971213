/* eslint-disable no-unused-vars */

import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { usePublishedStudentSubscriptionPlansQuery } from '../../../app/services/StudentSubscriptionPlansApi';

function DisplayStudentSubscriptionPlans() {
  const navigate = useNavigate();

  const { data, isLoading, isSuccess } = usePublishedStudentSubscriptionPlansQuery();

  const videwSubscriptionPlan = (id) => {
    navigate(`/student-subscription-plans/${id}`);
  };

  return (
    <>
      <Grid container>
        <Grid item md={2}></Grid>
        <Grid item md={8} sx={{ background: 'lightcyan' }}>
          {isSuccess && data.studentSubscriptionPlans ? (
            <Box
              sx={{ ml: '20px', mt: '20px', height: '100vh', alignItems: 'center' }}
            >
              <Grid container>
                {data.studentSubscriptionPlans.map((singlePlan) => (
                  <Grid item md={3} key={singlePlan.id}>
                    <Typography variant="h4">{singlePlan.title}</Typography>
                    <Typography>{singlePlan.no_of_days} Days</Typography>

                    <Typography>
                      <pre>{singlePlan.description}</pre>
                    </Typography>
                    {singlePlan.published_sub_category_plans.length >= 1
                      ? singlePlan.published_sub_category_plans.map(
                          (singleSubPlan, index) => (
                            <Box
                              key={singleSubPlan.id}
                              sx={{
                                display: 'flex',
                                justifyContent: 'space-around',
                              }}
                            >
                              <Typography>{singleSubPlan.title}</Typography>
                              <Typography key={singleSubPlan.id}>
                                ₹{singleSubPlan.cost}
                              </Typography>
                            </Box>
                          )
                        )
                      : null}
                    {singlePlan.published_grade_plans.length >= 1
                      ? singlePlan.published_grade_plans.map(
                          (singleSubPlan, index) => (
                            <Box
                              key={singleSubPlan.id}
                              sx={{
                                display: 'flex',
                                justifyContent: 'space-around',
                              }}
                            >
                              <Typography>{singleSubPlan.title}</Typography>
                              <Typography key={singleSubPlan.id}>
                                ₹{singleSubPlan.cost}
                              </Typography>
                            </Box>
                          )
                        )
                      : null}
                    {singlePlan.published_grade_subject_plans.length >= 1
                      ? singlePlan.published_grade_subject_plans.map(
                          (singleSubPlan, index) => (
                            <Box
                              key={singleSubPlan.id}
                              sx={{
                                display: 'flex',
                                justifyContent: 'space-around',
                              }}
                            >
                              <Typography>{singleSubPlan.title}</Typography>
                              <Typography key={singleSubPlan.id}>
                                ₹{singleSubPlan.cost}
                              </Typography>
                            </Box>
                          )
                        )
                      : null}
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => videwSubscriptionPlan(singlePlan.id)}
                    >
                      Get Started
                    </Button>
                  </Grid>
                ))}
              </Grid>
            </Box>
          ) : (
            <Typography>No student subscription Plan Found</Typography>
          )}
        </Grid>
        <Grid item md={8}></Grid>

        {/* <Grid
        item
        xs={false}
        sm={4}
        md={6}
        sx={{
          backgroundImage:
            'url(https://images.unsplash.com/photo-1521898284481-a5ec348cb555?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80)',
        }}
      ></Grid> */}
      </Grid>
    </>
  );
}
export default DisplayStudentSubscriptionPlans;
