/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import React, { useRef } from 'react';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import { Grid, Box, Button, TextField, InputAdornment, Alert } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import PropTypes from 'prop-types';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';

import ProgressRequest from './ProgressRequest';
import HlsJs from '../../../../HlsJs';

const API_URL = `${process.env.REACT_APP_BACKEND_API_PATH}/api`;

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

function UploadSubTopicVideo(props) {
  const { enqueueSnackbar } = useSnackbar();
  const { token } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const [uploaded, setUploaded] = useState(false);
  const { videoUrl, subTopicId, closeDialog } = props;
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [vidUrl, setVideoUrl] = useState(videoUrl);
  const [videoStatus, setVideoStatus] = useState(null);

  const videoRef = useRef();
  const [progress, setProgress] = React.useState(0);
  const [processProgress, setProcessProgress] = React.useState(0);
  const [video, setVideo] = useState(null);
  const [title, setTitle] = React.useState('');
  const [description, setDescription] = React.useState('');
  const [duration, setSetDuration] = React.useState(0);

  useEffect(() => {
    if (selectedVideo) {
      setVideoUrl(URL.createObjectURL(selectedVideo));
    }
    videoRef.current?.load();
  }, [selectedVideo]);

  const fileUploadRequest = (uploadData) => {
    return axios.post(
      `${API_URL}/course/subject/topic/subTopic/video/upload/${uploadData.subTopicId}`,
      {
        file: uploadData.file,
        file_type: 'subTopic',
        title: title,
        description: description,
        duration: uploadData.duration,
      },
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${uploadData.token}`,
        },

        onUploadProgress: (progressEvent) => {
          var percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );

          setProgress(percentCompleted);
        },
      }
    );
  };

  const validateData = (videoData) => {
    let err = true;

    if (!videoData.title) {
      enqueueSnackbar('Please provide title for the video', {
        variant: 'error',
      });
    } else {
      err = false;
    }

    return err;
  };

  const UploadVideo = (event) => {
    event.preventDefault();
    setLoading(true);

    const UploadData = {
      file: selectedVideo,
      token: token,
      subTopicId: subTopicId,
      title: title,
      description: description,
      duration: duration,
    };
    const res = validateData({ title });
    if (!res) {
      enqueueSnackbar(
        'Your video is being uploaded. Please do not refresh or leave this page',
        {
          variant: 'success',
        }
      );

      fileUploadRequest(UploadData)
        .then((res) => {
          if (res.data.video.id) {
            setLoading(false);
            setUploaded(true);
            // setVideoUrl(res.data.video.vid_url);
            setVideo(res.data.video);
            setVideoStatus('SUCCESS');
            setProcessProgress(100);

            enqueueSnackbar('Video Uploaded Successfully.', {
              variant: 'success',
            });
          } else {
            setLoading(false);

            enqueueSnackbar('Error uploading Video. ', {
              variant: 'error',
            });
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const loadedMetaData = (event) => {
    const video = videoRef.current;
    if (!video) return;

    setSetDuration(Math.floor(video.duration));
  };

  const onTitleChange = (e) => {
    setTitle(e.target.value);
  };
  const onDescriptionChange = (e) => {
    setDescription(e.target.value);
  };
  return (
    <>
      <Grid>
        <Grid item xs="12" sm="12" md="2" lg="2" xl="2" align="left"></Grid>
        <Grid item xs="12" sm="12" md="8" lg="8" xl="8" align="left">
          {vidUrl && (
            <Box mt={2} textAlign="center">
              <div>Video Preview:</div>

              {processProgress === 100 && video && video.vid_url ? (
                <HlsJs vidSrc={video.vid_url} />
              ) : (
                <video
                  controls
                  ref={videoRef}
                  controlsList="nodownload"
                  onLoadedMetadata={loadedMetaData}
                >
                  <source src={vidUrl} type="video/mp4" />
                  Your browser does not support HTML video.
                </video>
              )}
            </Box>
          )}
          <Box component="form" onSubmit={UploadVideo} noValidate sx={{ mt: 1 }}>
            <Button
              color="primary"
              variant="contained"
              aria-label="upload picture"
              disabled={loading || uploaded}
              component="label"
              endIcon={<OndemandVideoIcon />}
            >
              select video
              <input
                hidden
                accept="video/*"
                type="file"
                style={{ display: 'none' }}
                onChange={(e) => setSelectedVideo(e.target.files[0])}
              />
            </Button>
            <TextField
              margin="normal"
              required
              fullWidth
              id="title"
              label=" Title"
              name="title"
              autoComplete="title"
              autoFocus
              value={title}
              onChange={onTitleChange}
            />
            <TextField
              margin="normal"
              fullWidth
              id="description"
              label="Description"
              name="description"
              autoComplete="description"
              autoFocus
              multiline
              onChange={onDescriptionChange}
              value={description}
              rows="2"
            />
            <TextField
              margin="normal"
              // required
              fullWidth
              id="duration"
              label="Duration"
              name="duration"
              autoComplete="duration"
              type="number"
              autoFocus
              disabled
              value={duration}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">seconds</InputAdornment>
                ),
              }}
            />
            Step 1: Video upload
            {progress > 0 ? (
              <Box sx={{ width: '100%' }}>
                <LinearProgressWithLabel value={progress} color="secondary" />
              </Box>
            ) : null}
            <br /> <br />
            Step 2: Video processing for multiple devices and resolutions
            {/* {processProgress > 39 ? (
              <ProgressRequest
                uploadProcessProgress={processProgress}
                setUploadProcessProgress={setProcessProgress}
                id={video.media_convert_job_id}
              />
            ) : null} */}
            <br />
            <br />
            {loading && processProgress < 100 ? (
              <p>please wait while we upload your video</p>
            ) : null}
            {/* {videoStatus === 'PROCESSING' ? (
              <p>Your video is being processed. Please Wait</p>
            ) : null} */}
            {processProgress === 100 ? (
              <Alert severity="success">Your video Uploaded Successfully</Alert>
            ) : null}
            &nbsp;
            {selectedVideo && title ? (
              <Button
                variant="contained"
                type="submit"
                disabled={loading || uploaded}
              >
                Upload
              </Button>
            ) : null}
          </Box>
        </Grid>
        <Grid item xs="12" sm="12" md="2" lg="2" xl="2" align="left"></Grid>
      </Grid>
    </>
  );
}

export default UploadSubTopicVideo;
