/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BaseApi } from './BaseApi';

const API_URL = `${process.env.REACT_APP_BACKEND_API_PATH}/api`;
const LOCALSTORAGE_APP_KEY = `${process.env.REACT_APP_LOCALSTORAGE_KEY}`;

export const studentSubscriptionPlansApi = BaseApi.injectEndpoints({
  reducerPath: 'studentSubscriptionPlansApi',
  endpoints: (builder) => ({
    studentSubscriptionPlans: builder.query({
      query: () => ({
        url: '/student/subscription/plans',
        method: 'GET',
      }),
      providesTags: ['StudentSubscriptionPlan'],
    }),
    createStudentSubscriptionPlan: builder.mutation({
      query: (details) => ({
        url: '/student/subscription/plan/create',
        method: 'POST',

        body: {
          title: details.title,
          description: details.description,
          no_of_days: details.no_of_days,
          category_id: details.category_id,
        },
      }),
      invalidatesTags: ['StudentSubscriptionPlan'],
    }),
    studentSubscriptionPlan: builder.query({
      query: (details) => ({
        url: `/student/subscription/plan/show/${details.id}`,
        method: 'GET',
      }),
      providesTags: ['StudentSubscriptionPlan'],
    }),
    updateStudentSubscriptionPlan: builder.mutation({
      query: (details) => ({
        url: `/student/subscription/plan/update/${details.id}`,
        method: 'POST',

        body: {
          title: details.title,
          description: details.description,
          no_of_days: details.no_of_days,
          category_id: details.category_id,
        },
      }),
      invalidatesTags: ['StudentSubscriptionPlan'],
    }),
    updateStudentSubscriptionPlanSatus: builder.mutation({
      query: (details) => ({
        url: '/student/subscription/plan/status/update',
        method: 'POST',

        body: {
          status: details.status,
          student_subscription_plan_id: details.id,
        },
      }),
      invalidatesTags: ['StudentSubscriptionPlan'],
    }),
    publishedStudentSubscriptionPlans: builder.query({
      query: (details) => ({
        url: `/public/student/subscription/plans`,
        method: 'GET',
      }),
      providesTags: ['StudentSubscriptionPlan'],
    }),
    createStudentPaymentOrderId: builder.mutation({
      query: (details) => ({
        url: '/student/subscription/payment/create/orderId',
        method: 'POST',

        body: {
          amount: details.amount,
          currency: details.currency,
        },
      }),
      invalidatesTags: ['StudentSubscriptionPlan'],
    }),
    publicStudentSubscriptionPlan: builder.query({
      query: (details) => ({
        url: `/public/student/subscription/plan/show/${details.id}`,
        method: 'GET',
      }),
      providesTags: ['StudentSubscriptionPlan'],
    }),

    createStudentSubscriptionWithPayment: builder.mutation({
      query: (details) => ({
        url: '/student/subscription/with/payment',
        method: 'POST',

        body: {
          razorpay_payment_id: details.razorpay_payment_id,
          order_id: details.order_id,
          razorpay_signature: details.razorpay_signature,
          amount: details.amount,
          student_subscription_plan_id: details.student_subscription_plan_id,
          email: details.email,
          subCategories: details.subCategories,
          grades: details.grades,
          gradeSubjects: details.gradeSubjects,
        },
      }),
      invalidatesTags: ['StudentSubscriptionPlan'],
    }),
    createStudentSubCategorySubscriptionPlan: builder.mutation({
      query: (details) => ({
        url: '/student/subcategory/subscription/plan/create',
        method: 'POST',

        body: {
          title: details.title,
          cost: details.cost,
          category_id: details.category_id,
          student_subscription_plan_id: details.student_subscription_plan_id,
          sub_category_id: details.sub_category_id,
        },
      }),
      invalidatesTags: ['StudentSubscriptionPlan'],
    }),
    createStudentGradeSubscriptionPlan: builder.mutation({
      query: (details) => ({
        url: '/student/grade/subscription/plan/create',
        method: 'POST',

        body: {
          title: details.title,
          cost: details.cost,
          category_id: details.category_id,
          sub_category_id: details.sub_category_id,
          student_subscription_plan_id: details.student_subscription_plan_id,
          grade_id: details.grade_id,
        },
      }),
      invalidatesTags: ['StudentSubscriptionPlan'],
    }),
    createStudentGradeSubjectSubscriptionPlan: builder.mutation({
      query: (details) => ({
        url: '/student/gradesubject/subscription/plan/create',
        method: 'POST',

        body: {
          title: details.title,
          cost: details.cost,
          category_id: details.category_id,
          sub_category_id: details.sub_category_id,
          student_subscription_plan_id: details.student_subscription_plan_id,
          grade_id: details.grade_id,
          grade_subject_id: details.grade_subject_id,
        },
      }),
      invalidatesTags: ['StudentSubscriptionPlan'],
    }),
    updateStudentSubCategorySubscriptionPlanSatus: builder.mutation({
      query: (details) => ({
        url: '/student/subcategory/subscription/plan/status/update',
        method: 'POST',

        body: {
          status: details.status,
          student_subscription_plan_sub_category_id: details.id,
        },
      }),
      invalidatesTags: ['StudentSubscriptionPlan'],
    }),
    updateStudentGradeSubscriptionPlanSatus: builder.mutation({
      query: (details) => ({
        url: '/student/grade/subscription/plan/status/update',
        method: 'POST',

        body: {
          status: details.status,
          student_subscription_plan_grade_id: details.id,
        },
      }),
      invalidatesTags: ['StudentSubscriptionPlan'],
    }),
    updateStudentGradeSubjectSubscriptionPlanSatus: builder.mutation({
      query: (details) => ({
        url: '/student/gradesubject/subscription/plan/status/update',
        method: 'POST',

        body: {
          status: details.status,
          student_subscription_plan_grade_subject_id: details.id,
        },
      }),
      invalidatesTags: ['StudentSubscriptionPlan'],
    }),
    studentSubCategorySubscriptionPlan: builder.query({
      query: (details) => ({
        url: `/student/subcategory/subscription/plan/show/${details.id}`,
        method: 'GET',
      }),
      providesTags: ['StudentSubscriptionPlan'],
    }),
    studentGradeSubscriptionPlan: builder.query({
      query: (details) => ({
        url: `/student/grade/subscription/plan/show/${details.id}`,
        method: 'GET',
      }),
      providesTags: ['StudentSubscriptionPlan'],
    }),
    studentGradeSubjectSubscriptionPlan: builder.query({
      query: (details) => ({
        url: `/student/gradesubject/subscription/plan/show/${details.id}`,
        method: 'GET',
      }),
      providesTags: ['StudentSubscriptionPlan'],
    }),
  }),
});

export const {
  useStudentSubscriptionPlansQuery,
  useCreateStudentSubscriptionPlanMutation,
  useStudentSubscriptionPlanQuery,
  useUpdateStudentSubscriptionPlanMutation,
  useUpdateStudentSubscriptionPlanSatusMutation,
  usePublishedStudentSubscriptionPlansQuery,
  useCreateStudentPaymentOrderIdMutation,
  usePublicStudentSubscriptionPlanQuery,
  useCreateStudentSubscriptionWithPaymentMutation,
  useCreateStudentSubCategorySubscriptionPlanMutation,
  useCreateStudentGradeSubscriptionPlanMutation,
  useCreateStudentGradeSubjectSubscriptionPlanMutation,
  useUpdateStudentSubCategorySubscriptionPlanSatusMutation,
  useUpdateStudentGradeSubscriptionPlanSatusMutation,
  useUpdateStudentGradeSubjectSubscriptionPlanSatusMutation,
  useStudentSubCategorySubscriptionPlanQuery,
  useStudentGradeSubscriptionPlanQuery,
  useStudentGradeSubjectSubscriptionPlanQuery,
} = studentSubscriptionPlansApi;
