/* eslint-disable no-unused-vars */
import { Grid } from '@mui/material';
import React from 'react';
import { useSupportTicketsQuery } from '../../../app/services/SupportTicketsApi';
import DataLoader from '../../../components/DataLoader';
import SupportTicketsList from './SupportTicketsList';

function SupportTicketManager() {
  const { data, isLoading, isSuccess } = useSupportTicketsQuery();

  if (isLoading) {
    return <DataLoader thickness={5} size={50} />;
  }

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs="12" sm="12" md="12" lg="12" xl="12">
          {isSuccess && data.supportTickets.length ? (
            <SupportTicketsList supportTickets={data.supportTickets} />
          ) : (
            <p>No support tickets found</p>
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default SupportTicketManager;
