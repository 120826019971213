import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { useParams } from 'react-router';
import { useEducatorSubscriptionPlanQuery } from '../../../app/services/EducatorSubscriptionPlansApi';
import DataLoader from '../../../components/DataLoader';
import UpdateEducatorSubscriptionPlan from './UpdateEducatorSubscriptionPlan';

function SingleEducatorSubscriptionPlan() {
  const params = useParams();
  const [open1, setOpen1] = React.useState(false);

  const { data, isLoading, isSuccess } = useEducatorSubscriptionPlanQuery({
    id: parseInt(params.Id, 10),
  });

  const handleClickOpen1 = () => {
    setOpen1(true);
  };

  const handleClose1 = () => {
    setOpen1(false);
  };

  if (isLoading) {
    return <DataLoader thickness={5} size={50} />;
  }

  return (
    <>
      {isSuccess && data.educatorSubscriptionPlan ? (
        <>
          <Grid item xs="12" sm="12" md="12" lg="12" xl="12">
            <Box sx={{ textAlign: 'center' }}>
              <Button variant="contained" onClick={handleClickOpen1}>
                Update Educator Subscription Plan
              </Button>
              <>
                <Dialog open={open1} onClose={handleClose1}>
                  <DialogTitle>Update Educator Subscription Plan</DialogTitle>
                  <DialogContent>
                    <UpdateEducatorSubscriptionPlan
                      subscriptionPlan={data.educatorSubscriptionPlan}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose1}>Close</Button>
                  </DialogActions>
                </Dialog>
              </>
              &nbsp;
            </Box>
          </Grid>
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            sx={{ padding: 4, color: '#233c8d' }}
          >
            Title: {data.educatorSubscriptionPlan.title}
            <br />
            Description: {data.educatorSubscriptionPlan.description}
            <br />
            Cost: ₹{data.educatorSubscriptionPlan.cost}
            <br />
            Status: {data.educatorSubscriptionPlan.status}
          </Typography>
        </>
      ) : (
        <p>No educator subscriptionPlan found</p>
      )}
    </>
  );
}

export default SingleEducatorSubscriptionPlan;
