/* eslint-disable no-unused-vars */
import {
  Close,
  ContentCopy,
  Email,
  Facebook,
  LinkedIn,
  ShareSharp,
  Twitter,
  WhatsApp,
} from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import {
  usePublicCategoryQuery,
  usePublishedCategoriesQuery,
} from '../../app/services/CategoriesApi';
import { useLocation } from 'react-router-dom';
import { getOrigin } from '../../Uitils';

const ShareButton = ({ course, enrolledpage }) => {
  const location = useLocation();
  const [urlToShare, setUrlToShare] = useState('');
  const { data, isLoading, isSuccess } = usePublishedCategoriesQuery();
  const [showOptions, setShowOptions] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const handleToggleOptions = () => {
    setShowOptions(!showOptions);
  };

  const handleShareToPlatform = (platform) => {
    let shareUrl = '';
    switch (platform) {
      case 'facebook':
        shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
          urlToShare
        )}`;
        break;
      case 'twitter':
        shareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
          urlToShare
        )}`;
        break;
      case 'linkedin':
        shareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
          urlToShare
        )}`;
        break;
      case 'email':
        shareUrl = `mailto:?subject=Check this out&body=${encodeURIComponent(
          urlToShare
        )}`;
        break;
      case 'whatsapp':
        shareUrl = `https://wa.me/?text=${encodeURIComponent(urlToShare)}`;
        break;
      default:
        break;
    }
    window.open(shareUrl, '_blank');
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(urlToShare);
    enqueueSnackbar('Link copied to clipboard', {
      variant: 'success',
    });
  };
  useEffect(() => {
    setUrlToShare('');
    const origin = getOrigin();
    let categories = data?.categories;
    let finalCategory =
      Array.isArray(categories) && categories.length > 0
        ? categories.find((singleCategory) =>
            singleCategory.published_sub_categories.find(
              (singleSubCategory) => singleSubCategory.id == course?.sub_category_id
            )
          )
        : null;
    let finalSubCategory = finalCategory
      ? Array.isArray(finalCategory.published_sub_categories) &&
        finalCategory.published_sub_categories.length > 0
        ? finalCategory.published_sub_categories.find(
            (singleSubCategory) => singleSubCategory.id == course?.sub_category_id
          )
        : null
      : null;

    let finalCopyUrl = `${origin}/${finalCategory?.title}/${finalSubCategory?.title}/course/${course?.id}`;
    finalCopyUrl = finalCopyUrl.replace(/ /g, '%20');
    if (finalCategory && finalSubCategory && origin && course) {
      setUrlToShare(finalCopyUrl);
    }
  }, [course, data]);
  return (
    <>
      {enrolledpage ? (
        <>
          {' '}
          <ShareSharp
            sx={{ color: '#4b87c7', cursor: 'pointer' }}
            onClick={handleToggleOptions}
          />{' '}
          <span
            style={{
              fontSize: '14px',
              fontWeight: 600,
              color: 'GrayText',
              marginLeft: '0.5rem',
            }}
          >
            Share
          </span>
        </>
      ) : (
        <>
          <IconButton
            color="primary"
            onClick={handleToggleOptions}
            aria-label="add to shopping cart"
          >
            <ShareSharp />
          </IconButton>
          <span style={{ fontSize: '14px' }}>Share</span>
        </>
      )}
      {/* <IconButton
        color="primary"
        onClick={handleToggleOptions}
        aria-label="add to shopping cart"
      >
        <ShareSharp />
      </IconButton>
      <span style={{ fontSize: '14px' }}>Share</span> */}
      <Dialog
        open={showOptions}
        onClose={() => {
          setShowOptions(false);
        }}
      >
        <DialogTitle> Share</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => {
            setShowOptions(false);
          }}
          sx={(theme) => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: '#000',
          })}
        >
          <Close />
        </IconButton>
        <DialogContent sx={{ width: '400px' }}>
          <div
            className="share-options"
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '1rem',
              justifyContent: 'center',
              flexWrap: 'wrap',
            }}
          >
            <IconButton
              color="primary"
              onClick={() => handleShareToPlatform('facebook')}
              aria-label="facebook"
            >
              <Facebook sx={{ fontSize: '35px', color: '#186aff' }} />
            </IconButton>
            <IconButton
              color="primary"
              onClick={() => handleShareToPlatform('twitter')}
              aria-label="twitter"
            >
              <Twitter sx={{ fontSize: '35px', color: '#0b9ff5' }} />
            </IconButton>
            <IconButton
              color="primary"
              onClick={() => handleShareToPlatform('linkedin')}
              aria-label="linkedin"
            >
              <LinkedIn sx={{ fontSize: '35px', color: '#126b9c' }} />
            </IconButton>
            <IconButton
              color="primary"
              onClick={() => handleShareToPlatform('email')}
              aria-label="email"
            >
              <Email sx={{ fontSize: '35px' }} />
            </IconButton>
            <IconButton
              color="primary"
              onClick={() => handleShareToPlatform('whatsapp')}
              aria-label="whatsapp"
            >
              <WhatsApp sx={{ fontSize: '35px', color: '#33d46b' }} />
            </IconButton>
          </div>
          <Box sx={{ display: 'flex', gap: '0.5rem', marginTop: '1rem' }}>
            <TextField
              id="standard-name"
              defaultValue={urlToShare}
              fullWidth
              sx={{ borderRadius: '20px' }}
              className="inputField"
              InputProps={{
                readOnly: true,
                style: {
                  borderRadius: '50px',
                },
                endAdornment: (
                  <Button
                    variant="contained"
                    sx={{
                      marginLeft: '1rem',
                      borderRadius: '50px',
                      textTransform: 'capitalize',
                    }}
                    onClick={handleCopyLink}
                  >
                    Copy
                  </Button>
                ),
              }}
            />
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ShareButton;
