/* eslint-disable no-unused-vars */
import { Button, Box, TextField, MenuItem, InputAdornment } from '@mui/material';

import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useCreateStudentSubCategorySubscriptionPlanMutation } from '../../../../../app/services/StudentSubscriptionPlansApi';

function CreateSubCategoryPlan(props) {
  const { subscriptionPlan } = props;
  const { enqueueSnackbar } = useSnackbar();

  const [createSubscriptionPlan, result] =
    useCreateStudentSubCategorySubscriptionPlanMutation();

  const [subCategoryTitle, setSubCategoryTitle] = useState('');

  const [selectedSubCategory, setSelectedSubCategory] = useState({
    title: '',
    id: null,
  });

  const validateData = (subscriptionPlanData) => {
    let err = true;

    if (!subscriptionPlanData.title || !subscriptionPlanData.category_id) {
      enqueueSnackbar('Please provide all the required fields', {
        variant: 'error',
      });
    } else {
      err = false;
    }

    return err;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const subscriptionPlanData = {
      title: selectedSubCategory.title,
      cost: data.get('cost'),
      category_id: subscriptionPlan.category.id,
      sub_category_id: selectedSubCategory.id,
      student_subscription_plan_id: subscriptionPlan.id,
    };

    const res = validateData(subscriptionPlanData);
    if (!res) {
      createSubscriptionPlan(subscriptionPlanData);
    }
  };

  const handleSubCategoryChange = (event) => {
    const tempSelectedSubCategory =
      subscriptionPlan.category.published_sub_categories.find(
        (singleSubCategory) => singleSubCategory.id === event.target.value
      );

    setSelectedSubCategory(tempSelectedSubCategory);
    setSubCategoryTitle(event.target.value);
  };

  return result.data ? (
    result.data.message
  ) : (
    <>
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <TextField
          margin="normal"
          select
          required
          fullWidth
          id="sub_category"
          label="Sub Category"
          onChange={handleSubCategoryChange}
          name="sub_category"
          autoComplete="sub_category"
          autoFocus
          value={subCategoryTitle}
        >
          {subscriptionPlan &&
          subscriptionPlan.category &&
          subscriptionPlan.category.published_sub_categories.length
            ? subscriptionPlan.category.published_sub_categories.map(
                (singleSubCategory, index) => (
                  <MenuItem value={singleSubCategory.id} key={singleSubCategory.id}>
                    {singleSubCategory.title}
                  </MenuItem>
                )
              )
            : null}
        </TextField>

        <TextField
          margin="normal"
          // required
          fullWidth
          id="cost"
          label="Cost"
          name="cost"
          autoComplete="cost"
          type="number"
          autoFocus
          InputProps={{
            startAdornment: <InputAdornment position="start">₹</InputAdornment>,
          }}
        />

        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          disabled={result.isLoading || result.isSuccess}
        >
          Add Student Sub Category Subscription Plan
        </Button>
      </Box>
    </>
  );
}

export default CreateSubCategoryPlan;
