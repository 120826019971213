import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
} from '@mui/material';
import React from 'react';
import { useEducatorSubscriptionPlansQuery } from '../../../app/services/EducatorSubscriptionPlansApi';
import DataLoader from '../../../components/DataLoader';
import CreateNewEducatorSubscriptionPlan from './CreateNewEducatorSubscriptionPlan';
import EducatorSubscriptionPlansList from './EducatorSubscriptionPlansList';

function EducatorSubscriptionPlansManager() {
  const [open, setOpen] = React.useState(false);

  const { data, isLoading, isSuccess } = useEducatorSubscriptionPlansQuery();

  if (isLoading) {
    return <DataLoader thickness={5} size={50} />;
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs="12" sm="12" md="12" lg="12" xl="12">
          <div style={{ textAlign: 'center' }}>
            <Button variant="contained" onClick={handleClickOpen}>
              Create New Educator Subscription Plan
            </Button>
            <Dialog open={open} onClose={handleClose}>
              <DialogTitle> Create New Subscription Plan</DialogTitle>
              <DialogContent>
                <CreateNewEducatorSubscriptionPlan />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Close</Button>
              </DialogActions>
            </Dialog>
          </div>
        </Grid>
        <Grid item xs="12" sm="12" md="12" lg="12" xl="12">
          {isSuccess && data.educatorSubscriptionPlans.length ? (
            <EducatorSubscriptionPlansList
              subscriptionPlans={data.educatorSubscriptionPlans}
            />
          ) : (
            <p>No educator subscription plans found</p>
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default EducatorSubscriptionPlansManager;
