/* eslint-disable no-unused-vars */

import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useUpdateEducatorBankInfoMutation } from '../../../app/services/EducatorApi';
import { useState } from 'react';

function UpdatePayout(props) {
  const { bankInfo } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [isDisabled, setIsDisabled] = useState(true);
  const [updateBankInfo, res] = useUpdateEducatorBankInfoMutation();

  const validateData = (bankData) => {
    let err = true;

    if (
      !bankData.account_name ||
      !bankData.account_number ||
      !bankData.ifsc_code ||
      !bankData.account_type ||
      !bankData.branch ||
      !bankData.bank_name ||
      !bankData.branch_address
    ) {
      enqueueSnackbar('Please provide all the required fields', {
        variant: 'error',
      });
    } else {
      err = false;
    }

    return err;
  };
  const handleChangeSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const bankData = {
      id: bankInfo.id,
      account_name: data.get('accountName'),
      account_number: data.get('accountNumber'),
      ifsc_code: data.get('ifscCode'),
      account_type: data.get('accountType'),
      branch: data.get('branch'),
      bank_name: data.get('bankName'),
      branch_address: data.get('branchAddress'),
    };

    const res = validateData(bankData);
    if (!res) {
      updateBankInfo(bankData)
        .unwrap()
        .then(() => {
          enqueueSnackbar('Success', {
            variant: 'success',
          });
        })
        .catch((error) => {
          enqueueSnackbar('Error ', {
            variant: 'error',
          });
        });
    }
    setIsDisabled(true);
  };
  return (
    <Box
      sx={{
        // margin: '1rem',
        marginTop: '1rem',
        padding: '1rem',
        boxShadow: '1px 1px 10px 1px #dadada',
        borderRadius: '6px',
      }}
      component="form"
      onSubmit={handleChangeSubmit}
    >
      <Grid container spacing={1}>
        <Grid item xl={12} lg={12} md={12} sm={12}>
          <Typography sx={{ fontSize: '12px', color: '#57494a' }}>
            Account Name
          </Typography>
          <TextField
            size="small"
            fullWidth
            name="accountName"
            defaultValue={bankInfo.account_name}
            sx={{
              //   border: '1px solid #57494a',
              borderRadius: '10px',
            }}
            disabled={isDisabled}
          />
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={6}>
          <Typography sx={{ fontSize: '12px', color: '#57494a' }}>
            Account Number
          </Typography>
          <TextField
            size="small"
            fullWidth
            name="accountNumber"
            defaultValue={bankInfo?.account_number}
            sx={{
              borderRadius: '10px',
            }}
            disabled={isDisabled}
          />
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={6}>
          <Typography sx={{ fontSize: '12px', color: '#57494a' }}>
            IFSC Code
          </Typography>
          <TextField
            size="small"
            fullWidth
            name="ifscCode"
            defaultValue={bankInfo?.ifsc_code}
            sx={{
              borderRadius: '10px',
            }}
            disabled={isDisabled}
          />
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={6}>
          <Typography sx={{ fontSize: '12px', color: '#57494a' }}>
            Account Type
          </Typography>
          <TextField
            size="small"
            fullWidth
            name="accountType"
            defaultValue={bankInfo?.account_type}
            sx={{
              borderRadius: '10px',
            }}
            disabled={isDisabled}
          />
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={6}>
          <Typography sx={{ fontSize: '12px', color: '#57494a' }}>Branch</Typography>
          <TextField
            size="small"
            fullWidth
            name="branch"
            defaultValue={bankInfo?.branch}
            sx={{
              borderRadius: '10px',
            }}
            disabled={isDisabled}
          />
        </Grid>
        <Grid item xl={12} lg={12} md={6} sm={6}>
          <Typography sx={{ fontSize: '12px', color: '#57494a' }}>
            Bank Name
          </Typography>
          <TextField
            size="small"
            fullWidth
            name="bankName"
            defaultValue={bankInfo?.bank_name}
            sx={{
              borderRadius: '10px',
            }}
            disabled={isDisabled}
          />
        </Grid>
        <Grid item xl={12} lg={12} md={6} sm={6}>
          <Typography sx={{ fontSize: '12px', color: '#57494a' }}>
            Branch Address
          </Typography>
          <TextField
            size="small"
            fullWidth
            name="branchAddress"
            defaultValue={bankInfo?.branch_address}
            sx={{
              borderRadius: '10px',
            }}
            disabled={isDisabled}
          />
        </Grid>
        <Grid item xl={12} lg={12} md={6} sm={6} sx={{ textAlign: 'center' }}>
          {isDisabled ? (
            <Button
              sx={{
                background: '#2bb18c',
                color: '#fff',
                borderRadius: '20px',
                fontSize: '14px',
                fontWeight: 600,
                mt: '3rem',
                '&:hover': {
                  backgroundColor: '#2bb18c',
                },
              }}
              type="button"
              onClick={(e) => {
                e.preventDefault();
                setIsDisabled(false);
              }}
            >
              Edit Bank Details
            </Button>
          ) : (
            <Button
              sx={{
                background: '#2bb18c',
                color: '#fff',
                borderRadius: '20px',
                fontSize: '14px',
                fontWeight: 600,
                mt: '3rem',
                '&:hover': {
                  backgroundColor: '#2bb18c',
                },
              }}
              type="submit"
            >
              Update Bank Details
            </Button>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}
export default UpdatePayout;
