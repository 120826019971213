/* eslint-disable no-unused-vars */
import React, { Fragment, useEffect, useState } from 'react';
import { usePublishedCategoriesQuery } from '../../../app/services/CategoriesApi';
import { useNavigate, useParams } from 'react-router-dom';
import { Card, CardContent, Grid, Typography } from '@mui/material';

const GradeSubjectPage = () => {
  const params = useParams();
  const { data, isLoading, isSuccess } = usePublishedCategoriesQuery();
  const [finalGrade, setFinalGrade] = useState({});
  const navigate = useNavigate();
  useEffect(() => {
    let category = Array.isArray(data?.categories)
      ? data?.categories.find(
          (singleCategory) => singleCategory.title == params?.categoryTitle
        )
      : {};
    let subCategory = Array.isArray(category?.published_sub_categories)
      ? category?.published_sub_categories.find(
          (singleSubCategory) => singleSubCategory.id == params?.subCategoryId
        )
      : {};
    let finalGrade = Array.isArray(subCategory?.grades)
      ? subCategory?.grades.find((singleGrade) => singleGrade.id == params?.gradeId)
      : {};
    setFinalGrade({ ...finalGrade });
  }, [data, params]);
  return (
    <Fragment>
      <Grid container justifyContent={'center'}>
        <Grid item xl={10} lg={10} md={11} sm={11}>
          <Grid container spacing={2}>
            <Grid item xl={12} lg={12} md={12} sm={12}>
              <Typography
                sx={{
                  textAlign: 'center',
                  fontSize: '32px',
                  fontWeight: 700,
                  lineHeight: '52px',
                  color: '#1b76bb',
                }}
              >
                Choose Your Subject
              </Typography>
            </Grid>
            {Array.isArray(finalGrade?.grade_subjects) &&
              finalGrade?.grade_subjects.map((singleGradeSubject, index) => (
                <Grid
                  item
                  xl={3}
                  lg={3}
                  md={6}
                  sm={12}
                  key={'singleGradeSubject' + index}
                >
                  <Card sx={{ background: '#fff' }}>
                    <CardContent sx={{ background: '#fff' }}>
                      <div
                        onClick={() => {
                          navigate(
                            `/${params.categoryTitle}/${params.subCategoryId}/${finalGrade?.title}/${singleGradeSubject?.title}`
                          );
                        }}
                      >
                        <Typography
                          style={{
                            display: '-webkit-box',
                            WebkitBoxOrient: 'vertical',
                            WebkitLineClamp: 1,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            margin: '0rem',
                            cursor: 'pointer',
                            textAlign: 'center',
                            fontSize: '20px',
                            fontWeight: 700,
                            lineHeight: '30px',
                            color: '#1b76bb',
                          }}
                          title={singleGradeSubject?.title}
                        >
                          {singleGradeSubject?.title}
                        </Typography>
                      </div>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default GradeSubjectPage;
