import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
} from '@mui/material';
import React from 'react';
import { useStudentSubscriptionPlansQuery } from '../../../../app/services/StudentSubscriptionPlansApi';
import DataLoader from '../../../../components/DataLoader';
import CreateNewStudentSubscriptionPlan from './CreateNewStudentSubscriptionPlan';
import StudentSubscriptionPlansList from './StudentSubscriptionPlansList';

function StudentSubscriptionPlansManager() {
  const [open, setOpen] = React.useState(false);

  const { data, isLoading, isSuccess } = useStudentSubscriptionPlansQuery();

  if (isLoading) {
    return <DataLoader thickness={5} size={50} />;
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs="12" sm="12" md="12" lg="12" xl="12">
          <div style={{ textAlign: 'center' }}>
            <Button variant="contained" onClick={handleClickOpen}>
              Create New Student Subscription Plan
            </Button>
            <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
              <DialogTitle> Create New Student Subscription Plan</DialogTitle>
              <DialogContent>
                <CreateNewStudentSubscriptionPlan />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Close</Button>
              </DialogActions>
            </Dialog>
          </div>
        </Grid>
        <Grid item xs="12" sm="12" md="12" lg="12" xl="12">
          {isSuccess && data.studentSubscriptionPlans.length ? (
            <StudentSubscriptionPlansList
              subscriptionPlans={data.studentSubscriptionPlans}
            />
          ) : (
            <p>No student subscription plans found</p>
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default StudentSubscriptionPlansManager;
