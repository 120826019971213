/* eslint-disable no-unused-vars */
import { ExpandMore } from '@mui/icons-material';
import {
  Typography,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  List,
  ListItemButton,
  ListItemText,
  Collapse,
  TablePagination,
} from '@mui/material';
import moment from 'moment';
import React from 'react';
import { useNavigate } from 'react-router';
import { useUpdateStudentGradeSubjectSubscriptionPlanSatusMutation } from '../../../../../app/services/StudentSubscriptionPlansApi';

function GradeSubjectPlansList(props) {
  const { gradeSubjectPlans } = props;
  const navigate = useNavigate();

  const [expandedId, setExpandedId] = React.useState(-1);

  const [updateSubscriptionPlanStatus, result] =
    useUpdateStudentGradeSubjectSubscriptionPlanSatusMutation();

  const handleClick = (i) => {
    setExpandedId(expandedId === i ? -1 : i);
  };

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const videwSubscriptionPlan = (id) => {
    navigate(`/student/gradesubject/subscription/plan/${id}`);
  };

  const onStatusChange = (id, status) => {
    updateSubscriptionPlanStatus({ id, status });
  };

  //   const onDelete = (id) => {
  //     deleteSubCategory({ id });
  //   };

  return (
    <>
      <Typography component="div" variant="h5" sx={{ padding: 4, color: '#233c8d' }}>
        Grade Subject Plans
      </Typography>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Title</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Cost</TableCell>
              <TableCell>Created On</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {gradeSubjectPlans
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((singlePlan, index) => (
                <TableRow
                  key={singlePlan.id}
                  sx={{
                    '&:last-child td, &:last-child th': {
                      border: 0,
                    },
                  }}
                >
                  <TableCell component="th" scope="row">
                    {index + 1}
                  </TableCell>
                  <TableCell>{singlePlan.title}</TableCell>
                  <TableCell>{singlePlan.status || 'draft'}</TableCell>
                  <TableCell>{singlePlan.cost}</TableCell>
                  <TableCell>
                    {moment(singlePlan.created_at).format('Do MMM YY hh:mm A')}
                  </TableCell>
                  <TableCell>
                    <List
                      sx={{
                        width: '100%',
                        maxWidth: 200,
                        bgcolor: 'background.paper',
                      }}
                    >
                      <ListItemButton
                        onClick={() => handleClick(index)}
                        aria-expanded={expandedId === index}
                      >
                        <ListItemText primary="Options" />
                        <ExpandMore />
                      </ListItemButton>
                      <Collapse
                        in={expandedId === index}
                        timeout="auto"
                        unmountOnExit
                      >
                        <List component="div" disablePadding>
                          <ListItemButton
                            sx={{ pl: 4 }}
                            onClick={() => videwSubscriptionPlan(singlePlan.id)}
                          >
                            <ListItemText primary="View" />
                          </ListItemButton>
                        </List>
                        <List component="div" disablePadding>
                          <ListItemButton
                            sx={{ pl: 4 }}
                            onClick={() =>
                              onStatusChange(
                                singlePlan.id,
                                singlePlan.status === 'draft' || !singlePlan.status
                                  ? 'published'
                                  : 'draft'
                              )
                            }
                          >
                            <ListItemText
                              primary={
                                singlePlan.status === 'draft' || !singlePlan.status
                                  ? 'Publish'
                                  : 'Draft'
                              }
                            />
                          </ListItemButton>
                        </List>
                        {/* <List component="div" disablePadding>
                          <ListItemButton
                            sx={{ pl: 4 }}
                            // onClick={() => onDelete(singlePlan.id)}
                          >
                            <ListItemText primary="Delete" />
                          </ListItemButton>
                        </List> */}
                      </Collapse>
                    </List>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={gradeSubjectPlans && gradeSubjectPlans.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
}

export default GradeSubjectPlansList;
