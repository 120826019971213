/* eslint-disable no-unused-vars */

import { Button, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { usePublishedEducatorSubscriptionPlansQuery } from '../../../app/services/EducatorSubscriptionPlansApi';

function DisplayEducatorSubscriptionPlans() {
  const navigate = useNavigate();

  const { data, isLoading, isSuccess } =
    usePublishedEducatorSubscriptionPlansQuery();
  const videwSubscriptionPlan = (id) => {
    navigate(`/educator-subscription-plans/${id}`);
  };

  return (
    <>
      <Grid container>
        <Grid item md={2}></Grid>
        <Grid item md={8} sx={{ background: 'lightcyan' }}>
          {isSuccess && data.educatorSubscriptionPlans ? (
            <Box
              sx={{ ml: '20px', mt: '20px', height: '100vh', alignItems: 'center' }}
            >
              <Grid container>
                {data.educatorSubscriptionPlans.map((singlePlan) => (
                  <Grid item md={3} key={singlePlan.id}>
                    <Typography variant="h4">{singlePlan.title}</Typography>
                    <Typography>₹{singlePlan.cost}</Typography>

                    <Typography>
                      <pre>{singlePlan.description}</pre>
                    </Typography>

                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => videwSubscriptionPlan(singlePlan.id)}
                    >
                      Get Started
                    </Button>
                  </Grid>
                ))}
              </Grid>
            </Box>
          ) : (
            <Typography>No educator subscription Plan Found</Typography>
          )}
        </Grid>
        <Grid item md={8}></Grid>

        {/* <Grid
        item
        xs={false}
        sm={4}
        md={6}
        sx={{
          backgroundImage:
            'url(https://images.unsplash.com/photo-1521898284481-a5ec348cb555?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80)',
        }}
      ></Grid> */}
      </Grid>
    </>
  );
}
export default DisplayEducatorSubscriptionPlans;
