import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { useParams } from 'react-router';
import { useStudentSubscriptionPlanQuery } from '../../../../app/services/StudentSubscriptionPlansApi';
import DataLoader from '../../../../components/DataLoader';
import CreateGradePlan from './grades/CreateGradePlan';
import GradePlansList from './grades/GradePlansList';
import CreateGradeSubjectPlan from './gradeSubjects/CreateGradeSubjectPlan';
import GradeSubjectPlansList from './gradeSubjects/GradeSubjectPlansList';
import CreateSubCategoryPlan from './subCategories/CreateSubCategoryPlan';
import SubCategoryPlansList from './subCategories/SubCategoryPlansList';
import UpdateStudentSubscriptionPlan from './UpdateStudentSubscriptionPlan';

function SingleStudentSubscriptionPlan() {
  const params = useParams();
  const [open1, setOpen1] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);
  const [open4, setOpen4] = React.useState(false);

  const { data, isLoading, isSuccess } = useStudentSubscriptionPlanQuery({
    id: parseInt(params.Id, 10),
  });

  const handleClickOpen1 = () => {
    setOpen1(true);
  };

  const handleClose1 = () => {
    setOpen1(false);
  };

  const handleClickOpen2 = () => {
    setOpen2(true);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };

  const handleClickOpen3 = () => {
    setOpen3(true);
  };

  const handleClose3 = () => {
    setOpen3(false);
  };

  const handleClickOpen4 = () => {
    setOpen4(true);
  };

  const handleClose4 = () => {
    setOpen4(false);
  };

  if (isLoading) {
    return <DataLoader thickness={5} size={50} />;
  }

  return (
    <>
      {isSuccess && data.studentSubscriptionPlan ? (
        <>
          <Grid item xs="12" sm="12" md="12" lg="12" xl="12">
            <Box sx={{ textAlign: 'center' }}>
              <Button variant="contained" onClick={handleClickOpen1}>
                Update Plan
              </Button>
              <>
                <Dialog open={open1} onClose={handleClose1} fullWidth maxWidth="md">
                  <DialogTitle>Update Student Subscription Plan</DialogTitle>
                  <DialogContent>
                    <UpdateStudentSubscriptionPlan
                      subscriptionPlan={data.studentSubscriptionPlan}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose1}>Close</Button>
                  </DialogActions>
                </Dialog>
              </>
              &nbsp;
              <Button variant="contained" onClick={handleClickOpen2}>
                Create Sub Category Plan
              </Button>
              <>
                <Dialog open={open2} onClose={handleClose2} fullWidth maxWidth="md">
                  <DialogTitle>
                    Create Student Sub Category Subscription Plan
                  </DialogTitle>
                  <DialogContent>
                    <CreateSubCategoryPlan
                      subscriptionPlan={data.studentSubscriptionPlan}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose2}>Close</Button>
                  </DialogActions>
                </Dialog>
              </>
              &nbsp;
              <Button variant="contained" onClick={handleClickOpen3}>
                Create Grade Plan
              </Button>
              <>
                <Dialog open={open3} onClose={handleClose3} fullWidth maxWidth="md">
                  <DialogTitle>Create Student Grade Subscription Plan</DialogTitle>
                  <DialogContent>
                    <CreateGradePlan
                      subscriptionPlan={data.studentSubscriptionPlan}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose3}>Close</Button>
                  </DialogActions>
                </Dialog>
              </>
              &nbsp;
              <Button variant="contained" onClick={handleClickOpen4}>
                Create Grade Subject Plan
              </Button>
              <>
                <Dialog open={open4} onClose={handleClose4} fullWidth maxWidth="md">
                  <DialogTitle>
                    Create Student Grade Subject Subscription Plan
                  </DialogTitle>
                  <DialogContent>
                    <CreateGradeSubjectPlan
                      subscriptionPlan={data.studentSubscriptionPlan}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose4}>Close</Button>
                  </DialogActions>
                </Dialog>
              </>
              &nbsp;
            </Box>
          </Grid>

          <Typography
            gutterBottom
            variant="h5"
            component="div"
            sx={{ padding: 4, color: '#233c8d' }}
          >
            Title: {data.studentSubscriptionPlan.title}
            <br />
            Description: {data.studentSubscriptionPlan.description}
            <br />
            No Of Days: {data.studentSubscriptionPlan.no_of_days}
            <br />
            Status: {data.studentSubscriptionPlan.status}
          </Typography>
          <Grid container>
            <Grid item xs="12" sm="12" md="4" lg="4" xl="4">
              {data.studentSubscriptionPlan.sub_category_plans?.length >= 1 ? (
                <SubCategoryPlansList
                  subCategoryPlans={data.studentSubscriptionPlan.sub_category_plans}
                />
              ) : (
                <Typography>No Category Plans Found</Typography>
              )}
            </Grid>
            <Grid item xs="12" sm="12" md="4" lg="4" xl="4">
              {data.studentSubscriptionPlan.grade_plans?.length >= 1 ? (
                <GradePlansList
                  gradePlans={data.studentSubscriptionPlan.grade_plans}
                />
              ) : (
                <Typography>No Grade Plans Found</Typography>
              )}
            </Grid>
            <Grid item xs="12" sm="12" md="4" lg="4" xl="4">
              {data.studentSubscriptionPlan.grade_subject_plans?.length >= 1 ? (
                <GradeSubjectPlansList
                  gradeSubjectPlans={
                    data.studentSubscriptionPlan.grade_subject_plans
                  }
                />
              ) : (
                <Typography>No Grade Subject Plans Found</Typography>
              )}
            </Grid>
          </Grid>
        </>
      ) : (
        <p>No student subscriptionPlan found</p>
      )}
    </>
  );
}

export default SingleStudentSubscriptionPlan;
