/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import './App.css';
import './common.css';
import { Route, Routes } from 'react-router';
import AppLayout from './layout/AppLayout';
import UserSelect from './features/authentication/UserSelect';
import StudentRegistrationPage from './features/authentication/student/StudentRegistrationPage';
import EducatorRegistrationPage from './features/authentication/educator/EducatorRegistrationPage';
import InstitutionRegistrationPage from './features/authentication/institution/InstitutionRegistrationPage';
import VerifyEmailPage from './features/authentication/VerifyEmailPage';
import ResendEmailVerification from './features/authentication/ResendEmailVerification';
import ResetPasswordPage from './features/authentication/ResetPasswordPage';
import NotFoundPage from './components/NotFoundPage';
import CategoriesManager from './features/categories/admin/CategoriesManager';
import { useDispatch } from 'react-redux';
import SingleCategory from './features/categories/admin/SingleCategory';
import SingleSubCategory from './features/categories/admin/SingleSubCategory';
import { updateLocalSignInStatus } from './app/slices/authSlice';
import DisplaySingleCourse from './features/courses/public/DisplaySingleCourse';
import CoursesManager from './features/courses/adminOrEducatorOrInstitution/CoursesManager';
import SingleCourse from './features/courses/adminOrEducatorOrInstitution/SingleCourse';
import SingleSubject from './features/courses/adminOrEducatorOrInstitution/subjects/SingleSubject';
import SingleTopic from './features/courses/adminOrEducatorOrInstitution/subjects/topics/SingleTopic';
import SingleSubTopic from './features/courses/adminOrEducatorOrInstitution/subjects/topics/subTopics/SingleSubTopic';
import EnroledCourses from './features/courses/student/EnroledCourses';
import EnroledCourse from './features/courses/student/EnroledCourse';
import ExamsManager from './features/exams/adminOrEducatorOrInstitution/ExamsManager';
import SingleExam from './features/exams/adminOrEducatorOrInstitution/SingleExam';
import SingleQuestion from './features/exams/adminOrEducatorOrInstitution/questions/SingleQuestion';
import EnroledExams from './features/exams/student/EnroledExams';
import EnroledExam from './features/exams/student/EnroledExam';
import DisplaySingleExam from './features/exams/public/DisplaySingleExam';
import StudentsManager from './features/users/admin/StudentsManager';
import InstitutionsManager from './features/users/admin/InstitutionsManager';
import EducatorsManager from './features/users/admin/EducatorsManager';
import TestsManager from './features/tests/adminOrEducatorOrInstitution/TestsManager';
import SingleTest from './features/tests/adminOrEducatorOrInstitution/SingleTest';
import SingleTestSubject from './features/tests/adminOrEducatorOrInstitution/testSubjects/SingleTestSubject';
import SingleTestTopic from './features/tests/adminOrEducatorOrInstitution/testSubjects/testTopics/SingleTestTopic';
import SingleTestQuestion from './features/tests/adminOrEducatorOrInstitution/testSubjects/testTopics/testQuestions/SingleTestQuestion';
import DisplaySingleTest from './features/tests/public/DisplaySingleTest';
import PracticeTests from './features/tests/student/PracticeTests';
import PracticeTest from './features/tests/student/PracticeTest';
import DisplaySingleSubTopicVideo from './features/courses/adminOrEducatorOrInstitution/subjects/topics/subTopics/DisplaySingleSubTopicVideo';
import DisplayEnroledSingleCourseVideo from './features/courses/student/DisplayEnroledSingleCourseVideo';
import DIsplaySingleCourseVideo from './features/courses/adminOrEducatorOrInstitution/DIsplaySingleCourseVideo';
import VideoManager from './features/courses/adminOrEducatorOrInstitution/admin/VideoManager';
import DisplaySingleVideo from './features/courses/adminOrEducatorOrInstitution/admin/DisplaySingleVideo';
import DisplaySingleTopicVideo from './features/courses/adminOrEducatorOrInstitution/subjects/topics/DisplaySingleTopicVideo';
import DisplaySingleSubjectVideo from './features/courses/adminOrEducatorOrInstitution/subjects/DisplaySingleSubjectVideo';
import SubscriptionPlansManager from './features/subscriptionPlans/admin/SubscriptionPlansManager';
import SingleSubscriptionPlan from './features/subscriptionPlans/admin/SingleSubscriptionPlan';
import HomePage from './components/HomePage';
import GradesManager from './features/categories/admin/GradesManager';
import SingleGrade from './features/categories/admin/SIngleGrade';
import SingleGradeSubject from './features/categories/admin/SingleGradeSubject';
import PublicationsManager from './features/categories/admin/PublicationsManager';
import SinglePublication from './features/categories/admin/SinglePublication';
import KeywordsManager from './features/keywords/admin/KeywordsManager';
import SingleKeyword from './features/keywords/admin/SingleKeyword';
import SearchQueryPage from './features/courses/public/SearchQueryPage';
import StudentDashboard from './features/users/student/StudentDashboard';
import StudentProfile from './features/users/student/StudentProfile';
import LearningObjectivesManager from './features/users/admin/learningObjective/LearningObjectivesManager';
import SingleLearningObjective from './features/users/admin/learningObjective/SingleLearningObjective';
import UserFollowers from './features/users/UserFollowers';
import UserFollowing from './features/users/UserFollowing';
import UserReviews from './features/users/UserReviews';
import AdminDashboard from './features/users/admin/AdminDashboard';
import EducatorDashboard from './features/users/educator/EducatorDashboard';
import InstitutionDashboard from './features/users/institution/InstitutionDashboard';
import SinglePublicationEdition from './features/categories/admin/SinglePublicationEdition';
import { EducatorProfile } from './features/users/educator/EducatorProfile';
import VideoViolationManager from './features/courses/adminOrEducatorOrInstitution/admin/VideoViolationManager';
import CourseViolationManager from './features/courses/adminOrEducatorOrInstitution/admin/CourseViolationManager';
import SingleVideoViolation from './features/courses/adminOrEducatorOrInstitution/admin/SingleVideoViolation';
import SingleCourseViolation from './features/courses/adminOrEducatorOrInstitution/admin/SingleCourseViolation';
import SubCategoryResults from './features/courses/public/SubCategoryResults';
import { EducatorChannel } from './features/users/educator/EducatorChannel';
import NewCreateCourse from './features/courses/adminOrEducatorOrInstitution/NewCreateCourse';
import SingleEducatorSubscriptionPlan from './features/subscriptionPlans/admin/SingleEducatorSubscriptionPlan';
import EducatorSubscriptionPlansManager from './features/subscriptionPlans/admin/EducatorSubscriptionPlansManager';
import DisplayEducatorSubscriptionPlans from './features/subscriptionPlans/public/DisplayEducatorSubscriptionPlans';
import DisplaySubscriptionPlans from './features/subscriptionPlans/public/DisplaySubscriptionPlans';
import DisplaySingleEducatorSubscriptionPlan from './features/subscriptionPlans/public/DisplaySingleEducatorSubscriptionPlan';
import { TeachEducator } from './components/onboard/Educator/TeachEducator';
import { HowToUseApp } from './components/onboard/Educator/HowToUseApp';
import { TeacherPlan } from './components/onboard/Educator/TeacherPlan';
import EditSingleCourse from './features/courses/adminOrEducatorOrInstitution/EditSingleCourse';
import StudentSubscriptionPlansManager from './features/subscriptionPlans/admin/student/StudentSubscriptionPlansManager';
import SingleStudentSubscriptionPlan from './features/subscriptionPlans/admin/student/SingleStudentSubscriptionPlan';
import DisplayStudentSubscriptionPlans from './features/subscriptionPlans/public/DisplayStudentSubscriptionPlans';
import SingleGradeSubjectPlan from './features/subscriptionPlans/admin/student/gradeSubjects/SingleGradeSubjectPlan';
import SingleGradePlan from './features/subscriptionPlans/admin/student/grades/SingleGradePlan';
import SingleSubCategoryPlan from './features/subscriptionPlans/admin/student/subCategories/SingleSubCategoryPlan';
import DisplaySingleStudentSubscriptionPlan from './features/subscriptionPlans/public/DisplaySingleStudentSubscriptionPlan';
import StudentSubscriptionManager from './features/subscriptionPlans/student/StudentSubscriptionManager';
import SingleStudentSubscription from './features/subscriptionPlans/student/SingleStudentSubscription';
import DisplayOnBoardSubscription from './features/subscriptionPlans/educator/DisplayOnBoardSubscription';
import DocumentManager from './features/courses/adminOrEducatorOrInstitution/admin/DocumentManager';
import DisplayEnroledSingleCourseDocument from './features/courses/student/DisplayEnroledSingleCourseDocument';
import DisplayEnroledCourseExternalVideo from './features/courses/student/DisplayEnroledCourseExternalVideo';
import SupportTicketManager from './features/supportTickets/admin/SupportTicketManager';
import SingleSupportTicket from './features/supportTickets/SingleSupportTicket';
import UserSupportTicketManager from './features/supportTickets/users/UserSupportTicketManager';
import { EducatorPerformance } from './features/users/educator/EducatorPerformance';
import { EducatorPlanProfilePayout } from './features/users/educator/EducatorPlanProfilePayout';
import { EducatorEngagement } from './features/users/educator/EducatorEngagement';
import GradeSubjectPage from './features/courses/public/GradeSubjectPage';
import { PrivacyPloicy } from './layout/PrivacyPolicy';
import { RefundPolicy } from './layout/RefundPolicy';
import { SubscriptionCancellationPolicy } from './layout/SubscriptionCancellationPolicy';
import { TermsConditions } from './layout/TermsConditions';
import { AcceptanceOfAgreement } from './layout/AcceptanceOfAgreement';
import ScrollToTop from './layout/ScrollToTop';

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateLocalSignInStatus());
  }, []);

  return (
    <div className="App">
      <ScrollToTop />
      <Routes>
        <Route path="/teacher-plan" element={<TeacherPlan />} />
        <Route path="/" element={<AppLayout />}>
          <Route index element={<HomePage />} />
          <Route path="/teach-educator" element={<TeachEducator />} />
          <Route path="/how-to-use" element={<HowToUseApp />} />
          <Route path="/select-account" element={<UserSelect />} />
          <Route path="/search" element={<SearchQueryPage />} />
          <Route path="categories" element={<CategoriesManager />} />
          <Route path="categories/:categoryId" element={<SingleCategory />} />
          <Route
            path="subcategories/:subcategoryId"
            element={<SingleSubCategory />}
          />

          <Route path="grades" element={<GradesManager />} />
          <Route path="grades/:gradeId" element={<SingleGrade />} />
          <Route
            path="gradesubjects/:gradeSubjectId"
            element={<SingleGradeSubject />}
          />

          <Route path="publications" element={<PublicationsManager />} />
          <Route
            path="publications/:publicationId"
            element={<SinglePublication />}
          />

          <Route
            path="publicationEditions/:publicationEditionId"
            element={<SinglePublicationEdition />}
          />

          {/* Student Sidebar Routes */}
          <Route path="/student/dashboard" element={<StudentDashboard />} />
          <Route path="/student/profile" element={<StudentProfile />} />

          {/* Admin Dashboard */}
          <Route path="/admin/dashboard" element={<AdminDashboard />} />

          {/* Educator Dashboard */}
          <Route path="/educator/dashboard" element={<EducatorDashboard />} />
          {/* Educator Performace */}
          <Route path="/educator/performance" element={<EducatorPerformance />} />
          {/* Educator Engagement */}
          <Route path="/educator/engagement" element={<EducatorEngagement />} />
          {/* Educator Profile */}
          <Route path="/educator/profile" element={<EducatorPlanProfilePayout />} />
          {/* Educator Channel */}
          <Route path="/educator/channel/:Id" element={<EducatorChannel />} />
          {/* Institution Dashboard */}
          <Route path="/institution/dashboard" element={<InstitutionDashboard />} />

          {/* Educator Course Routes */}
          <Route path="add-course" element={<NewCreateCourse />} />
          <Route path="courses" element={<CoursesManager />} />
          <Route path="courses/:Id" element={<SingleCourse />} />
          <Route path="edit-courses/:Id" element={<EditSingleCourse />} />
          <Route path="course/video/:Id" element={<DIsplaySingleCourseVideo />} />
          <Route path="subjects/:subjectId" element={<SingleSubject />} />
          <Route path="topics/:topicId" element={<SingleTopic />} />
          <Route path="subTopics/:subTopicId" element={<SingleSubTopic />} />
          <Route
            path="subTopic/video/:Id"
            element={<DisplaySingleSubTopicVideo />}
          />

          <Route path="topic/video/:Id" element={<DisplaySingleTopicVideo />} />

          <Route path="subject/video/:Id" element={<DisplaySingleSubjectVideo />} />

          <Route path="course/violations" element={<CourseViolationManager />} />
          <Route path="course/violations/:Id" element={<SingleCourseViolation />} />

          {/* Student Course Routes */}
          <Route path="enrols/course" element={<EnroledCourses />} />

          <Route path="enrols/course/:enrolId" element={<EnroledCourse />} />
          <Route
            path="enrols/:Id/video/:videoId"
            element={<DisplayEnroledSingleCourseVideo />}
          />

          <Route
            path="enrols/:Id/document/:documentId"
            element={<DisplayEnroledSingleCourseDocument />}
          />

          <Route
            path="enrols/:Id/topic/:topicId/external-video"
            element={<DisplayEnroledCourseExternalVideo />}
          />

          {/* Public Courses */}
          <Route
            path=":categoryTitle/:subCategoryId"
            element={<SubCategoryResults />}
          />
          <Route
            path=":categoryTitle/:subCategoryId/:gradeId"
            element={<SubCategoryResults />}
          />
          <Route
            path=":categoryTitle/:subCategoryId/:gradeTitle/:subjectTitle"
            element={<SubCategoryResults />}
          />
          <Route
            path="allgrades/:categoryTitle/:subCategoryId/:gradeId"
            element={<GradeSubjectPage />}
          />
          <Route
            path=":categoryTitle/:subCategoryTitle/course/:courseId"
            element={<DisplaySingleCourse />}
          />
          {/* Exam Routes */}
          <Route path="exams" element={<ExamsManager />} />
          <Route path="exams/:Id" element={<SingleExam />} />
          <Route path="enrols/exam" element={<EnroledExams />} />
          <Route path="enrols/exam/:enrolId" element={<EnroledExam />} />
          <Route path="questions/:questionId" element={<SingleQuestion />} />
          <Route
            path=":categoryTitle/:subCategoryTitle/exam/:examId"
            element={<DisplaySingleExam />}
          />

          {/* Test Routes */}
          <Route path="tests" element={<TestsManager />} />
          <Route path="tests/:Id" element={<SingleTest />} />
          <Route
            path="testSubjects/:testSubjectId"
            element={<SingleTestSubject />}
          />
          <Route path="testTopics/:testTopicId" element={<SingleTestTopic />} />
          <Route
            path="testQuestions/:testQuestionId"
            element={<SingleTestQuestion />}
          />
          <Route
            path=":categoryTitle/:subCategoryTitle/test/:testId"
            element={<DisplaySingleTest />}
          />
          <Route path="practice/tests" element={<PracticeTests />} />
          <Route path="practice/tests/:practiceTestId" element={<PracticeTest />} />

          {/* Admin User Management */}

          <Route path="students" element={<StudentsManager />} />
          <Route path="educators" element={<EducatorsManager />} />
          <Route path="institutions" element={<InstitutionsManager />} />

          <Route path="/reset-password/:token" element={<ResetPasswordPage />} />

          {/* Admin Videos management */}

          <Route path="videos" element={<VideoManager />} />
          <Route path="videos/:Id" element={<DisplaySingleVideo />} />

          <Route path="video/violations" element={<VideoViolationManager />} />
          <Route path="video/violations/:Id" element={<SingleVideoViolation />} />
          {/* Admin Documents */}

          <Route path="documents" element={<DocumentManager />} />

          <Route
            path="/resend/email/verification"
            element={<ResendEmailVerification />}
          />

          {/* Admin Keywords */}
          <Route path="keywords" element={<KeywordsManager />} />
          <Route path="keywords/:Id" element={<SingleKeyword />} />

          {/* Admin Learning Objectives */}
          <Route
            path="learning/objectives"
            element={<LearningObjectivesManager />}
          />
          <Route
            path="learning/objectives/:Id"
            element={<SingleLearningObjective />}
          />

          {/* Subscription plan management */}

          <Route path="subscriptionplans" element={<SubscriptionPlansManager />} />
          <Route path="subscription/plan/:Id" element={<SingleSubscriptionPlan />} />

          {/* public subscription plan */}
          <Route path="subscription-plans" element={<DisplaySubscriptionPlans />} />

          {/* Educator Subscription plan management */}

          <Route
            path="educator/subscriptionplans"
            element={<EducatorSubscriptionPlansManager />}
          />
          <Route
            path="educator/subscription/plan/:Id"
            element={<SingleEducatorSubscriptionPlan />}
          />

          {/* educator public subscription plan */}
          <Route
            path="educator-subscription-plans"
            element={<DisplayEducatorSubscriptionPlans />}
          />

          <Route
            path="educator-subscription-plans/:Id"
            element={<DisplaySingleEducatorSubscriptionPlan />}
          />

          {/* Student Subscription plan management */}

          <Route
            path="student/subscriptionplans"
            element={<StudentSubscriptionPlansManager />}
          />
          <Route
            path="student/subscription/plan/:Id"
            element={<SingleStudentSubscriptionPlan />}
          />
          <Route
            path="student/subcategory/subscription/plan/:Id"
            element={<SingleSubCategoryPlan />}
          />
          <Route
            path="student/grade/subscription/plan/:Id"
            element={<SingleGradePlan />}
          />
          <Route
            path="student/gradesubject/subscription/plan/:Id"
            element={<SingleGradeSubjectPlan />}
          />

          {/* student public subscription plan */}
          <Route
            path="student-subscription-plans"
            element={<DisplayStudentSubscriptionPlans />}
          />

          <Route
            path="student-subscription-plans/:Id"
            element={<DisplaySingleStudentSubscriptionPlan />}
          />
          {/* student subscribed routes */}
          <Route
            path="student/subscriptions"
            element={<StudentSubscriptionManager />}
          />
          <Route
            path="student/subscriptions/:Id"
            element={<SingleStudentSubscription />}
          />

          {/* Support Tickets */}
          <Route path="manage/support/tickets" element={<SupportTicketManager />} />
          <Route path="support/tickets" element={<UserSupportTicketManager />} />
          <Route path="support/ticket/:Id" element={<SingleSupportTicket />} />

          {/* educator onboard  */}
          <Route path="educator/onboard" element={<DisplayOnBoardSubscription />} />

          {/* User Following and Followers */}
          <Route path="following" element={<UserFollowing />} />
          <Route path="followers" element={<UserFollowers />} />

          {/* User Reviews */}
          <Route path="reviews" element={<UserReviews />} />

          {/* Footer Pages Route */}
          <Route path="privacy-policy" element={<PrivacyPloicy />} />
          <Route path="refund-policy" element={<RefundPolicy />} />
          <Route
            path="subscription-cancellation-policy"
            element={<SubscriptionCancellationPolicy />}
          />
          <Route path="terms-conditions" element={<TermsConditions />} />
          <Route
            path="acceptance-of-agreement"
            element={<AcceptanceOfAgreement />}
          />
        </Route>
        <Route path="*" element={<NotFoundPage />} />

        {/* Authentication Routes */}
        <Route path="/student-account" element={<StudentRegistrationPage />} />
        <Route path="/educator-account" element={<EducatorRegistrationPage />} />
        <Route
          path="/institution-account"
          element={<InstitutionRegistrationPage />}
        />
        <Route
          path="/verify-email/:id/:expire/:signature"
          element={<VerifyEmailPage />}
        />
      </Routes>
    </div>
  );
}

export default App;
