/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BaseApi } from './BaseApi';

const API_URL = `${process.env.REACT_APP_BACKEND_API_PATH}/api`;
const LOCALSTORAGE_APP_KEY = `${process.env.REACT_APP_LOCALSTORAGE_KEY}`;

export const supportTicketsApi = BaseApi.injectEndpoints({
  reducerPath: 'supportTicketsApi',
  endpoints: (builder) => ({
    supportTickets: builder.query({
      query: () => ({
        url: '/support/tickets',
        method: 'GET',
      }),
      providesTags: ['Support Ticket'],
    }),
    createSupportTicket: builder.mutation({
      query: (details) => ({
        url: '/support/ticket/create',
        method: 'POST',

        body: {
          title: details.title,
          description: details.description,
        },
      }),
      invalidatesTags: ['Support Ticket'],
    }),
    supportTicket: builder.query({
      query: (details) => ({
        url: `/support/ticket/show/${details.id}`,
        method: 'GET',
      }),
      providesTags: ['Support Ticket'],
    }),

    updateSupportTicketSatus: builder.mutation({
      query: (details) => ({
        url: '/support/ticket/status/update',
        method: 'POST',

        body: {
          status: details.status,
          support_ticket_id: details.id,
        },
      }),
      invalidatesTags: ['Support Ticket'],
    }),

    createSupportTicketComment: builder.mutation({
      query: (details) => ({
        url: '/support/ticket/comment/create',
        method: 'POST',
        body: {
          title: details.commentText,
          support_ticket_id: details.support_ticket_id,
          support_ticket_comment_id: details.support_ticket_comment_id,
        },
      }),
      invalidatesTags: ['Support Ticket'],
    }),
  }),
});

export const {
  useSupportTicketsQuery,
  useCreateSupportTicketMutation,
  useSupportTicketQuery,
  useUpdateSupportTicketSatusMutation,
  useCreateSupportTicketCommentMutation,
} = supportTicketsApi;
