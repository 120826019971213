import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
  Grid,
  Box,
  Avatar,
  Button,
} from '@mui/material';
import {
  CastForEducation,
  Language,
  LocationOn,
  People,
  PersonalVideo,
  Reviews,
  Star,
  Timelapse,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import DisplaySingleCourseSubjects from './DisplaySingleCourseSubjects';
import { useState } from 'react';
import DisplaySingleCourseVideoQuality from './DisplaySingleCourseVideoQuality';

function DisplayAcadmicsSearchCourseResults(props) {
  const { courses } = props;
  const [subjectDialog, setSubjectDialog] = useState(false);
  const [videoQualityDialog, setVideoQualityDialog] = useState(false);
  const navigate = useNavigate();
  const [selectedCourse, setSelectedCourse] = useState({});
  const onCourseClick = (selected) => {
    navigate(
      `/${selected.sub_category.category.title}/${selected.sub_category.title}/course/${selected.id}`
    );
  };

  return courses.length >= 1 ? (
    <Grid container spacing={2}>
      {courses.map((singleCourse, index) => (
        <Grid item xs="12" sm="12" md="12" lg="3" xl="3" key={index}>
          <CardActionArea
            // component="a"
            sx={{ borderRadius: '10px' }}
            // onClick={() => onCourseClick(singleCourse)}
          >
            <Box
              sx={{
                background: '#fff',
                borderRadius: '10px',
                padding: '1rem',
                boxShadow: '1px 1px 10px 5px #ebebeb',
              }}
            >
              <Card sx={{ borderRadius: '10px' }}>
                <Grid container>
                  <Grid item xs="6" sm="6" md="6" lg="6" xl="6">
                    <CardMedia
                      component="img"
                      sx={{
                        maxWidth: 140,
                        minWidth: '100%',
                        height: 220,
                        display: { xs: 'none', sm: 'block' },
                      }}
                      image={
                        singleCourse.url ||
                        `https://images.unsplash.com/photo-1516397281156-ca07cf9746fc?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80`
                      }
                    />
                  </Grid>
                  <Grid item xs="6" sm="6" md="6" lg="6" xl="6" alignSelf={'center'}>
                    <div
                      style={{
                        paddingTop: '0.5rem',
                        paddingBottom: '0.5rem',
                        paddingLeft: '0.5rem',
                        display: 'grid',
                        gap: '0.7rem',
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: '12px',
                          fontWeight: 700,
                          display: 'flex',
                          alignItems: 'center',
                          gap: '0.3rem',
                        }}
                      >
                        <CastForEducation
                          sx={{
                            height: '20px',
                            width: '20px',
                            color: '#4b86c7',
                          }}
                        />
                        {`Lessons ${
                          singleCourse.subjects?.length
                            ? singleCourse.subjects?.length
                            : 0
                        }`}{' '}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: '12px',
                          fontWeight: 700,
                          display: 'flex',
                          alignItems: 'center',
                          gap: '0.3rem',
                        }}
                      >
                        <PersonalVideo
                          sx={{
                            height: '20px',
                            width: '20px',
                            color: '#4b86c7',
                          }}
                        />
                        {`Videos ${
                          singleCourse.published_video_count +
                          singleCourse.published_subject_video_count +
                          singleCourse.published_subject_video_count +
                          singleCourse.published_topic_video_count +
                          singleCourse.published_sub_topic_video_count
                        }`}{' '}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: '12px',
                          fontWeight: 700,
                          display: 'flex',
                          alignItems: 'center',
                          gap: '0.3rem',
                        }}
                      >
                        <Timelapse
                          sx={{
                            height: '20px',
                            width: '20px',
                            color: '#4b86c7',
                          }}
                        />
                        {moment
                          .utc(
                            (Number(singleCourse.published_video_sum_duration) +
                              Number(
                                singleCourse.published_subject_video_sum_duration
                              ) +
                              Number(
                                singleCourse.published_topic_video_sum_duration
                              ) +
                              Number(
                                singleCourse.published_sub_topic_video_sum_duration
                              )) *
                              1000
                          )
                          .format('HH:mm:ss')}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: '12px',
                          fontWeight: 700,
                          display: 'flex',
                          alignItems: 'center',
                          gap: '0.3rem',
                        }}
                      >
                        <Language
                          sx={{
                            height: '20px',
                            width: '20px',
                            color: '#4b86c7',
                          }}
                        />
                        {singleCourse?.language && `${singleCourse.language} Audio`}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: '12px',
                          fontWeight: 700,
                          display: 'flex',
                          alignItems: 'center',
                          gap: '0.3rem',
                        }}
                      >
                        <Reviews
                          sx={{
                            height: '20px',
                            width: '20px',
                            color: '#4b86c7',
                          }}
                        />
                        {/* 18,534 Reviews */}
                        {singleCourse?.course_reviews_count
                          ? singleCourse?.course_reviews_count
                          : 'No Ratings'}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: '12px',
                          fontWeight: 700,
                          display: 'flex',
                          alignItems: 'center',
                          gap: '0.3rem',
                        }}
                      >
                        <People
                          sx={{
                            height: '20px',
                            width: '20px',
                            color: '#4b86c7',
                          }}
                        />
                        {/* 115,904 Students */}
                        {singleCourse?.course_enrols_count
                          ? singleCourse?.course_enrols_count
                          : 'No'}
                        &nbsp;Students
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
              </Card>
              <Grid item xs="12" sm="12" md="12" lg="12" xl="12">
                <CardContent
                  sx={{
                    flex: 1,
                    overflowWrap: 'anywhere',
                    padding: '1rem 0rem 0rem 0rem',
                  }}
                  pb={'0rem'}
                  className="acedmic-card-content"
                >
                  <div style={{ display: 'flex', gap: '0.5rem' }}>
                    <Avatar
                      src={singleCourse?.creator.educator_info?.image_url}
                      sx={{ height: '60px', width: '60px' }}
                    >
                      A
                    </Avatar>
                    <Typography
                      component="h2"
                      variant="h5"
                      sx={{
                        fontSize: '14px',
                        fontWeight: 700,
                        marginBottom: '0.5rem',
                      }}
                    >
                      <p
                        style={{
                          display: '-webkit-box',
                          WebkitBoxOrient: 'vertical',
                          WebkitLineClamp: 2,
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          margin: '0rem',
                        }}
                        title={`
                            ${singleCourse.title}
                            ${
                              singleCourse.sub_category
                                ? ` | ${singleCourse.sub_category.title}`
                                : null
                            }
                            ${
                              singleCourse.grade
                                ? ` | ${singleCourse.grade.title}`
                                : null
                            }
                            ${
                              singleCourse.grade_subject
                                ? ` | ${singleCourse.grade_subject.title}`
                                : null
                            }

                            ${
                              singleCourse.publication
                                ? ` | ${singleCourse.publication.title}`
                                : null
                            }
                            ${
                              singleCourse.publication_edition
                                ? ` | ${singleCourse.publication_edition.edition} Edition`
                                : null
                            }
                        `}
                      >
                        {singleCourse.title}
                        {singleCourse.sub_category
                          ? ` | ${singleCourse.sub_category.title}`
                          : null}
                        {singleCourse.grade
                          ? ` | ${singleCourse.grade.title}`
                          : null}
                        {singleCourse.grade_subject
                          ? ` | ${singleCourse.grade_subject.title}`
                          : null}

                        {singleCourse.publication
                          ? ` | ${singleCourse.publication.title}`
                          : null}
                        {singleCourse.publication_edition
                          ? ` | ${singleCourse.publication_edition.edition} Edition`
                          : null}
                      </p>
                      <p style={{ margin: '0rem' }}>
                        {' '}
                        {singleCourse.status === 'published' ? (
                          <span
                            style={{
                              fontSize: '13px',
                              fontWeight: 500,
                            }}
                          >
                            {' '}
                            {/* {` Published On ${new Date(
                              singleCourse?.created_at
                            ).toLocaleDateString('en-US', {
                              year: 'numeric',
                              month: 'short',
                              day: 'numeric',
                            })}`} */}
                            {/* {` Published On ${moment(singleCourse?.created_at)
                              .local()
                              .format('Do MMM YY')}`} */}
                            {singleCourse?.status === 'published'
                              ? `published on ${moment(
                                  singleCourse?.published_at
                                ).format('Do MMM YY ')}`
                              : `created on ${moment(
                                  singleCourse?.created_at
                                ).format('Do MMM YY ')}`}
                          </span>
                        ) : null}
                      </p>
                    </Typography>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      gap: '1rem',
                      // justifyContent: 'space-between',
                      marginBottom: '0.5rem',
                    }}
                  >
                    <Typography sx={{ textTransform: 'capitalize' }}>
                      {singleCourse.creator.educator_info
                        ? singleCourse.creator.educator_info?.first_name ||
                          singleCourse.creator.name
                        : null}
                      {singleCourse.creator.educator_info?.last_name &&
                        ` ${singleCourse.creator.educator_info?.last_name}`}
                    </Typography>
                    <span
                      style={{
                        background: '#09768d',
                        color: '#fff',
                        padding: '0.1rem 0.2rem',
                        borderRadius: '20px',
                        fontSize: '12px',
                        fontWeight: 500,
                        display: 'flex',
                        alignItems: 'center',
                        width: '45px',
                        justifyContent: 'center',
                      }}
                    >
                      {singleCourse.course_reviews_avg_rating
                        ? Math.round(singleCourse.course_reviews_avg_rating * 10) /
                          10
                        : 0}
                      &nbsp;
                      <Star sx={{ color: '#fff', fontSize: '1rem' }} />
                    </span>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      marginBottom: '0.5rem',
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: '12px',
                        fontWeight: 700,
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 1,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        maxWidth: '80px',
                      }}
                      title={
                        singleCourse.creator.educator_info?.headline
                          ? singleCourse.creator.educator_info?.headline
                          : null
                      }
                    >
                      {singleCourse.creator.educator_info?.headline
                        ? singleCourse.creator.educator_info?.headline
                        : null}{' '}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: '12px',
                        fontWeight: 700,
                        display: 'flex',
                        alignItems: 'center',
                        gap: '0.3rem',
                      }}
                    >
                      <LocationOn
                        sx={{
                          height: '20px',
                          width: '20px',
                          color: '#4b86c7',
                        }}
                      />{' '}
                      {singleCourse.creator.educator_info
                        ? singleCourse.creator.educator_info?.location
                        : null}{' '}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: '12px',
                        fontWeight: 500,
                        color: '#1c1d1f',
                        marginTop: '0.2rem',
                        display: 'flex',
                        alignItems: 'unset',
                        gap: '0.5rem',
                      }}
                    >
                      <CastForEducation sx={{ fontSize: '1rem' }} />
                      {/* 13 Courses */}
                      {singleCourse?.creator?.published_courses
                        ? singleCourse?.creator?.published_courses?.length
                        : 'No'}
                      &nbsp;Courses
                    </Typography>
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button
                      variant="contained"
                      sx={{
                        fontSize: '12px',
                        padding: '0.3rem 0.5rem 0.3rem 0.5rem',
                        background: '#74b71d',
                        ':hover': {
                          background: '#74b71d',
                        },
                      }}
                      onClick={() => {
                        setVideoQualityDialog(true);
                        setSelectedCourse(singleCourse);
                      }}
                    >
                      View Quality
                    </Button>
                    <Button
                      variant="contained"
                      sx={{
                        fontSize: '12px',
                        padding: '0.3rem 0.5rem 0.3rem 0.5rem',
                        background: '#ffb103',
                        ':hover': {
                          background: '#ffb103',
                        },
                      }}
                      onClick={() => {
                        setSubjectDialog(true);
                        setSelectedCourse(singleCourse);
                      }}
                    >
                      View Syllabus
                    </Button>
                  </div>
                  <Button
                    variant="contained"
                    sx={{
                      fontSize: '14px',
                      fontWeight: 700,
                      // padding: '0.3rem 0.5rem 0.3rem 0.5rem',
                      background: '#ee3163',
                      width: '100%',
                      marginTop: '1rem',
                      ':hover': {
                        background: '#ee3163',
                      },
                    }}
                    onClick={() => {
                      onCourseClick(singleCourse);
                    }}
                  >
                    Enroll and learn
                  </Button>
                </CardContent>
              </Grid>
            </Box>
          </CardActionArea>
        </Grid>
      ))}
      {subjectDialog && (
        <DisplaySingleCourseSubjects
          coursesID={selectedCourse?.id}
          open={subjectDialog}
          onClosePopUp={() => {
            setSubjectDialog(false);
            setSelectedCourse({});
          }}
        />
      )}
      {videoQualityDialog && (
        <DisplaySingleCourseVideoQuality
          coursesID={selectedCourse?.id}
          open={videoQualityDialog}
          onClosePopUp={() => {
            setVideoQualityDialog(false);
            setSelectedCourse({});
          }}
        />
      )}
    </Grid>
  ) : (
    <p>No results</p>
  );
}
export default DisplayAcadmicsSearchCourseResults;
