export const allLanguages = [
  'Assamese',
  'Bengali',
  'Bodo',
  'Dogri',
  'English',
  'Gujarati',
  'Hindi',
  'Kannada',
  'Kashmiri',
  'Konkani',
  'Maithili',
  'Malayalam',
  'Marathi',
  'Meitei',
  'Nepali',
  'Odia',
  'Punjabi',
  'Sanskrit',
  'Santali',
  'Sindhi',
  'Tamil',
  'Telugu',
  'Urdu',
];

export const getFileNameFromUrl = (url) => {
  return url.substring(url.lastIndexOf('/') + 1);
};

export function getOrigin() {
  if (typeof window !== 'undefined') {
    return window.location.origin;
  } else {
    // Fallback or default origin (can be modified based on your needs)
    return 'http://localhost:3000';
  }
}
